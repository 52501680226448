/* eslint-disable react/prop-types */
import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormControl from '../../UI/FormControl';
import Button from '../../UI/Button';
import ArrowNext from '../../UI/Icons/ArrowNext';

const PaymentForm = ({ job, setSelectedIndex, setSubmitValues, currencies }) => {
  const VALIDATIONSHEMA = Yup.object({
    currency_id: Yup.string().required().label('Currency')
  });

  let INITIALVALUES = {
    currency_id: 1,
    fixed_amount: '',
    hourly_rate: ''
  };

  if (job) {
    INITIALVALUES = {
      currency_id: job.currency ? job.currency.id : '',
      fixed_amount: job.fixed_amount === null ? 0 : job.fixed_amount,
      hourly_rate: job.hourly_rate === null ? 0 : job.hourly_rate
    };
  }

  const onSubmit = (values) => {
    setSubmitValues(state => ({
      ...state,
      ...values
    }));
    setSelectedIndex(index => ++index);
  };
  return (
    <Formik
      initialValues = {INITIALVALUES}
      validationSchema = {VALIDATIONSHEMA}
      onSubmit={onSubmit}>
      {formik => (
        <Form className='w-full' >
          <label className='block text-sm text-gray-600 font-semibold ' >Pay</label>
          <p className='text-sm text-gray-500 mb-2' >when include pay you increase the chance of getting the right candidates</p>
          <div className="flex flex-col justify-start items-start mb-3">
            <FormControl control='input' type='number' min="0" label='Fixed Amount' name='fixed_amount' placeholder='Please Enter Course Budget'
              onChange={(e) => {
                const { value } = e.target;
                value !== '' && formik.setFieldValue('hourly_rate', '');
                formik.setFieldValue('fixed_amount', Number(value));
              }} />
            <p className='text-sm font-semibold mx-3 mb-3'>or</p>
            <FormControl control='input' type='number' min="0" label='Hourly Rate' name='hourly_rate' placeholder='Please Enter Course Budget'
              onChange={(e) => {
                const { value } = e.target;
                value !== '' && formik.setFieldValue('fixed_amount', '');
                formik.setFieldValue('hourly_rate', Number(value));
              }} />
            <FormControl control='select' label='Currency...' name='currency_id' options={currencies}/>
          </div>
          <Button type='submit' className='text-primary border-2  mx-auto my-16 hover:border-primary hover:font-bold'> <ArrowNext/> Next</Button>
        </Form>)}
    </Formik>
  );
};

export default PaymentForm;
