import React from 'react';

const LocationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.002" viewBox="0 0 24 24.002">
      <g id="location" transform="translate(0 0.001)">
        <rect id="container" width="24" height="24" fill="#0f4975" opacity="0"/>
        <g id="Group_109" data-name="Group 109" transform="translate(-0.825 -1.826)">
          <path id="Path_60" data-name="Path 60" d="M21.326,3.294a.86.86,0,1,0-1.217-1.217l-.811.811a1.459,1.459,0,0,0-.043,2,9.463,9.463,0,0,1-7.049,15.771H12.2a9.431,9.431,0,0,1-6.305-2.409,1.459,1.459,0,0,0-2,.043l-.811.811a.86.86,0,0,0,1.217,1.217l.631-.631a11.136,11.136,0,0,0,6.416,2.657V24.1H9.334a.86.86,0,1,0,0,1.72h5.735a.86.86,0,0,0,0-1.72H13.062V22.352A11.184,11.184,0,0,0,20.694,3.925Z" transform="translate(0 0)" fill="#0f4975"/>
          <path id="Path_61" data-name="Path 61" d="M4.5,10.955a7.455,7.455,0,1,1,7.455,7.455A7.455,7.455,0,0,1,4.5,10.955Zm9.749-4.3A3.154,3.154,0,1,0,17.4,9.808,3.154,3.154,0,0,0,14.249,6.654ZM6.507,13.823a1.434,1.434,0,1,1,1.434,1.434A1.434,1.434,0,0,1,6.507,13.823Z" transform="translate(0.246 0.246)" fill="#0f4975" fillRule="evenodd"/>
        </g>
      </g>
    </svg>

  );
};

export default LocationIcon;
