/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import ExperienceForm from '../Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import { useAuth } from '../../../context/auth';
import { getToken } from '../../../utils/auth';
import axios from 'axios';
import TextError from '../../UI/TextError';

const ExperienceCreate = ({onClose, fetchExp}) => {
  const token = getToken();
  const {currentUser} = useAuth();
  const [error, setError] = useState('');
  const [categories, setCategories] = useState(false);
  const [countries, setCountries] = useState(false);

  const INITIALVALUES = {
    title: '',
    employment_type: '',
    company_name: '',
    start_month: '',
    start_year: '',
    end_month: '',
    end_year: '',
    headline: '',
    description: '',
    category_id: '',
    country_id: '',
    city_id: '',
    currently_working: false,
    end_current_postion: false,
    user_id: currentUser.id
  };

  useEffect(() => {
    axios.get(`/api/categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCategories(res.data.data);
    });
    axios.get(`/api/countries`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCountries(res.data.data);
    }).catch(error => setError(error.messaage));
    return () => {
      fetchExp && fetchExp(true);
    };
  }, []);

  const onSubmit = values => {
    if (values.currently_working) {
      delete values.end_month;
      delete values.end_year;
    }
    axios.post(`/api/experiences`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(() => {
      onClose();
    }).catch(error => setError(error.messaage));
  };

  return (
    <Fragment>
      {error && <TextError>{error}</TextError>}
      {
        categories && countries && <Formik
          initialValues = {INITIALVALUES}
          validationSchema = {VALIDATIONSHEMA}
          onSubmit={onSubmit}>
          {formik => (
            <ExperienceForm categories={categories} countries={countries} formik={formik} />
          )}
        </Formik>
      }
    </Fragment>
  );
};

export default ExperienceCreate;
