/* eslint-disable react/prop-types */
import React from 'react';
import Input from '../Input';
import Textarea from '../Textarea';
import Select from '../Select';
import Checkbox from '../Checkbox';

const FormControl = ({control, ...restProps}) => {
  switch (control) {
    case 'input':
      return <Input {...restProps} />;
    case 'textarea':
      return <Textarea {...restProps}/>;
    case 'select':
      return <Select {...restProps}/>;
    case 'radio':
    case 'checkbox':
      return <Checkbox {...restProps}/>;
    case 'date':
    default:
      break;
  }
};

export default FormControl;
