/* eslint-disable react/prop-types */
import React from 'react';

const TextError = ({children}) => {
  return (
    <div className="text-red-600 text-xs font-semibold mt-1" >
      {children}
    </div>
  );
};

export default TextError;
