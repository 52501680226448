/* eslint-disable react/prop-types */
import React from 'react';
import { Tab } from '@headlessui/react';
import InfoTab from './infoTab';
import Posts from './posts';
import CoursesTab from './coursesTab';
import SettingsTab from './settingsTab';
import UserInfoTab from './userInfoTab';
import JobSearch from '../components/Jobs/instructor/Search';
import { useAuth } from '../context/auth';

const ProfileTabs = ({ user, setFetch }) => {
  const {currentUser} = useAuth();
  const isCurrentUser = user.id === currentUser.id;
  return (
    <div className='flex flex-col justify-start items-center mx-auto w-full max-w-screen-md my-10 bg-white rounded-lg' >
      <Tab.Group>
        <Tab.List className='flex justify-center items-center flex-wrap gap-6 w-full border-b border-gray-400'>
          {user.role !== 'user' && <Tab
            className={({ selected }) => selected
              ? 'text-secondary font-semibold border-b-4 border-secondary text-sm uppercase py-4 px-2 focus:outline-none'
              : 'text-primary cursor-pointer text-sm uppercase py-4 px-2 focus:outline-none'
            }>
            info
          </Tab>}
          {user.role === 'user' && <Tab
            className={({ selected }) => selected
              ? 'text-secondary font-semibold border-b-4 border-secondary text-sm uppercase py-4 px-2 focus:outline-none'
              : 'text-primary cursor-pointer text-sm uppercase py-4 px-2 focus:outline-none'
            }>
            user info
          </Tab>}
          <Tab
            className={({ selected }) => selected
              ? 'text-secondary font-semibold border-b-4 border-secondary text-sm uppercase py-4 px-2 focus:outline-none'
              : 'text-primary cursor-pointer text-sm uppercase py-4 px-2 focus:outline-none'
            }>
            posts
          </Tab>
          {user.role !== 'user' && <Tab
            className={({ selected }) => selected
              ? 'text-secondary font-semibold border-b-4 border-secondary text-sm uppercase py-4 px-2 focus:outline-none'
              : 'text-primary cursor-pointer text-sm uppercase py-4 px-2 focus:outline-none'
            }>
            courses
          </Tab>}
          {user.role === 'user' && <Tab
            className={({ selected }) => selected
              ? 'text-secondary font-semibold border-b-4 border-secondary text-sm uppercase py-4 px-2 focus:outline-none'
              : 'text-primary cursor-pointer text-sm uppercase py-4 px-2 focus:outline-none'
            }>
            Requests
          </Tab>}
          {isCurrentUser && <Tab
            className={({ selected }) => selected
              ? 'text-secondary font-semibold border-b-4 border-secondary text-sm uppercase py-4 px-2 focus:outline-none'
              : 'text-primary cursor-pointer text-sm uppercase py-4 px-2 focus:outline-none'
            }>
            settings
          </Tab>}
        </Tab.List>
        <Tab.Panels className='w-full' >
          {user.role !== 'user' && <Tab.Panel>
            <InfoTab user={user} />
          </Tab.Panel>}
          {user.role === 'user' && <Tab.Panel>
            <UserInfoTab isCurrentUser={isCurrentUser} user={user} setFetch={setFetch} />
          </Tab.Panel>}
          <Tab.Panel className='w-full' ><Posts userId={user.id} category='' postTab={true}/></Tab.Panel>
          {user.role !== 'user' && <Tab.Panel><CoursesTab user={user} /></Tab.Panel>}
          {user.role === 'user' && <Tab.Panel className='w-full' ><JobSearch profile={true} /></Tab.Panel>}
          {isCurrentUser && <Tab.Panel><SettingsTab user={user} setFetch={setFetch}/></Tab.Panel>}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default ProfileTabs;
