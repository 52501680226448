import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const CreatedSuccessfully = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push('/home');
    }, 5000);
  }, []);

  return (
    <div className="flex justify-center w-full h-screen bg-primary_bg">
      <div className="relative bg-white w-800px  px-8 h-screen flex flex-col justify-center items-center">

        <img src="/images/logo.png" alt="Peconto" className='mb-10 w-20 md:w-24 mt-32' />
        <div className='flex flex-col justify-center items-center w-5/7'>
          <h1 className='text-2xl md:text-4xl text-primary font-body font-semibold capitalize' >Account created successfully</h1>
          <p className='text-sm md:text-lg text-primary font-normal opacity-75 text-center w-2/3 md:w-4/5' >Please wait a moment while we setup your timeline.</p>
        </div>

        <div className='flex flex-col justify-start items-center w-64 h-64'>
          <img src="/images/loading-dots-blue.gif" alt="Loading" />
        </div>

      </div>
    </div>
  );
};

export default CreatedSuccessfully;
