import {call, put, takeEvery} from 'redux-saga/effects';
import Client from '../../api/client';
import {
  createUser,
  createUserSuccess,
  deleteUser,
  deleteUserSuccess,
  editUser,
  editUserSuccess,
  getUser,
  getUserSuccess,
  listUser,
  listUserSuccess
} from '../reducer/userSlice';

function * list (action) {
  let userData;
  try {
    userData = yield call(() => Client('/api/admins'));
  } catch (error) {}
  yield put(listUserSuccess(userData));
}

function * create (action) {
  const data = action.payload.values;
  let userData;
  try {
    userData = yield call(() => Client('/api/admins', {method: 'POST', body: data}));
  } catch (error) {
    if (action.payload.failedCallback) {
      action.payload.failedCallback();
    }
    return null;
  }
  if (action.payload.successCallback) {
    yield put(createUserSuccess(userData.data));
    action.payload.successCallback();
  }
}

function * edit (action) {
  const data = action.payload.values;
  const {id} = action.payload;
  let userData;
  try {
    userData = yield call(() => Client(`/api/admins/${id}`, {method: 'PUT', body: data}));
  } catch (error) {
    if (action.payload.failedCallback) {
      action.payload.failedCallback();
    }
    return null;
  }
  yield put(editUserSuccess(userData.data));
  if (action.payload.successCallback) {
    action.payload.successCallback();
  }
}

function * remove (action) {
  const {id} = action.payload;
  let userData;
  try {
    userData = yield call(() => Client(`/api/admins/${id}`, {method: 'DELETE'}));
  } catch (error) {
    if (action.payload.failedCallback) {
      action.payload.failedCallback();
    }
    return null;
  }
  yield put(deleteUserSuccess(userData.data));
  if (action.payload.successCallback) {
    action.payload.successCallback();
  }
}

function * get (action) {
  const {id} = action.payload;
  let userData;
  try {
    userData = yield call(() => Client(`/api/admins/${id}`));
  } catch (error) {}
  yield put(getUserSuccess(userData));
}

function * userSaga () {
  yield takeEvery(createUser.type, create);
  yield takeEvery(listUser.type, list);
  yield takeEvery(editUser.type, edit);
  yield takeEvery(deleteUser.type, remove);
  yield takeEvery(getUser.type, get);
};

export default userSaga;
