/* eslint-disable react/prop-types */
import { ErrorMessage, Field } from 'formik';
import React from 'react';
import TextError from '../TextError';

const Checkbox = ({label, name, ...restProps}) => {
  return (
    <div className="flex flex-row justify-start items-center w-full mb-5">
      <Field className='w-5 h-5 mr-4 cursor-pointer' type='checkbox' name={name} {...restProps} />
      <label className='block text-sm text-gray-600 font-semibold' htmlFor={name}>{label}</label>
      <ErrorMessage name={name} component={TextError}/>
    </div>
  );
};

export default Checkbox;
