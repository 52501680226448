import React from 'react';

const CertificateIcon = () => {
  return (
    <svg id="certificate" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="container" width="24" height="24" fill="none"/>
      <g id="Group_139" data-name="Group 139" transform="translate(-2.25 -2.5)">
        <path id="Path_94" data-name="Path 94" d="M10.25,9.951a2.2,2.2,0,1,1,2.2,2.2A2.2,2.2,0,0,1,10.25,9.951Z" transform="translate(1.805 1.354)" fill="#0f4975"/>
        <path id="Path_95" data-name="Path 95" d="M5.451,11.3A8.8,8.8,0,1,1,21.706,16l3.43,5.94a.943.943,0,0,1-1,1.4l-3.291-.654L19.766,25.86a.943.943,0,0,1-1.71.168L14.634,20.1q-.188.008-.378.008t-.378-.008l-3.422,5.926a.943.943,0,0,1-1.71-.166L7.663,22.691l-3.288.646a.943.943,0,0,1-1-1.4L6.806,16A8.763,8.763,0,0,1,5.451,11.3Zm2.624,6.271a8.8,8.8,0,0,0,3.807,2.211l-2.021,3.5L9.182,21.3a.943.943,0,0,0-1.075-.62l-2.059.4Zm8.555,2.211,2.018,3.5.675-1.989a.943.943,0,0,1,1.077-.622l2.06.41-2.024-3.505A8.8,8.8,0,0,1,16.63,19.786ZM14.256,7.217A4.088,4.088,0,1,0,18.344,11.3,4.088,4.088,0,0,0,14.256,7.217Z" transform="translate(0)" fill="#0f4975" fillRule="evenodd"/>
      </g>
    </svg>

  );
};

export default CertificateIcon;
