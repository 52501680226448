import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowBack from '../components/UI/Icons/ArrowBack';
import ContactUsForm from '../components/ContactUsForm';

function ContactUs () {
  const [success, setSuccess] = useState(false);
  return (
    <div className="flex justify-center w-full h-screen bg-primary_bg">
      <div className={`relative bg-white w-3/4 sm:w-1/2 lg:w-3/5 xl:w-2/5 px-8 h-full flex flex-col ${success ? 'justify-center' : 'justify-between'} items-center`}>
        {!success ? <Fragment>
          <div className='absolute top-0 left-0 m-6'>
            <Link to='/help-center' className='text-primary flex justify-start items-center p-3'>
              <ArrowBack fill="#0F4975" />
                      Back
            </Link>
          </div>
          <img src="/images/logo.png" alt="Peconto" className='mb-16 mt-20 w-24' />

          <div className='flex flex-col justify-center items-center'>
            <h1 className='text-4xl text-primary font-body font-semibold' >Contact support</h1>
            <p className='text-md w-2/3 opacity-75 text-center text-primary font-normal' >Submit a support ticket with our team to answer your question or inquiry.</p>
          </div>

          <div className='flex flex-col justify-center items-center w-full mt-4 px-16'>

            <ContactUsForm success={ () => setSuccess(true)} />
          </div>
        </Fragment> : <div className='flex flex-col justify-center items-center'>
          <div className='flex flex-col justify-center items-center'>
            <h1 className='text-4xl text-primary font-body font-semibold' >Message sent</h1>
            <p className='text-md w-2/3 opacity-75 text-center text-primary font-normal' >Our support team replies within 24 hours. You&apos;ll receive an email with the required answers.</p>
          </div>
          <Link to='/home' className='flex flex-row justify-center items-center gap-x-12 rounded-lg px-4 py-3 font-normal uppercase text-sm outline-none focus:outline-none ease-linear transition-all duration-150  text-primary border-2  mx-auto border-primary hover:font-bold w-3/5 mt-10'>home page</Link>

        </div>}

      </div>
    </div>
  );
};

export default ContactUs;
