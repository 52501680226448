import React from 'react';

const PhoneIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="phone" transform="translate(0 0)">
        <rect id="container" width="24" height="24" fill="#0f4975" opacity="0"/>
        <path id="Path_62" data-name="Path 62" d="M5.381,12.867a26.921,26.921,0,0,0,13.942,13.55l.99.442a5.1,5.1,0,0,0,6.312-1.818l1.294-1.93a1.459,1.459,0,0,0-.3-1.948l-4.39-3.543a1.457,1.457,0,0,0-2.086.267l-1.358,1.834a17.7,17.7,0,0,1-8.032-8.037l1.832-1.359a1.459,1.459,0,0,0,.267-2.087L10.316,3.843a1.457,1.457,0,0,0-1.946-.3l-1.943,1.3a5.106,5.106,0,0,0-1.8,6.352l.755,1.662Z" transform="translate(-4.167 -3.301)" fill="#0f4975"/>
      </g>
    </svg>
  );
};

export default PhoneIcon;
