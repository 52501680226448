/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import ContactInfoForm from '../Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import TextError from '../../UI/TextError';
import { useAuth } from '../../../context/auth';

const ContactInfoCreate = ({userId, success, role}) => {
  const [error, setError] = useState('');
  const [countries, setCountries] = useState(false);
  const token = getToken();
  const { currentUser } = useAuth();

  const INITIALVALUES = {
    profile_url: currentUser.profile_url,
    phone_number: currentUser.phone_number || '',
    website: currentUser.website || '',
    country_id: currentUser.country_id || '',
    city_id: currentUser.city_id || '',
    _method: 'PUT'
  };

  useEffect(() => {
    axios.get(`/api/countries`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCountries(res.data.data);
    }).catch(error => setError(error.messaage));
  }, []);

  const onSubmit = values => {
    axios.post(`/api/profile/${userId}`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
      success();
    }).catch(error => setError(error.message));
  };
  return (
    <Fragment>
      {error && <TextError>{error}</TextError>}
      {countries && <Formik
        initialValues = {INITIALVALUES}
        validationSchema = {VALIDATIONSHEMA}
        onSubmit={onSubmit}>
        {formik => (
          <ContactInfoForm values={formik.values} countries={countries} profileUrl={formik.values.profile_url} userImage={currentUser.photo} role={role} />
        )}
      </Formik>}
    </Fragment>
  );
};

export default ContactInfoCreate;
