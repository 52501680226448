import * as Yup from 'yup';

export const VALIDATIONSHEMA = Yup.object({
  title: Yup.string().required(),
  employment_type: Yup.string(),
  company_name: Yup.string().required(),
  start_month: Yup.string().required(),
  start_year: Yup.string().required(),
  category_id: Yup.string().required(),
  country_id: Yup.string().required(),
  city_id: Yup.string().required('city is required'),
  //   end_month: Yup.string(),
  //   end_year: Yup.string(),
  description: Yup.string()
});
