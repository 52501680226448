import React from 'react';

const UnFollowIcon = () => {
  return (
    <svg id="unfollow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="container" width="24" height="24" fill="#fff" opacity="0"/>
      <g id="Group_115" data-name="Group 115" transform="translate(-471 -633)">
        <path id="Path_66" data-name="Path 66" d="M8.561,8.56a1.063,1.063,0,0,1,1.5,0l3.5,3.491,3.5-3.491a1.061,1.061,0,1,1,1.5,1.5l-3.5,3.491,3.5,3.491a1.061,1.061,0,1,1-1.5,1.5l-3.5-3.491-3.5,3.491a1.061,1.061,0,1,1-1.5-1.5l3.5-3.491-3.5-3.491A1.059,1.059,0,0,1,8.561,8.56Z" transform="translate(469.441 631.45)" fill="#fff"/>
        <path id="Path_67" data-name="Path 67" d="M8.894,3.876a60.267,60.267,0,0,1,13.26,0,5.638,5.638,0,0,1,4.978,4.918,57.724,57.724,0,0,1,0,13.432,5.639,5.639,0,0,1-4.978,4.918,60.281,60.281,0,0,1-13.26,0,5.638,5.638,0,0,1-4.978-4.918,57.719,57.719,0,0,1,0-13.432A5.638,5.638,0,0,1,8.894,3.876ZM21.918,5.983a58.139,58.139,0,0,0-12.788,0,3.516,3.516,0,0,0-3.1,3.058,55.6,55.6,0,0,0,0,12.939,3.516,3.516,0,0,0,3.1,3.058,58.141,58.141,0,0,0,12.788,0,3.516,3.516,0,0,0,3.1-3.058,55.6,55.6,0,0,0,0-12.939A3.516,3.516,0,0,0,21.918,5.983Z" transform="translate(467.477 629.49)" fill="#fff" fillRule="evenodd"/>
      </g>
    </svg>

  );
};

export default UnFollowIcon;
