import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '../components/UI/Button';
import ArrowNext from '../components/UI/Icons/ArrowNext';
import FollowIcon from '../components/UI/Icons/FollowIcon';
import { useAuth } from '../context/auth';
import { getToken } from '../utils/auth';
import axios from 'axios';

const Follow = () => {
  const [list, setList] = useState();
  const [fetch, setFetch] = useState(false);
  const history = useHistory();
  const { currentUser } = useAuth();
  const token = getToken();
  useEffect(() => {
    axios.get(`/api/user/follow/list`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setList(res.data.data);
    });
  }, [fetch]);

  const handleFollow = (id) => {
    axios.post(`/api/user/follow`, {user_id: id}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      setFetch(state => !state);
    });
  };
  const handleClick = () => {
    history.push(`/create-${currentUser.role === 'user' ? 'company' : 'instructor'}-profile`);
  };

  return (
    <div className="flex justify-center w-full h-auto min-h-screen bg-primary_bg">
      <div className="relative bg-white w-800px  px-8 h-full flex flex-col justify-start items-center">

        <div className='flex flex-col justify-center items-center mt-16 mb-8 md:mb-16 w-5/7'>
          <h1 className='text-2xl md:text-4xl text-primary font-body font-semibold capitalize' >{currentUser.role === 'user' ? 'instructors' : 'companies'} to follow</h1>
          <p className='text-sm md:text-lg text-primary font-normal opacity-75 text-center' >Based on your choices, we recommend following these {currentUser.role === 'user' ? 'instructors' : 'companies'}.</p>
        </div>

        <ul className='flex flex-col justify-start items-start gap-y-4 w-full md:w-2/3'>
          {list && list.map(follow => <li key={follow.id} className='w-full border border-gray-300 rounded-md p-3 flex justify-between items-center'>
            <div className='flex justify-start items-center mr-5 md:mr-10 w-24 h-12 md:h-16' >
              <img src={follow.photo} alt="company" className=' h-full object-contain p-1 border border-gray-300 rounded-lg' />
            </div>
            <div className='flex flex-col justify-center items-start w-full' >
              <h5 className='text-primary text-sm md:text-base mb-1 md:mb-2'>{follow.role !== 'user' ? follow.full_name : follow.company_details ? follow.company_details.name : follow.full_name}</h5>
              <p className='text-gray-500 text-xs'>{follow.role === 'user' ? follow.company_details && follow.company_details.category && follow.company_details.category.label : ''} -- {follow.country && follow.country.label}</p>
            </div>
            <div onClick={() => handleFollow(follow.id)} className='mx-2 md:mx-4 cursor-pointer' >
              <FollowIcon/>
            </div>
          </li>)}
        </ul>

        <div className='flex flex-col justify-start items-center my-16'>
          <Button className='text-primary border-2  mx-auto mb-4 hover:border-primary hover:font-bold' onClick={handleClick}> <ArrowNext /> Next</Button>
          <Link to={`/create-${currentUser.role === 'user' ? 'company' : 'instructor'}-profile`} className='text-secondary text-sm p-3 hover:text-primary'>
                      Skip For Now
          </Link>
        </div>

      </div>
    </div>
  );
};

export default Follow;
