/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import UserIcon from '../components/UI/Icons/UserIcon';
import EnvelopIcon from '../components/UI/Icons/EnvelopIcon';
import CompanyIcon from '../components/UI/Icons/CompanyIcon';
import PhoneIcon from '../components/UI/Icons/PhoneIcon';
import IndustryIcon from '../components/UI/Icons/IndustryIcon';
import WebsiteIcon from '../components/UI/Icons/WebsiteIcon';
import LocationIcon from '../components/UI/Icons/LocationIcon';
import { Link } from 'react-router-dom';
import CompanyDetailsCreate from '../components/CompanyDetails/Create';
import Modal from '../components/UI/Modal';

const UserInfoTab = ({ user, isCurrentUser, setFetch }) => {
  const [showCompanyDetailsModal, setShowCompanyDetailsModal] = useState(false);

  return (
    <div className='flex flex-col justify-start items-center w-full px-32'>
      <div className='flex flex-col justify-start items-start w-full border border-gray-400 p-10 rounded-md my-10' >
        <h5 className='text-primary capitalize text-xl font-body font-semibold mb-8' >Admin info</h5>
        <div className='flex flex-col justify-start items-start w-full gap-6'>
          <div className='flex justify-start items-center gap-x-4 text-md text-primary'>
            <UserIcon/> {user.full_name}
          </div>
          <div className='flex justify-start items-center gap-x-4 text-md text-primary'>
            <EnvelopIcon/> {user.email}
          </div>
        </div>
        {isCurrentUser && <div className='w-full flex justify-center items-center mt-4' >
          <Link to='/forgot-password' className="py-3 px-16 text-primary border border-primary uppercase rounded-lg hover:font-semibold focus:outline-none" >update password</Link>
        </div>}
      </div>
      <div className='flex flex-col justify-start items-start w-full border border-gray-400 p-10 rounded-md my-10' >
        <h5 className='text-primary capitalize text-xl font-body font-semibold mb-8' >company info</h5>
        {user.company_details && <div className='flex flex-col justify-start items-start w-full gap-6'>
          <div className='flex justify-start items-center gap-x-4 text-md text-primary'>
            <CompanyIcon/> {user.company_details.name}
          </div>
          <div className='flex justify-start items-center gap-x-4 text-md text-primary'>
            <IndustryIcon/> <span>{user.company_details.category && user.company_details.category.label} {user.company_details.subCategory && user.company_details.subCategory.label}</span>
          </div>
          <div className='flex justify-start items-center gap-x-4 text-md text-primary'>
            <LocationIcon/> <span>{user.country && user.country.label} {user.city && user.city.label}</span>
          </div>
          <div className='flex justify-start items-center gap-x-4 text-md text-primary'>
            <PhoneIcon/> <span>{user.phone_number}</span>
          </div>
          {user.website && <div className='flex justify-start items-center gap-x-4 text-md text-primary'>
            <WebsiteIcon/> <span>{user.category.website}</span>
          </div>}
        </div>}
        {isCurrentUser && <div className='w-full flex justify-center items-center mt-4' >
          <button onClick={() => setShowCompanyDetailsModal(true)} className="py-3 px-16 text-primary border border-primary uppercase rounded-lg hover:font-semibold focus:outline-none" >Edit about company</button>
        </div>}
      </div>
      {showCompanyDetailsModal && <Modal title='Edit company details' onClose={() => setShowCompanyDetailsModal(false)} > <CompanyDetailsCreate userId={user.id} success={() => setShowCompanyDetailsModal(false)} setFetch={setFetch}/></Modal>}
    </div>
  );
};

export default UserInfoTab;
