/* eslint-disable react/prop-types */
import * as React from 'react';
import {
  Breadcrumbs,
  Link
} from '@mui/material';
import {Link as ReactRouterLink} from 'react-router-dom';

const BasicBreadcrumbs = ({url, back, active}) => {
  return (
    <div role="presentation" className='mb-3'>
      <Breadcrumbs aria-label="breadcrumb">
        <ReactRouterLink to='/admin'>
          <Link underline="hover" color="inherit">
            Dashboard
          </Link>
        </ReactRouterLink>
        {url && back && <ReactRouterLink to={`${url}`}>
          <Link underline="hover" color="inherit" >
            {back}
          </Link>
        </ReactRouterLink>}
        <h6>{active}</h6>
      </Breadcrumbs>
    </div>
  );
};

export default BasicBreadcrumbs;
