/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSubCategory, listSubCategory } from '../../../../store/reducer/subCategorySlice';
import BasicTable from '../../../../components/UI/BasicTable';
import BasicBreadcrumbs from '../../../../components/UI/BasicBreadcrubs';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';

const ListSubCategories = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { subCategories } = useSelector(state => state.subCategory);

  const editAction = (id) => history.push(`/admin/sub-categories/${id}`);
  const deleteAction = (id) => {
    const successCallback = () => {
      history.push('/admin/sub-categories');
      dispatch(listSubCategory());
    };
    dispatch(deleteSubCategory({id, successCallback}));
  };

  useEffect(() => {
    dispatch(listSubCategory());
  }, [dispatch]);

  const tableCells = [
    {
      Header: 'Name',
      accessor: 'name',
      align: 'left'
    },
    {
      Header: 'Label',
      accessor: 'label',
      align: 'left'
    },
    {
      Header: 'Category',
      accessor: 'category',
      align: 'left'
    }
  ];

  return (
    <div className='container mx-auto h-full'>
      <div className='ml-5 mt-12'>
        <div className='flex justify-between items-center mb-4'>
          <BasicBreadcrumbs active='Sub Categories' />
          <Button variant="contained" color="primary" onClick={() => history.push('/admin/sub-category')}>
            Create Sub Category
          </Button>
        </div>
        {subCategories && <BasicTable title='Sub Categories' rows={subCategories.data} cells={tableCells} editAction={editAction} deleteAction={deleteAction} />}
      </div>
    </div>
  );
};

export default ListSubCategories;
