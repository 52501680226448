/* eslint-disable react/prop-types */
import React from 'react';

const Button = ({className, children, ...restProps}) => {
  return (
    <button
      className={'flex flex-row justify-between items-center gap-x-12 rounded-lg px-4 py-2 font-normal uppercase text-sm outline-none focus:outline-none ease-linear transition-all duration-150 ' + className} {...restProps}>
      {children}
    </button>
  );
};

export default Button;
