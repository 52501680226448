/* eslint-disable react/prop-types */
import React from 'react';
import Comment from '../components/Comment';

const Comments = ({comments, userId, postId, fetchPost}) => {
  return (
    <div className='w-full flex flex-col'>
      {comments && comments.map(comment => (
        <Comment
          fetchPost={fetchPost}
          userId={comment.user.id}
          userName={comment.user.first_name + ' ' + comment.user.last_name}
          image={comment.user.photo}
          key={comment.id}
          id={comment.id}
          content={comment.content}/>))}
    </div>
  );
};

export default Comments;
