/* eslint-disable react/prop-types */
/* eslint-disable no-unneeded-ternary */
import { Form, Formik } from 'formik';
import React, {useState} from 'react';
import FormControl from '../components/UI/FormControl';
import { getToken } from '../utils/auth';
import axios from 'axios';
import TextError from '../components/UI/TextError';
import Alert from '../components/UI/Alert';

const SettingsTab = ({setFetch, user}) => {
  const [error, setError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const token = getToken();
  const INITIALVALUES = {
    notify_on_apply_job: user.settings ? (user.settings.notify_on_apply_job === 1 ? true : false) : '',
    notify_on_like_post: user.settings ? (user.settings.notify_on_like_post === 1 ? true : false) : '',
    notify_on_comment_post: user.settings ? (user.settings.notify_on_comment_post === 1 ? true : false) : true,
    _method: 'put'
  };

  const onSubmit = values => {
    axios.post(`/api/profile/${user.id}`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(() => {
      setShowAlert(true);
      setFetch(state => !state);
    }).catch(error => setError(error.message));
  };

  const Delete = () => {
    axios.delete(`/api/profile/${user.id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
    }).catch(error => setError(error.message));
  };
  return (
    <div className='flex flex-col justify-start items-center w-full px-32'>
      {showAlert && <Alert hide={() => setShowAlert(false)} title="Settings updated successfully" />}
      <div className='flex flex-col justify-start items-start w-full border border-gray-400 p-10 rounded-md my-10' >
        <h5 className='text-primary capitalize text-xl font-body font-semibold mb-8' >Email preferences</h5>
        <h6 className='text-primary capitalize text-md mb-4'>Send emails when:</h6>
        {error && <TextError>{error }</TextError>}
        <Formik
          initialValues={INITIALVALUES}
          onSubmit={onSubmit}>
          <Form className='w-full'>
            {user.role === 'user' && <FormControl control='checkbox' name='notify_on_apply_job' label='Someone apply to my job'/>}
            <FormControl control='checkbox' name='notify_on_like_post' label='Someone likes my post' />
            <FormControl control='checkbox' name='notify_on_comment_post' label='Someone comments on my post' />
            <div className='w-full flex justify-center items-center mt-4' >
              <button className="py-3 px-16 text-primary border border-primary uppercase rounded-lg hover:font-semibold focus:outline-none" type='submit' >save changes</button>
            </div>
          </Form>
        </Formik>
      </div>
      <div className='flex flex-col justify-center items-center w-full p-10 bg-blue-100 mb-10 rounded-lg gap-6'>
        <h6 className='text-primary capitalize' >Not happy with Peconto?</h6>
        <button className="py-3 px-16 text-primary border border-primary uppercase rounded-lg hover:font-semibold hover:text-red-500 hover:border-red-500 focus:outline-none" onClick={Delete} >Delete account</button>
      </div>
    </div>
  );
};

export default SettingsTab;
