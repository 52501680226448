/* eslint-disable react/prop-types */
import React from 'react';
import {Form} from 'formik';
import FormControl from '../../UI/FormControl';
import { months, years } from '../../utils/constants';
import Button from '../../UI/Button';
import DegreeIcon from '../../UI/Icons/DegreeIcon';
import CompanyIcon from '../../UI/Icons/CompanyIcon';
import CalendarIcon from '../../UI/Icons/CalendarIcon';
import ArrowNext from '../../UI/Icons/ArrowNext';

const EducationForm = ({edit}) => {
  return (
    <Form className="w-full">
      <FormControl control='input' type='text' label='Degree title...' name='degree' Icon={<DegreeIcon/> }/>
      <FormControl control='input' type='text' label='University...' name='school' Icon={<CompanyIcon/>} />
      <div className='grid gap-4 grid-cols-2'>
        <FormControl control='select' label='Start Month' name='start_month' options={months} Icon={<CalendarIcon/>} />
        <FormControl control='select' label='Start Year' name='start_year' options={years} Icon={<CalendarIcon/>} />
      </div>
      <div className='grid gap-4 grid-cols-2'>
        <FormControl control='select' label='End Month' name='end_month' options={months} Icon={<CalendarIcon/>} />
        <FormControl control='select' label='End Year' name='end_year' options={years} Icon={<CalendarIcon/>} />
      </div>
      {edit ? <div className="border-t sticky w-full flex flex-row justify-between items-center bottom-0 bg-white py-3">
        <Button className='text-red-600 bg-transparent rounded-full hover:bg-gray-300' type='reset' >Delete</Button>
        <Button className='text-primary border-2  mx-auto hover:border-primary hover:font-bold' type='submit'>Update</Button>
      </div> : <div className='border-t sticky w-full flex flex-row-reverse justify-between items-center bottom-0 bg-white py-3 '>
        <Button className='text-primary border-2  mx-auto hover:border-primary hover:font-bold' type='submit'> <ArrowNext/> Next</Button>
      </div>}
    </Form>
  );
};

export default EducationForm;
