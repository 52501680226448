/* eslint-disable react/prop-types */
import axios from 'axios';
import React, { useState } from 'react';
import Button from '../../../UI/Button';
import Modal from '../../../UI/Modal';
import { getToken } from '../../../../utils/auth';

const CoverImageEdit = ({onClose, fetchInfo, coverImage}) => {
  const [image, setImage] = useState(null);
  const token = getToken();

  const handleChangeImage = (e) => {
    const image = e.target.files[0];

    if (image === '' || image === undefined) {
      // eslint-disable-next-line no-undef
      alert(`not an image, the file is a ${typeof image}`);
      return;
    }
    setImage(image);
  };

  const handleSubmit = () => {
    if (image !== '') {
    // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData.append('cover_photo', image);
      formData.append('_method', 'PUT');

      axios.post('/api/profile/image', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(() => {
        onClose();
        fetchInfo();
      }).catch();
    } else {
      onClose();
    }
  };

  return (
    <Modal title="Edit Profile Image" onClose={onClose}>
      <div className="flex flex-col justify-start items-center">
        <div className='w-full h-64'>
          {image
            ? <img className='object-cover w-full h-full  cursor-pointer' src={URL.createObjectURL(image)} />
            : <img className='object-cover w-full h-full  cursor-pointer' src={coverImage}/>}
        </div>
        <div className="w-full p-3">
          <input
            type="file"
            accept="image/gif, image/jpeg, image/png"
            name="image"
            id="post"
            onChange={handleChangeImage}
            style={{ display: 'none' }}
          />
          <label className='p-2 bg-gray-200 hover:bg-gray-400 rounded-lg cursor-pointer' htmlFor="post">
            add cover
          </label>
        </div>
        <div className="border-t sticky w-full flex flex-row justify-between items-center bottom-0 bg-white py-3">
          <Button className='text-white bg-blue-700 rounded-full ml-auto hover:bg-blue-900' onClick={handleSubmit}>Submit</Button>
        </div>
      </div>
    </Modal>
  );
};

export default CoverImageEdit;
