/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import { Formik } from 'formik';
import EducationForm from '../Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import { useAuth } from '../../../context/auth';
import { getToken } from '../../../utils/auth';
import axios from 'axios';
import TextError from '../../UI/TextError';

const EducationCreate = ({fetchEdu, onClose}) => {
  const token = getToken();
  const {currentUser} = useAuth();
  const [error, setError] = useState('');
  const INITIALVALUES = {
    school: '',
    degree: '',
    field_study: '',
    start_month: '',
    start_year: '',
    end_month: '',
    end_year: '',
    grade: '',
    activities_societies: '',
    description: '',
    user_id: currentUser.id
  };
  const onSubmit = values => {
    axios.post(`/api/educations`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(() => {
      onClose();
      fetchEdu && fetchEdu(values.school);
    }).catch(error => setError(error.messaage));
  };

  return (
    <Fragment>
      {error && <TextError>{error}</TextError>}
      <Formik
        initialValues = {INITIALVALUES}
        validationSchema = {VALIDATIONSHEMA}
        onSubmit={onSubmit}>
        {formik => (
          <EducationForm />
        )}
      </Formik>
    </Fragment>
  );
};

export default EducationCreate;
