import React, {useState} from 'react';
import Recaptcha from 'react-recaptcha';
import { useHistory, useParams } from 'react-router-dom';
import { register } from '../api/auth';
import useInputValue from './input-value';
import TextError from './UI/TextError';
import axios from 'axios';
import EnvelopIcon from './UI/Icons/EnvelopIcon';
import LockIcon from './UI/Icons/LockIcon';
import UserIcon from './UI/Icons/UserIcon';
import { useAuth } from '../context/auth';

function RegisterWithEmail () {
  let history = useHistory();
  const {setToken, setCurrentUser} = useAuth();
  let { role: userRole } = useParams();
  let email = useInputValue('email');
  let firstName = useInputValue('first_name');
  let lastName = useInputValue('last_name');
  let password = useInputValue('password');
  let passwordConfirmation = useInputValue('password_confirmation');

  const [isVerified, setIsVerified] = useState(true);
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isVerified) {
      register({
        email: email.value,
        first_name: firstName.value,
        last_name: lastName.value,
        role: userRole || 'user',
        password: password.value,
        password_confirmation: passwordConfirmation.value
      })
        .then(({ user, token }) => {
          if (token && user) {
            setToken(token);
            setCurrentUser(user);
            axios.post(`/api/email/verify/resend`, {}, {
              headers: {
                'Authorization': `Bearer ${token}`
              }});
            history.push('/verify');
          }
        })
        .catch((error) => {
          error.json().then(({ errors }) => {
            [
              email,
              firstName,
              lastName,
              password
            ].forEach(({ parseServerError }) =>
              parseServerError(errors)
            );
          });
        });
    } else {
      setError('Please verified your are not a robot');
    }

    register({
      email: email.value,
      first_name: firstName.value,
      last_name: lastName.value,
      role: userRole || 'user',
      password: password.value,
      password_confirmation: passwordConfirmation.value
    })
      .then(({ user, token }) => {
        if (token && user) {
          setToken(token);
          setCurrentUser(user);
          axios.post(`/api/email/verify/resend`, {}, {
            headers: {
              'Authorization': `Bearer ${token}`
            }});
          history.push('/verify');
        }
      })
      .catch((error) => {
        error.json().then(({ errors }) => {
          [
            email,
            firstName,
            lastName,
            password
          ].forEach(({ parseServerError }) =>
            parseServerError(errors)
          );
        });
      });
  };

  const verifyCallback = (response) => {
    if (response) {
      setIsVerified(true);
    }
  };

  return (
    <form onSubmit={handleSubmit} method="POST" className='h-full w-4/5 md:w-1/2'>

      <div className="my-4">
        <div className='flex justify-start items-center border border-gray-300 rounded-md py-2 px-4'>
          <UserIcon/>
          <input
            id="first_name"
            type="text"
            name="first_name"
            placeholder='First Name...'
            className={` w-full py-1 px-3 text-primary font-base focus:outline-none ${firstName.error ? 'border-red-500' : ''}`}
            required
            autoFocus
            {...firstName.bind}
          />
        </div>

        {firstName.error && <p className="text-red-500 text-xs pt-2">{firstName.error}</p>}
      </div>

      <div className="my-4">
        <div className='flex justify-start items-center border border-gray-300 rounded-md py-2 px-4'>
          <UserIcon/>
          <input
            id="last_name"
            type="text"
            name="last_name"
            placeholder='Last Name...'
            className={` w-full py-1 px-3 text-primary font-base focus:outline-none ${lastName.error ? 'border-red-500' : ''}`}
            required
            autoFocus
            {...lastName.bind}
          />
        </div>

        {lastName.error && <p className="text-red-500 text-xs pt-2">{lastName.error}</p>}
      </div>

      <div className="my-4">
        <div className='flex justify-start items-center border border-gray-300 rounded-md py-2 px-4'>
          <EnvelopIcon/>
          <input
            id="email"
            type="email"
            name="email"
            placeholder='Email address...'
            className={` w-full py-1 px-3 text-primary font-base focus:outline-none ${email.error ? 'border-red-500' : ''}`}
            required
            autoFocus
            {...email.bind}
          />
        </div>

        {email.error && <p className="text-red-500 text-xs pt-2">{email.error}</p>}
      </div>

      <div className="mb-3">
        <div className='flex justify-start items-center border border-gray-300 rounded-md py-2 px-4'>
          <LockIcon/>
          <input
            type="password"
            id="password"
            name="password"
            placeholder='Password...'
            className={`w-full py-1 px-3 text-primary font-base${
              password.error ? 'border-red-500' : ''
            }`}
            required
            {...password.bind}
          />
        </div>
        {password.error && (
          <p className="text-red-500 text-xs pt-2">
            {password.error}
          </p>
        )}
      </div>

      <div className="mb-3">
        <div className='flex justify-start items-center border border-gray-300 rounded-md py-2 px-4'>
          <LockIcon/>
          <input
            type="password"
            id="password-confirmation"
            name="password_confirmation"
            placeholder='Confirm Password...'
            className={`w-full py-1 px-3 text-primary font-base${
              password.error ? 'border-red-500' : ''
            }`}
            required
            {...passwordConfirmation.bind}
          />
        </div>
      </div>

      {error && <TextError>{error}</TextError>}

      <div className="mt-4 mb-10  md:ml-10">
        <Recaptcha
          sitekey={process.env.MIX_RECAPTCHA_SITE_KEY}
          render="explicit"
          verifyCallback={verifyCallback}
        />
      </div>
      <div className="mb-4">
        <button className="rounded-lg p-4 text-white bg-primary w-full font-bold">
                    CREATE ACCOUNT
        </button>
      </div>
    </form>
  );
}

export default RegisterWithEmail;
