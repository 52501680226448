/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { useAuth } from '../../../context/auth';
import Button from '../../UI/Button';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import TextError from '../../UI/TextError';
import PhotoIcon from '../../UI/Icons/PhotoIcon';
import VideoIcon from '../../UI/Icons/VideoIcon';
import { AiOutlineClose } from 'react-icons/ai';
import IconButton from '../../UI/IconButton';

const PostForm = ({edit, postId, fetchPosts, onClose, fetchPost}) => {
  const {currentUser} = useAuth();
  const {id} = currentUser;
  const token = getToken();
  const [error, setError] = useState();
  const [image, setImage] = useState('');
  const [content, setContent] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [subCategoryId, setSubCategoryId] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    axios.get(`/api/categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCategories(res.data.data);
    });
    if (edit) {
      axios.get(`/api/posts/${postId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(res => {
        setContent(res.data.data.content);
        setImage(res.data.data.image);
      }).catch(error => console.log(error));
    }
    ;
  }, []);

  useEffect(() => {
    if (categoryId !== '') {
      axios.get(`/api/categories/${parseInt(categoryId)}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(res => {
        setSubCategories(res.data.data.sub_categories.data);
      });
    }
  }, [categoryId]);

  const handleChangeImage = (e) => {
    const image = e.target.files[0];

    if (image === '' || image === undefined) {
      // eslint-disable-next-line no-undef
      alert(`not an image, the file is a ${typeof image}`);
      return;
    }
    setImage(image);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('content', content);
    formData.append('image', image);
    formData.append('video_url', videoUrl);
    formData.append('user_id', id);
    formData.append('category_id', categoryId);
    formData.append('sub_category_id', subCategoryId);
    if (edit) {
      axios.put(`/api/posts/${postId}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then().catch(error => setError(error.message));
      onClose();
      setTimeout(() => {
        fetchPost();
      }, 500);
    } else {
      axios.post(`/api/posts`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then().catch(error => setError(error.message));
      onClose();
      setTimeout(() => {
        fetchPosts();
      }, 500);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-3 flex flex-col justify-start items-center bg-secondary bg-opacity-10 border border-gray-400 rounded-lg">
      {error && <TextError>{error}</TextError>}
      <div className="flex flex-row justify-start items-start w-full mb-3">
        <div className='flex-grow'>
          <div className='flex justify-between items-center'>
            <div className="flex justify-start items-center">
              <img className='w-12 h-12 border border-gray-300 rounded-lg mr-4 cursor-pointer' src={currentUser.photo} />
              <p className='text-primary my-3' >Start a post...</p>
            </div>
            <IconButton onClick={onClose} className='ml-2 p-2 hover:bg-gray-300'>
              <AiOutlineClose/>
            </IconButton>
          </div>
          <textarea
            className='w-full my-3 h-32 p-3 focus:outline-none bg-white rounded-md border border-primary'
            value={content}
            onChange={(event) => setContent(event.target.value)}
            placeholder="What do you want to talk about?"
            autoFocus={true}
          ></textarea>
          <div className='flex justify-start items-center gap-x-3 pb-4 w-full border-b border-gray-400'>
            <div className="cursor-pointer w-1/2">
              <input
                type="file"
                accept="image/gif, image/jpeg, image/png"
                name="image"
                id="post"
                onChange={handleChangeImage}
                style={{ display: 'none' }}
              />
              <label className="cursor-pointer flex flex-no-wrap justify-start items-center gap-x-5 bg-white rounded-md p-3 border hover:border-primary" htmlFor="post">
                <PhotoIcon/> <span className='text-primary capitalize' >upload photo</span>
              </label>
            </div>
            <div className="cursor-pointer flex justify-start items-center bg-white rounded-md p-3 border hover:border-primary w-1/2">
              <VideoIcon/>
              <input type='text' className="cursor-pointer focus:outline-none ml-5 w-2/3" name='videoUrl' id='videoUrl' placeholder='Link video...' onChange={(e) => setVideoUrl(e.target.value)}/>
            </div>
          </div>
          <div className="flex justify-start items-center gap-x-3 mt-4 mb-6 w-full">
            <div className="flex justify-start items-center border border-gray-300 bg-white rounded-md py-2 px-4 w-1/2">
              <select className='w-full py-1 px-3 text-primary font-base focus:outline-none' id='category_id' name='category_id' onChange={(event) => setCategoryId(event.target.value)}>
                <option value='' defaultValue >Industry... </option>
                {categories.map(option => (<option key={option.id} value={option.id}>{option.label}</option>))}
              </select>
            </div>
            <div className="flex justify-start items-center border border-gray-300 bg-white rounded-md py-2 px-4 w-1/2">
              <select className='w-full py-1 px-3 text-primary font-base focus:outline-none' id='sub_category_id' name='sub_category_id' onChange={(event) => setSubCategoryId(event.target.value)}>
                <option value='' defaultValue >Category... </option>
                {subCategories.map(option => (<option key={option.id} value={option.id}>{option.label}</option>))}
              </select>
            </div>
          </div>
          <div className='w-full h-1/2 relative'>
            {image && (
              <Fragment>
                <img
                  className='object-contain rounded-lg mb-3'
                  src={URL.createObjectURL(image)}
                  alt="shared_image"/>
                <Button onClick={() => setImage(null)} className='absolute top-0 right-0 bg-gray-200 bg-opacity-25 rounded-full hover:bg-opacity-75 mt-2 mr-2'>X</Button>
              </Fragment>
            )}
          </div>
          <div className="flex flex-row justify-between items-center w-full">
            <button type='submit' className='rounded-lg p-4 text-white bg-primary w-full font-bold uppercase border-2 border-primary hover:bg-secondary hover:text-white'>{edit ? 'update' : 'publish post'}</button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PostForm;
