/* eslint-disable react/prop-types */
import React, {useEffect, useState, useRef } from 'react';
import Comments from '../../containers/comments';
import Card from '../UI/Card';
import IconButton from '../UI/IconButton';
import { useAuth } from '../../context/auth';
import { getToken } from '../../utils/auth';
import axios from 'axios';
import Modal from '../UI/Modal';
import PostForm from './Form';
import OptionIcon from '../UI/Icons/OptionIcon';
import LikeIcon from '../UI/Icons/LikeIcon';
import CommentIcon from '../UI/Icons/CommentIcon';
import CreateComment from '../Comment/Create';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';

const Post = ({id, userId, fetchPosts, industry, country}) => {
  const [showComments, setShowComments] = useState(false);
  const [showEditList, setShowEditList] = useState(false);
  const [showModaledit, setShowModalEdit] = useState(false);
  const [fetchPost, setFetchPost] = useState(false);
  const [post, setPost] = useState(null);
  const history = useHistory();
  const token = getToken();
  const {currentUser} = useAuth();

  const toggleEditList = () => {
    setShowEditList(prevState => !prevState);
  };

  const toggleFetchPost = () => {
    setFetchPost(prevState => !prevState);
  };

  useEffect(() => {
    axios.get(`/api/posts/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then((res) => setPost(res.data.data)).catch();
  }, [fetchPost]);

  const DeletePost = (postId) => {
    axios.delete(`/api/posts/${postId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then().catch();
    toggleEditList();
    setTimeout(() => {
      fetchPosts();
    }, 500);
  };

  let menuRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', (event) => {
      if (!menuRef.current.contains(event.target)) {
        setShowEditList(false);
      }
    });
  }, []);

  const LikePost = () => {
    axios.post(`/api/like`, {user_id: userId, post_id: id}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(setTimeout(() => (toggleFetchPost()), 500)).catch();
  };

  const handleClickResult = (username) => {
    history.push(`/profile/${username}`);
  };

  const handleShowComment = () => setShowComments((prevState) => !prevState);
  return (
    post && <Card className='bg-white flex-col shadow-lg mt-4 mb-6 '>
      <div className='divide-y'>
        <div>
          <div className='flex flex-row justify-between items-center'>
            <div className="flex flex-row justify-start items-center mb-6">
              {post.user.photo ? <img onClick={() => handleClickResult(post.user.username)} className='w-12 h-12 p-1 border border-gray-300 rounded-lg mr-4 cursor-pointer' src={post.user.photo} /> : <img className='w-12 rounded-full mr-2 cursor-pointer' src="https://www.iotric.com/assets/images/dummy.png" />}
              <div>
                <h4 onClick={() => handleClickResult(post.user.username)} className='text-primary text-base hover:underline cursor-pointer'>{post.user.first_name}</h4>
                <p className='text-gray-500 text-xs capitalize'>{industry} -- {country}</p>
              </div>
            </div>
            <div className='flex flex-row justify-end items-center relative'>
              <p className='text-xs text-gray-500'>{post.posted_at}</p>
              {currentUser.id === userId &&
         <IconButton onClick={toggleEditList} className='ml-2 p-2 hover:bg-gray-300'>
           <OptionIcon/>
         </IconButton>}
              {showEditList && <div ref={menuRef} className="absolute top-0 right-0 rounded-lg p-3 bg-white border-1 mt-8 shadow-lg">
                <ul>
                  {/* <li onClick={handleClickEdit} className='text-sm font-semibold cursor-pointer p-2 hover:bg-gray-200 rounded-lg'>Edit</li> */}
                  <li onClick={() => DeletePost(id)} className='text-sm font-semibold cursor-pointer p-2 hover:bg-gray-200 rounded-lg'>Delete</li>
                </ul>
              </div>}
            </div>
          </div>
        </div>
        <div>
          <div className='my-3 w-full'>
            <p className="text-base text-primary">{post.content}</p>
          </div>
          {post.image && <div className='mt-6 mb-3 w-full h-64'>
            <img className='object-cover rounded-lg w-full h-full' src={post.image} alt="post image" />
          </div>}
          {post.video_url && <div className='mt-6 mb-3 w-full h-64' >
            <ReactPlayer
              width='100%'
              height='100%'
              controls
              url={post.video_url}
            />
          </div>}

          <div className="flex flex-row justify-start my-3 gap-x-8">
            <div onClick={LikePost} className={`flex justify-center items-center text-sm  rounded-md border border-gray-400 p-2 hover:border-2 cursor-pointer hover:border-primary ${post.has_liked ? 'bg-secondary text-white' : 'text-primary'}`}>
              <LikeIcon fill={post.has_liked ? 'white' : null} width='15' height='15'/> <span className={`font-semibold ml-3 ${post.has_liked ? 'text-white' : 'text-primary '} `} >{post.likes.count} likes</span></div>
            <div className={`flex justify-center items-center text-sm  rounded-md border border-gray-400 p-2 hover:border-2 cursor-pointer hover:border-primary text-primary`} onClick={handleShowComment}>
              <CommentIcon width='15' height='15' />
              <span className={`font-semibold ml-3 text-primary`} >
                {post.comments.countwithReplies} Comments
              </span>
            </div>
          </div>
        </div>
        <div className="my-3">
          <CreateComment postId={id} fetchPost={toggleFetchPost} showComment={handleShowComment}/>
        </div>
        {showComments && <Comments fetchPost={toggleFetchPost} comments={post.comments.data} userId={userId} postId={id}/>}
      </div>
      {showModaledit &&
   <Modal
     title="Edit Post"
     onClose={() => setShowModalEdit(false)}>
     <PostForm
       onClose={() => setShowModalEdit(false)}
       edit
       postId={id}
       fetchPost={toggleFetchPost}
       fetchPosts={fetchPosts}/>
   </Modal>}
    </Card>
  );
};

export default Post;
