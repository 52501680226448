import { createSlice } from '@reduxjs/toolkit';

const user = createSlice({
  name: 'user',
  initialState: {
    users: {data: [], count: 0},
    fetched: undefined,
    updated: {},
    created: {}
  },
  reducers: {
    createUser (state, action) {},
    createUserSuccess (state, action) {
      state.created = action.payload;
    },
    editUser (state, action) {},
    editUserSuccess (state, action) {
      state.updated = action.payload;
    },
    getUser (state, action) {},
    getUserSuccess (state, action) {
      state.fetched = action.payload.data;
    },
    listUser (state, action) {},
    listUserSuccess (state, action) {
      state.users.data = action.payload.data;
      state.users.count = action.payload.count;
    },
    deleteUser (state, action) {},
    deleteUserSuccess (state, action) {}
  }
});

// Action creators are generated for each case reducer function
export const {
  createUser,
  createUserSuccess,
  editUser,
  editUserSuccess,
  getUser,
  getUserSuccess,
  listUser,
  listUserSuccess,
  deleteUser,
  deleteUserSuccess
} = user.actions;

export default user.reducer;
