/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import TextError from '../../UI/TextError';
import Card from '../../UI/Card';
import { useHistory, useParams } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import { useAuth } from '../../../context/auth';
import Modal from '../../UI/Modal';
import ApplyForm from '../ApplyForm/index';
import ContactInfo from '../../ContactInfo';

const JobShow = () => {
  const [job, setJob] = useState(false);
  const [error, setError] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showApplicantModal, setShowApplicantModal] = useState(false);
  const token = getToken();
  const {id} = useParams();
  const history = useHistory();
  const { currentUser } = useAuth();

  const handleClickUser = (username) => {
    history.push(`/profile/${username}`);
  };

  useEffect(() => {
    axios.get(`/api/jobs/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setJob(res.data.data);
    }).catch(error => setError(error.messaage));
  }, []);

  return job ? (
    <div className='w-full bg-gray-200 flex flex-col md:flex-row justify-start md:justify-center items-start p-6 gap-6'>
      <div className=' flex flex-col items-start justify-start w-full md:w-3/5'>
        {error && <TextError>{error}</TextError>}
        <Card className='bg-white w-full flex flex-col gap-3 divide-y'>
          <div className='flex flex-col w-full p-3'>
            <h3 className='text-3xl text-primary font-body '>{job.title}</h3>
            <p className='text-sm text-primary' >{job.brief || ''}</p>
          </div>
          {showDetails ? <Fragment>
            <div className="flex flex-col justify-start items-start text-sm w-full p-3">
              <p className='text-primary text-lg capitalize font-body' >job description</p>
              <div className='text-primary opacity-75 parse-quill' dangerouslySetInnerHTML={{__html: job.description}} ></div>
            </div>
            <div className="grid grid-cols-2 gap-16 w-full p-3">
              <div className='flex flex-col justify-start items-start gap-2 '>
                <h5 className='text-md text-primary font-body' >Job Details</h5>
                <div className='flex justify-between items-center w-full text-xs text-gray-400 capitalize' >
                  <p>category</p>
                  <p>{job.sub_category.label}</p>
                </div>
                <div className='flex justify-between items-center w-full text-xs text-gray-400 capitalize' >
                  <p>industry</p>
                  <p>{job.category.label}</p>
                </div>
                <div className='flex justify-between items-center w-full text-xs text-gray-400 capitalize' >
                  <p>country</p>
                  <p>{job.country.label}</p>
                </div>
                <div className='flex justify-between items-center w-full text-xs text-gray-400 capitalize' >
                  <p>type</p>
                  <p>{job.course_type}</p>
                </div>
                <div className='flex justify-between items-center w-full text-xs text-gray-400 capitalize' >
                  <p>language</p>
                  <p>{job.course_lang}</p>
                </div>
              </div>
              <div className='w-full p-3'>
                <h5 className='text-md text-primary font-body' >Specialties</h5>
                <div className='flex justify-start items-start flex-wrap gap-4 my-3' >
                  <div className='text-secondary p-2 border border-gray-400 cursor-pointer rounded-md text-sm'>{job.sub_category.label}</div>
                </div>
              </div>
            </div>
          </Fragment> : <div className='py-2'>
            <button onClick={() => setShowDetails(true)} className='flex justify-center items-center w-40 p-2 my-2 border border-gray-400 text-primary rounded-md' > <FiChevronDown className='mr-3 text-secondary' /> view all details </button>
          </div>}
        </Card>
        {currentUser.id === job.user.id &&
            <Card className='bg-white flex flex-col justify-start items-start w-full my-4' >
              <h5>Applicants ({job.applies.data && job.applies.data.length})</h5>
              { job.applies.data && job.applies.data.map(apply => <div className='my-2 py-4 border-b border-gray-400 flex justify-start items-start gap-6 w-full' key={apply.id} >
                <img className='w-12 h-12 border border-gray-400 rounded-md' src={apply.applicant.photo} alt="applicant photo" />
                <div className='flex flex-col justify-items-start items-start w-full'>
                  <h5 className='text-primary' >{apply.applicant.full_name}</h5>
                  <p className='text-gray-500' >{apply.applicant.email}</p>
                  <p className='text-primary text-sm' >{apply.cover_letter}</p>
                  <div className='flex justify-end items-center w-full gap-6'>
                    <a href={apply.resume} target="_blank" className='text-secondary font-semibold uppercase cursor-pointer text-sm' rel="noreferrer" >Download cv</a>
                    <button onClick={() => setShowApplicantModal(true)} className='text-secondary font-semibold uppercase cursor-pointer text-sm' >contact info</button>
                  </div>
                </div>
                {showApplicantModal && <Modal onClose={() => setShowApplyModal(false)} >
                  <ContactInfo userInfo={apply.applicant} onClose={() => setShowApplicantModal(false)} />
                </Modal>}
              </div>)}
            </Card>
        }

      </div>
      <div className='w-full md:w-1/5' >
        <Card className='shadow-none bg-white border border-gray-400 flex flex-col justify-start items-center divide-y gap-6' >
          <div className='flex flex-col justify-start items-center cursor-pointer' onClick={() => handleClickUser(job.user.username)} >
            {job.user.photo ? <img className='w-16 h-16 p-1 border border-gray-300 rounded-lg mr-4 cursor-pointer' src={job.user.photo} /> : <img className='w-16 h-16 p-1 border border-gray-300 rounded-lg mr-4 cursor-pointer' src="https://www.iotric.com/assets/images/dummy.png" />}
            <div className='mt-4' >
              <h4 className='text-primary text-lg text-center'>{job.user.company_details && job.user.company_details.name}</h4>
              <p className='text-gray-500 text-xs capitalize text-center'>{job.user.company_details && job.user.company_details.category && job.user.company_details.category.label} -- {job.user.country && job.user.country.label}</p>
            </div>
          </div>
          <div className='py-2 flex flex-col justify-start items-start w-full gap-2' >
            <h4 className='text-primary font-body' >Payment</h4>
            <div className='text-sm text-gray-500 w-full flex justify-between items-center capitalize' >
              <p>type</p>
              <p>{job.fixed_amount && 'fixed amount'}</p>
              <p>{job.hourly_rate && 'hourly rate' }</p>
            </div>
            <div className='text-sm text-gray-500 w-full flex justify-between items-center capitalize' >
              <p>amount</p>
              <p>{job.fixed_amount || job.hourly_rate } {job.currency && job.currency.label}</p>
            </div>
          </div>
          <div className='py-2 flex flex-col justify-start items-start w-full gap-2' >
            <h4 className='text-primary font-body' >Schedule</h4>
            <div className='text-sm text-gray-500 w-full flex justify-between items-center capitalize' >
              <p>start date</p>
              <p>{job.course_date || job.course_start }</p>
            </div>
            <div className='text-sm text-gray-500 w-full flex justify-between items-center capitalize' >
              <p>session time</p>
              <p>{job.course_time_type}</p>
            </div>
            <div className='text-sm text-gray-500 w-full flex justify-between items-center capitalize' >
              <p>duration</p>
              <p>{job.duration} {job.duration_type}</p>
            </div>
          </div>
          <div className='py-4 flex flex-col justify-center items-center w-full gap-4' >
            {currentUser.role !== 'user' && <button onClick={() => setShowApplyModal(true)} className='flex justify-center items-center py-3 bg-primary text-white rounded-md w-4/5 font-semibold uppercase text-sm hover:bg-secondary'>
                    Apply now!
            </button>}
            <button onClick={() => setShowContactModal(true)} className='flex justify-center items-center py-2 bg-white text-primary rounded-md w-4/5  uppercase text-sm border border-primary hover:font-semibold'>
                    contact info
            </button>
          </div>
        </Card>
      </div>
      {showApplyModal && <Modal title={<p className='text-lg text-gray-600'>Apply for... <br/> <span className='text-2xl text-primary' >{job.title}</span></p>} onClose={() => setShowApplyModal(false)} >
        <ApplyForm userId={currentUser.id} jobId={job.id} onClose={() => setShowApplyModal(false)} />
      </Modal>}
      {showContactModal && <Modal onClose={() => setShowApplyModal(false)} >
        <ContactInfo userInfo={job.user} onClose={() => setShowContactModal(false)} />
      </Modal>}

    </div>
  ) : null;
};

export default JobShow;
