/* eslint-disable react/prop-types */
import React from 'react';
import Courses from '../components/Courses';

const CoursesTab = ({user}) => {
  return (
    <div className='px-32'>
      <Courses instructorCourses={user.instructorCourses.data} userId={user.id}/>
    </div>
  );
};

export default CoursesTab;
