/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import JobForm from '../Form';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import TextError from '../../UI/TextError';
import Card from '../../UI/Card';
import { useParams } from 'react-router-dom';

const JobEdit = () => {
  const [job, setJob] = useState();
  const [error, setError] = useState('');
  const [countries, setCountries] = useState();
  const [currencies, setCurrencies] = useState();
  const [categories, setCategories] = useState();
  const token = getToken();
  const {id} = useParams();

  useEffect(() => {
    axios.get(`/api/jobs/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setJob(res.data.data);
    }).catch(error => setError(error.messaage));
    axios.get(`/api/countries`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCountries(res.data.data);
    }).catch(error => setError(error.messaage));
    axios.get(`/api/categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCategories(res.data.data);
    });
    axios.get(`/api/currencies`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCurrencies(res.data.data);
    });
  }, []);

  return job && countries && currencies && categories ? (
    <div className='w-full bg-gray-200'>
      <div className='container p-2 mx-auto flex flex-col items-start justify-start md:w-3/5 sm:w-full'>
        {error && <TextError>{error}</TextError>}
        <h3 className='text-2xl font-semibold my-6'>Edit Job</h3>
        <Card className='bg-white w-full'>
          <JobForm job={job} edit setError={setError} countries={countries} currencies={currencies} categories={categories} />
        </Card>
      </div>
    </div>
  ) : null;
};

export default JobEdit;
