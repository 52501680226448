import * as Yup from 'yup';

export const VALIDATIONSHEMA = Yup.object({
  first_name: Yup.string(),
  last_name: Yup.string(),
  username: Yup.string().required(),
  email: Yup.string().email().required(),
  phone_number: Yup.string().nullable(),
  address: Yup.string().nullable(),
  country_id: Yup.string().nullable(),
  city_id: Yup.string().nullable(),
  zip_code: Yup.string().nullable(),
  password: Yup.string().matches(/^.{8,}$/,
    'Must Contain at least 8 Characters'
  ).required(),
  confirm_password: Yup.string().oneOf([Yup.ref('password'), null], "Password doesn't match").required()
});
