/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getToken } from '../../../../utils/auth';
import TextError from '../../../UI/TextError';
import { Link, useHistory } from 'react-router-dom';
import Card from '../../../UI/Card';
import { courseLang } from '../../../utils/constants';
import IconButton from '../../../UI/IconButton';
import EditIcon from '../../../UI/Icons/EditIcon';
import { useAuth } from '../../../../context/auth';

const JobSearch = ({profile}) => {
  const token = getToken();
  const [results, setResults] = useState();
  const [countries, setCountries] = useState();
  const [categories, setCategories] = useState();
  const [cities, setCities] = useState();
  const history = useHistory();
  const { currentUser } = useAuth();

  const [countryId, setCountryId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [cityId, setCityId] = useState('');
  const [lang, setLang] = useState('');
  const [payment, setPayment] = useState('');

  const handleJobClick = (id) => history.push(`/job/show/${id}`);

  useEffect(() => {
    axios.get(`/api/job/filter/?category_id=${categoryId}&country_id=${countryId}&city_id=${cityId}&course_lang=${lang}&payment=${payment}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      setResults(res.data.data);
    });
  }, [categoryId, countryId, cityId, lang, payment]);

  useEffect(() => {
    axios.get(`/api/countries`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCountries(res.data.data);
    });
    axios.get(`/api/categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCategories(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (countryId !== '') {
      axios.get(`/api/countries/${parseInt(countryId)}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(res => {
        setCities(res.data.data.cities.data);
      });
    }
  }, [countryId]);

  return (
    <div className="relative w-full flex flex-col md:flex-row justify-start items-start gap-8">
      {!profile && currentUser.role !== 'user' && <Card className='shadow-none w-1/5 flex flex-col justify-start items-start gap-4 bg-white border border-gray-400' >
        <h6 className='text-base text-primary' >Filters</h6>

        <div className="flex justify-start items-center border border-gray-300 rounded-md py-1 px-4 w-full">
          <select onChange={(e) => setCategoryId(e.target.value)} className='w-full py-1 px-3 text-primary font-base focus:outline-none' id={categoryId} name={categoryId}>
            <option value='' defaultValue >Category...</option>
            {categories && categories.map(option => (<option key={option.id} value={option.id}>{option.label}</option>))}
          </select>
        </div>

        <div className="flex justify-start items-center border border-gray-300 rounded-md py-1 px-4 w-full">
          <select onChange={(e) => setCountryId(e.target.value)} className='w-full py-1 px-3 text-primary font-base focus:outline-none' id={countryId} name={countryId}>
            <option value='' defaultValue >Country...</option>
            {countries && countries.map(option => (<option key={option.id} value={option.id}>{option.label}</option>))}
          </select>
        </div>

        <div className="flex justify-start items-center border border-gray-300 rounded-md py-1 px-4 w-full">
          <select onChange={(e) => setCityId(e.target.value)} className='w-full py-1 px-3 text-primary font-base focus:outline-none' id={cityId} name={cityId}>
            <option value='' defaultValue >City...</option>
            {cities && cities.map(option => (<option key={option.id} value={option.id}>{option.label}</option>))}
          </select>
        </div>

        <div className="flex justify-start items-center border border-gray-300 rounded-md py-1 px-4 w-full">
          <select onChange={(e) => setLang(e.target.value)} className='w-full py-1 px-3 text-primary font-base focus:outline-none' id={lang} name={lang}>
            <option value='' defaultValue >Course language...</option>
            {courseLang.map(option => (<option key={option.id} value={option.id}>{option.label}</option>))}
          </select>
        </div>

        <h6 className='text-base text-primary' >Preferred Payment</h6>
        <div className="flex justify-start items-center border border-gray-300 rounded-md py-1 px-4 w-full">
          <select onChange={(e) => setPayment(e.target.value)} className='w-full py-1 px-3 text-primary font-base focus:outline-none' id={payment} name={payment}>
            <option value='any' defaultValue >Any Payment Type</option>
            <option value='fixed' >Fixed Amount</option>
            <option value='hourly' >Hourly rate</option>
          </select>
        </div>
      </Card>}
      <div className={`w-full ${currentUser.role === 'user' ? 'mx-auto md:w-3/5' : 'md:w-3/5 mx-auto'}`} >
        {results ? <div className='mt-4'>
          {results.length === 0
            ? <TextError>No Result Found</TextError>
            : <div className='flex flex-col justify-start'>
              {currentUser.role === 'user' && <div className='w-full p-10 flex flex-col justify-center items-center gap-6 bg-blue-200 rounded-lg mb-6'>
                <p className='text-base text-primary capitalize' >need a professional?</p>
                <Link to='/job' className='flex justify-center items-center py-3 bg-primary text-white rounded-md w-4/5 font-semibold uppercase text-sm hover:bg-secondary'>
                    post course request
                </Link>
              </div>}
              {!profile && <h2 className='pb-4 text-lg text-primary font-body'>Course requests posts based on your interests</h2>}
              {results.map(job => (
                job.user && <div key={job.id} className="flex flex-col justify-start items-start my-2 w-full border border-gray-400 rounded-md p-5 group bg-white divide-y">
                  <div className="flex flex-col justify-start items-start">
                    <h4 onClick={() => handleJobClick(job.id)} className="text-lg text-primary font-body cursor-pointer">{job.title}</h4>
                    <h6 className='text-md text-gray-500' >{job.brief || ''}</h6>
                    <div className='flex justify-start items-start gap-4 my-3' >
                      <div className='text-secondary p-2 border border-gray-400 cursor-pointer rounded-md'>{job.sub_category.label || ''}</div>
                    </div>
                  </div>
                  <div className='w-full py-3 grid grid-cols-4 gap-4' >
                    <div className="flex flex-col justify-start items-start">
                      <p className='text-xs text-gray-500 capitalize' >category</p>
                      <p className='text-sm text-primary' >{job.category.label}</p>
                    </div>
                    <div className="flex flex-col justify-start items-start">
                      <p className='text-xs text-gray-500 capitalize' >payment</p>
                      {job.fixed_amount && <p className='text-sm text-primary' >Fixed amount {job.fixed_amount} {job.currency.label}</p>}
                      {job.hourly_rate && <p className='text-sm text-primary' >Hourly rate {job.hourly_rate} {job.currency.label}</p>}
                    </div>
                    <div className="flex flex-col justify-start items-start">
                      <p className='text-xs text-gray-500 capitalize' >location</p>
                      <p className='text-sm text-primary' >{job.country ? job.country.label : null} {job.city ? job.city.label : null}</p>
                    </div>
                    <div className="flex flex-col justify-start items-start">
                      <p className='text-xs text-gray-500 capitalize' >language</p>
                      <p className='text-sm text-primary' >{job.course_lang || null}</p>
                    </div>
                  </div>
                  {job.user && <div className='flex justify-between items-center pt-3 w-full'>
                    <div className="flex flex-row justify-start items-center">
                      {job.user.photo ? <img className='w-12 h-12 p-1 border border-gray-300 rounded-lg mr-4 cursor-pointer' src={job.user.photo} /> : <img className='w-12 rounded-full mr-2 cursor-pointer' src="https://www.iotric.com/assets/images/dummy.png" />}
                      <div>
                        <h4 className='text-primary text-base'>{job.user.company_details && job.user.company_details.name}</h4>
                        <p className='text-gray-500 text-xs capitalize'>{job.user.company_details && job.user.company_details.category && job.user.company_details.category.label} -- {job.user.country && job.user.country.label}</p>
                      </div>
                    </div>
                    {currentUser.id === job.user.id && <div>
                      <IconButton onClick={() => history.push(`/job/${job.id}`)}>
                        <EditIcon/>
                      </IconButton>
                    </div>}
                  </div>}
                </div>
              ))}
            </div>
          }
        </div>
          : <img src='/images/loading-dots-blue.gif' alt='loading...'/>
        }
      </div>

    </div>
  );
};

export default JobSearch;
