/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {AiOutlinePlus} from 'react-icons/ai';
import Modal from '../UI/Modal';
import Card from '../UI/Card';
import CourseCreate from './Create';
import CourseEdit from './Edit';
import {getToken} from '../../utils/auth.js';
import axios from 'axios';
import { useAuth } from '../../context/auth';
import Button from '../UI/Button';
import OptionIcon from '../UI/Icons/OptionIcon';
import UserIcon from '../UI/Icons/UserIcon';
import LocationIcon from '../UI/Icons/LocationIcon';
import WebsiteIcon from '../UI/Icons/WebsiteIcon';

const Courses = ({instructorCourses, userId}) => {
  const token = getToken();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userCourses, setUserCourses] = useState(instructorCourses);
  const [fetch, setFetch] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const {currentUser} = useAuth();

  const isCurrentUser = userId === currentUser.id;

  const handleFetch = (id) => setFetch(id);
  const handleCloseEdit = () => setShowEditModal(false);
  const handleCloseCreate = () => setShowCreateModal(false);

  useEffect(() => {
    if (courseId !== null) {
      setShowEditModal(true);
    }
  }, [courseId]);

  useEffect(() => {
    if (fetch) {
      axios.get(`/api/instructor-courses`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(res => {
        setUserCourses(res.data.data);
      });
    }
  }, [fetch]);

  return (
    <Card className='w-full h-auto flex flex-col justify-start items-start rounded-lg bg-white shadow-none relative p-5 my-5'>
      <div className="flex w-full h-auto flex-col justify-start items-center mb-4">
        {isCurrentUser && <Button className='text-primary border px-8  mx-auto border-primary hover:font-bold py-3' onClick={() => setShowCreateModal(true)} > add new course  <AiOutlinePlus className="w-5 h-5"/> </Button>}
      </div>
      {userCourses.map(course => (
        <div key={course.id} className="flex flex-col justify-start items-start my-2 w-full border border-gray-400 px-6 py-3 rounded-md">
          <div className="flex flex-col justify-start items-start w-full">
            <div className='flex justify-between items-center w-full' >
              <h6 className="text-lg text-primary">{course.courses.label}</h6>
              {isCurrentUser && <button onClick={() => setCourseId(course.id)} className="ml-2 p-3 hover:bg-gray-200 rounded-full">
                <OptionIcon className="w-5 h-5"/>
              </button>}
            </div>
            <p className='text-primary font-light text-left text-xs w-full border-b border-gray-400 py-4'>
              {course.details}
            </p>
            <div className="flex justify-start items-center text-sm gap-x-3 my-4">
              <div className='flex justify-start items-center gap-x-3'>
                <UserIcon/>
                <div className='flex flex-col justify-start items-center gap-y-1'>
                  <p className='text-xs text-gray-500' >Capacity</p>
                  <p className='text-primary' >{course.capacity || ''} Members</p>
                </div>
              </div>

              <div className='flex justify-start items-center gap-x-3'>
                <WebsiteIcon/>
                <div className='flex flex-col justify-start items-center gap-y-1'>
                  <p className='text-xs text-gray-500' >Payment</p>
                  <p className='text-primary' >{course.fixed_amount && 'Fixed amount'} , {course.hourly_rate && 'Hourly rate'}</p>
                </div>
              </div>

              <div className='flex justify-start items-center gap-x-3'>
                <LocationIcon/>
                <div className='flex flex-col justify-start items-center gap-y-1'>
                  <p className='text-xs text-gray-500' >Location</p>
                  <p className='text-primary' >{course.online && 'Online'} , {course.on_site && 'On-Site'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {showCreateModal && <Modal title="Create Course" onClose={handleCloseCreate}><CourseCreate fetchCourse={handleFetch} onClose={handleCloseCreate} /></Modal>}
      {showEditModal && <Modal title="Edit Course" onClose={() => setShowEditModal(false)}><CourseEdit setCourseId={setCourseId} fetchCourse={handleFetch} onClose={handleCloseEdit} courseId={courseId} /></Modal>}
    </Card>
  );
};

export default Courses;
