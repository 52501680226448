import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTerms } from '../../store/reducer/termsSlice';

const TermsOfUse = () => {
  const { fetched } = useSelector(state => state.terms);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTerms({ id: '2' }));
  }, []);

  return (
    fetched ? <div className='w-full flex flex-col justify-start items-start gap-6'>
      <div className='flex flex-col justify-start items-start mb-4'>
        <p className='text-sm text-primary opacity-75 w-3/5' >Last updated {fetched.updated_at}.</p>
      </div>
      <div className='w-full flex flex-col justify-start items-start mb-8 gap-y-4 text-left'>
        <div className='text-primary opacity-75 parse-quill' dangerouslySetInnerHTML={{__html: fetched.description}} ></div>
      </div>
    </div>
      : '');
};

export default TermsOfUse;
