import { all } from 'redux-saga/effects';
import CategorySaga from './category';
import SubCategorySaga from './subCategory';
import userSaga from './user';
import privacySaga from './privacy';
import termsSaga from './terms';

export default function * sagas () {
  yield all([
    userSaga(),
    CategorySaga(),
    SubCategorySaga(),
    privacySaga(),
    termsSaga()
  ]);
};
