import React from 'react';
import ProfileContainer from '../containers/profile';

function Profile () {
  return (
    <div className="w-full mx-auto flex flex-col bg-primary_bg">
      <ProfileContainer/>
    </div>
  );
}

export default Profile;
