import {call, put, takeEvery} from 'redux-saga/effects';
import Client from '../../api/client';
import {
  editTerms,
  editTermsSuccess,
  getTerms,
  getTermsSuccess
} from '../reducer/termsSlice';

function * edit (action) {
  const data = action.payload.values;
  const {id} = action.payload;
  let cmsData;
  try {
    cmsData = yield call(() => Client(`/api/cms/${id}`, {method: 'PUT', body: data}));
  } catch (error) {
    if (action.payload.failedCallback) {
      action.payload.failedCallback();
    }
    return null;
  }
  yield put(editTermsSuccess(cmsData.data));
  if (action.payload.successCallback) {
    action.payload.successCallback();
  }
}

function * get (action) {
  const {id} = action.payload;
  let cmsData;
  try {
    cmsData = yield call(() => Client(`/api/cms/${id}`));
  } catch (error) {}
  yield put(getTermsSuccess(cmsData));
}

function * termsSaga () {
  yield takeEvery(editTerms.type, edit);
  yield takeEvery(getTerms.type, get);
};

export default termsSaga;
