/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import {Form} from 'formik';
import FormControl from '../../UI/FormControl';
import Button from '../../UI/Button';
import { getToken } from '../../../utils/auth';
import axios from 'axios';
import PhoneIcon from '../../UI/Icons/PhoneIcon';
import LocationIcon from '../../UI/Icons/LocationIcon';
import ArrowNext from '../../UI/Icons/ArrowNext';
import PhotoIcon from '../../UI/Icons/PhotoIcon';
import WebsiteIcon from '../../UI/Icons/WebsiteIcon';

const ContactInfoForm = ({ profileUrl, countries, values, userImage, role }) => {
  const token = getToken();
  const [cities, setCities] = useState([]);
  const [image, setImage] = useState(null);

  const handleChangeImage = (e) => {
    const image = e.target.files[0];

    if (image === '' || image === undefined) {
      // eslint-disable-next-line no-undef
      alert(`not an image, the file is a ${typeof image}`);
      return;
    } else {
      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData.append('photo', image);
      formData.append('_method', 'PUT');

      axios.post('/api/profile/image', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(() => {
      }).catch();
    }
    setImage(image);
  };

  useEffect(() => {
    if (values.country_id !== '') {
      axios.get(`/api/countries/${parseInt(values.country_id)}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(res => {
        setCities(res.data.data.cities.data);
      });
    }
  }, [values.country_id]);

  return (
    <Form className="w-full">
      {role !== 'user' && <Fragment>
        <div className='h-32 w-32 mx-auto border-2 border-gray-300 rounded-md cursor-pointer p-1'>
          {image
            ? <img className='object-cover w-full h-full border-3 cursor-pointer' src={URL.createObjectURL(image)} />
            : <label className='p-4 w-full h-full flex flex-col justify-center items-center' htmlFor="post">
              <PhotoIcon/> <p className='text-sm text-gray-500 text-center my-3'>Personal photo</p> </label>}
        </div>
        <div className="w-full p-3">
          <input
            type="file"
            accept="image/gif, image/jpeg, image/png"
            name="image"
            id="post"
            onChange={handleChangeImage}
            style={{ display: 'none' }}
          />
        </div>
        {profileUrl && <h6 className='text-sm font-semibold text-blue-600 hover:underline cursor-pointer' >{profileUrl}</h6>}
      </Fragment>}
      <FormControl control='input' type='text' label='Phone number' name='phone_number' Icon={<PhoneIcon/>}/>
      <FormControl control='select' label='Country' name='country_id' options={countries} Icon={<LocationIcon/>}/>
      <FormControl control='select' label='City' name='city_id' options={cities} />
      <FormControl control='input' type='text' label='Website' name='website' Icon={<WebsiteIcon/>}/>

      <div className="w-full flex flex-row justify-between items-center py-3">
        <Button className='text-primary border-2  mx-auto hover:border-primary hover:font-bold' type='submit'> <ArrowNext/> Next</Button>
      </div>
    </Form>
  );
};

export default ContactInfoForm;
