import { createSlice } from '@reduxjs/toolkit';

const subCategory = createSlice({
  name: 'subCategory',
  initialState: {
    subCategories: {data: [], count: 0},
    fetched: undefined,
    updated: {},
    created: {}
  },
  reducers: {
    createSubCategory (state, action) {},
    createSubCategorySuccess (state, action) {
      state.created = action.payload;
    },
    editSubCategory (state, action) {},
    editSubCategorySuccess (state, action) {
      state.updated = action.payload;
    },
    getSubCategory (state, action) {},
    getSubCategorySuccess (state, action) {
      state.fetched = action.payload.data;
    },
    listSubCategory (state, action) {},
    listSubCategorySuccess (state, action) {
      state.subCategories.data = action.payload.data;
      state.subCategories.count = action.payload.count;
    },
    deleteSubCategory (state, action) {},
    deleteSubCategorySuccess (state, action) {}
  }
});

// Action creators are generated for each case reducer function
export const {
  createSubCategory,
  createSubCategorySuccess,
  editSubCategory,
  editSubCategorySuccess,
  getSubCategory,
  getSubCategorySuccess,
  listSubCategory,
  listSubCategorySuccess,
  deleteSubCategory,
  deleteSubCategorySuccess
} = subCategory.actions;

export default subCategory.reducer;
