import React, {useState, useEffect} from 'react';
import { getToken } from '../../utils/auth';
import FollowIcon from '../UI/Icons/FollowIcon';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const TimelineFollow = () => {
  const [list, setList] = useState();
  const [fetch, setFetch] = useState(false);
  const token = getToken();
  const history = useHistory();
  useEffect(() => {
    axios.get(`/api/user/follow/list`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setList(res.data.data);
    });
  }, [fetch]);

  const handleFollow = (id) => {
    axios.post(`/api/user/follow`, {user_id: id}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      setFetch(state => !state);
    });
  };

  return (
    <div className='bg-white rounded-md w-64 sticky top-0 p-6 hidden md:block'>
      <ul className='flex flex-col justify-start items-start gap-y-4 w-full'>
        <h4 className='text-primary mt-6'>Follow more</h4>
        {list && list.map(follow =>
          <li key={follow.id} className='w-full border border-gray-300 rounded-md p-4 flex flex-col justify-start items-start'>
            <div className='flex justify-between items-center w-full' >
              <img onClick={() => history.push(`/profile/${follow.username}`) } src={follow.photo} alt="company" className='w-10 h-10 p-1 border border-gray-300 rounded-lg' />
              <div className=' cursor-pointer' onClick={() => handleFollow(follow.id)} ><FollowIcon/></div>
            </div>
            <h5 onClick={() => history.push(`/profile/${follow.username}`) } className='text-primary text-base mt-3 mb-2 hover:underline cursor-pointer'> {follow.role !== 'user' ? follow.full_name : follow.company_details ? follow.company_details.name : follow.full_name} </h5>
            {follow.company_details && follow.country && <p className='text-gray-500 text-xs'> {follow.role === 'user' ? follow.company_details.category && follow.company_details.category.label : ''} -- {follow.country.label}</p>}
          </li>)}
      </ul>

    </div>
  );
};

export default TimelineFollow;
