/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import CourseForm from '../Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import { getToken } from '../../../utils/auth';
import axios from 'axios';
import TextError from '../../UI/TextError';

const CourseEdit = ({fetchCourse, onClose, courseId, setCourseId}) => {
  const [course, setCourse] = useState(false);
  const [currencies, setCurrencies] = useState(false);
  const [categories, setCategories] = useState(false);

  const [error, setError] = useState('');
  const token = getToken();

  const INITIALVALUES = course ? {
    course_id: course.course_id,
    type: course.type,
    hours: course.hours,
    price: course.price,
    user_id: course.user_id,
    details: course.details || '',
    capacity: course.capacity || '',
    fixed_amount: course.fixed_amount,
    hourly_rate: course.hourly_rate,
    on_site: course.on_site,
    online: course.online,
    category_id: course.courses.category_id,
    sub_category_id: course.courses.sub_category_id,
    currency_id: course.currency.id
  } : null;

  useEffect(() => {
    axios.get(`/api/instructor-courses/${courseId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCourse(res.data.data);
    }).catch(error => setError(error.messaage));
    axios.get(`/api/categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCategories(res.data.data);
    });
    axios.get(`/api/currencies`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCurrencies(res.data.data);
    });
    return () => {
      setCourseId(null);
      fetchCourse(null);
    };
  }, []);

  const onDelete = () => {
    axios.delete(`/api/instructor-courses/${courseId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
      onClose();
      fetchCourse(courseId);
    }).catch(error => setError(error.message));
  };

  const onSubmit = values => {
    delete values.category_id;
    delete values.sub_category_id;
    axios.put(`/api/instructor-courses/${courseId}`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
      onClose();
      fetchCourse(courseId);
    }).catch(error => setError(error.message));
  };

  return (
    <Fragment>
      {error && <TextError>{error}</TextError>}
      {course && course.currency && categories && <Formik
        initialValues = {INITIALVALUES}
        validationSchema = {VALIDATIONSHEMA}
        onSubmit={onSubmit}
        onReset={onDelete}>
        {formik => (
          <CourseForm currencies={currencies} categories={categories} edit values={formik.values} />
        )}
      </Formik>}
    </Fragment>
  );
};

export default CourseEdit;
