/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import ShowIcon from '../UI/Icons/ShowIcon';
import axios from 'axios';
import { getToken } from '../../utils/auth';

const TimelineView = ({ fieldsOfInterest, selected, setSelected, user }) => {
  const [categories, setCategories] = useState(false);
  const token = getToken();

  useEffect(() => {
    axios.get(`/api/categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCategories(res.data.data);
    });
  }, []);
  return (
    <div className='hidden md:flex bg-transparent flex-col justify-start items-start gap-y-4 divide-y capitalize w-64 md:w-56 sticky top-0'>
      <div className='flex flex-col justify-start items-start gap-y-4 w-full px-5' >
        <h4 className='text-primary'>my timeline</h4>
        <div className='flex justify-between items-center text-gray-500 text-sm w-full' >followers <span className='text-primary' >{user.following}</span></div>
        <div className='flex justify-between items-center text-gray-500 text-sm w-full' >fields of interest <span className='text-primary' >{fieldsOfInterest.length}</span></div>
      </div>

      <ul className='flex flex-col justify-start items-start gap-y-1 w-full'>
        <h4 className='text-primary px-5 mt-6'>Timeline View</h4>
        <li onClick={() => setSelected('')} className={`w-full text-gray-500 text-sm rounded-md flex cursor-pointer justify-between items-center py-2 px-5 ${selected === '' && 'text-primary bg-blue-200'}`}>
                      my timeline
          {selected === '' && <ShowIcon />}
        </li>
        {categories && categories.map(field => <li key={field.id} onClick={() => setSelected(field.id)} className={`w-full text-gray-500 text-sm rounded-md flex cursor-pointer justify-between items-center py-2 px-5 ${selected === field.id && 'text-primary bg-blue-200'}`}>
          {field.label}
          {selected === field.id && <ShowIcon/>}
        </li>)}
      </ul>

    </div>
  );
};

export default TimelineView;
