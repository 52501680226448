import React, {useState, useEffect, useRef} from 'react';
import Card from '../UI/Card';
import IconButton from '../UI/IconButton';
import { useAuth } from '../../context/auth';
import axios from 'axios';
import { getToken } from '../../utils/auth';
import OptionIcon from '../UI/Icons/OptionIcon';

// eslint-disable-next-line react/prop-types
const Comment = ({image, content, userName, userId, fetchPost, id}) => {
  const {currentUser} = useAuth();
  const [showEditList, setShowEditList] = useState(false);
  const token = getToken();

  const toggleEditList = () => {
    setShowEditList(prevState => !prevState);
  };

  let menuRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', (event) => {
      if (!menuRef.current.contains(event.target)) {
        setShowEditList(false);
      }
    });
  }, []);

  const DeleteComment = (id) => {
    axios.delete(`/api/comments/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(setTimeout(() => fetchPost(), 300)).catch(error => console.log(error));
    toggleEditList();
  };

  return (
    <Card className='flex-row w-auto mt-4 shadow-none'>
      <div className="flex flex-col justify-start items-start w-auto bg-primary_bg rounded-lg p-3">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row justify-start items-center" >
            <img className='h-8 w-8 rounded-lg mr-4 cursor-pointer' src={image || 'https://www.iotric.com/assets/images/dummy.png'} />
            <h5 className='text-primary text-base' >{userName}</h5>
          </div>
          {currentUser.id === userId &&
          <IconButton onClick={toggleEditList} className='ml-2 p-2 hover:bg-gray-300'>
            <OptionIcon/>
          </IconButton>}
          <div className='mb-3 text-sm font-bold relative flex flex-row justify-between items-center'>
            {showEditList && <div ref={menuRef} className="absolute top-0 right-0 rounded-lg p-3 bg-white border-1 mt-8 shadow-lg">
              <ul>
                {/* <li onClick={handleClickEdit} className='text-sm font-semibold cursor-pointer p-2 hover:bg-gray-200 rounded-lg'>Edit</li> */}
                <li onClick={() => DeleteComment(id)} className='text-sm font-semibold cursor-pointer p-2 hover:bg-gray-200 rounded-lg'>Delete</li>
              </ul>
            </div>}
          </div>
        </div>
        <p className='px-5 py-3 text-gray-600 text-base' >{content}</p>
      </div>
    </Card>
  );
};

export default Comment;
