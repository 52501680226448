import React, { useState } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { useAuth } from '../context/auth';
import { setIntendedUrl } from '../utils/auth';
import Search from './Search';
import SearchIcon from '../components/UI/Icons/SearchIcon';
import UserIcon from '../components/UI/Icons/UserIcon';
import Notifications from './Notifications';

function AuthNav () {
  let {setCurrentUser, setToken, currentUser} = useAuth();
  let history = useHistory();
  let [hideMobileNav, setHideMobileNav] = useState(true);

  const toggleMobileNav = () => setHideMobileNav(prevState => !prevState);
  const closeMobileNav = () => setHideMobileNav(true);

  const handleLogout = () => {
    setCurrentUser(null);
    setToken(null);
    history.push('/');
    setIntendedUrl(null);
  };

  return (
    <div className="auth-nav flex flex-row py-6 border-b border-grey-light">
      <div className="container flex-col lg:flex-row px-2 mx-auto flex items-center justify-between">
        <div className="left flex justify-between w-full lg:w-auto flex-1 lg:flex-initial">
          <ul className="list-reset flex items-center">
            <li>
              <NavLink
                to="/home"
                activeClassName="font-bold"
                className="text-gray-800 no-underline text-indigo">
                <img src="/images/logo.svg" alt="" />
              </NavLink>
            </li>
          </ul>

          <div
            onClick={toggleMobileNav}
            id="sidebar-open"
            className='z-50 flex px-6 items-center lg:hidden text-gray-700'>

            <span className={`svg-full ${!hideMobileNav ? 'mobile-nav-show' : ''}`}>
                MENU &nbsp;
              <svg className="fill-current" role="button" xmlns="http://www.w3.org/2000/svg" width="35" height="12" viewBox="0 0 35 12">
                <rect width="35" height="2"></rect>
                <rect y="5" width="24" height="2"></rect>
                <rect y="10" width="14" height="2"></rect>
              </svg>
            </span>
          </div>
        </div>

        <div
          className={`right lg:flex pt-8 lg:pt-0 right fixed lg:relative bg-white w-full lg:w-auto h-screen lg:h-auto relative ${hideMobileNav ? 'mobile-hidden' : ''}`}>
          <ul className="mt-8 py-8 lg:py-0 lg:mt-0 list-reset flex items-center flex-col lg:flex-row">
            {currentUser.role === 'admin' && <li
              onClick={closeMobileNav}
              className="px-4 py-3 lg:py-0">
              <NavLink
                to='/admin'
                className="capitalize text-2xl font-bold lg:text-sm lg:font-light text-gray-700 underline lg:no-underline">
                  Dashboard
              </NavLink>
            </li>}
            <li
              onClick={closeMobileNav}
              className="px-4 py-3 lg:py-0">
              <NavLink
                to='/home'
                className="capitalize text-2xl font-bold lg:text-lg lg:font-normal text-primary border-secondary  py-3 px-2 "
                activeClassName='rounded-md border-2 border-secondary text-secondary font-semibold'>
                  timeline
              </NavLink>
            </li>
            <li
              onClick={closeMobileNav}
              className="px-4 py-3 lg:py-0">
              {
                currentUser.role === 'user' ? <NavLink
                  to='/job'
                  activeClassName='rounded-md border-2 border-secondary text-secondary font-semibold'
                  className="capitalize text-2xl font-bold lg:text-lg lg:font-normal text-primary border-secondary py-3 px-2">
                    Request Training
                </NavLink> : <NavLink
                  to='/jobs'
                  activeClassName='rounded-md border-2 border-secondary text-secondary font-semibold'
                  className="capitalize text-2xl font-bold lg:text-lg lg:font-normal text-primary border-secondary py-3 px-2">
                  Courses Requests
                </NavLink>
              }
            </li>
            <li
              className="capitalize text-2xl font-bold lg:text-lg lg:font-semibold text-gray-700 underline lg:no-underline">
              <NavLink
                to='/help-center'
                activeClassName='rounded-md border-2 border-secondary text-secondary font-semibold'
                className="capitalize text-2xl font-bold lg:text-lg lg:font-normal text-primary border-secondary  py-3 px-2 ">
                  Help Center
              </NavLink>
            </li>

            <li
              className={`px-4 py-3 lg:py-0 ${hideMobileNav ? 'hidden' : ''}`}>
              <NavLink
                to='/home'
                className="capitalize text-2xl font-bold lg:text-sm lg:font-light text-gray-700 underline lg:no-underline">
                <SearchIcon />
              </NavLink>
            </li>
            <li
              className={`px-4 py-3 lg:py-0 ${hideMobileNav ? 'hidden' : ''}`}>
              <div
                className="capitalize text-2xl font-bold lg:text-sm lg:font-light text-gray-700 underline lg:no-underline">
                <Notifications/>
              </div>
            </li>
            <li
              className={`px-4 py-3 lg:py-0 ${hideMobileNav ? 'hidden' : ''}`}>
              <NavLink
                to={`/profile/${currentUser.username}`}
                className="font-bold lg:text-sm lg:font-light capitalize text-sm text-gray-700 underline lg:no-underline">
                <UserIcon/>
              </NavLink>
            </li>
            <li
              onClick={handleLogout}
              className={`px-4 py-3 lg:py-0 ${hideMobileNav ? 'hidden' : ''}`}>
              <Link
                to="/logout"
                className="capitalize text-2xl font-bold lg:text-sm lg:font-light text-gray-700 underline lg:no-underline">
                  Logout</Link>
            </li>
          </ul>
        </div>

        <div
          className='lg:flex pt-8 lg:pt-0 w-full lg:w-auto h-screen lg:h-auto'>
          <ul className="mt-8 py-8 lg:py-0 lg:mt-0 list-reset flex items-center flex-row">
            <li
              className="px-4 py-3 lg:py-0">
              <div
                className="capitalize text-2xl font-bold lg:text-sm lg:font-light text-gray-700 underline lg:no-underline">
                <Search/>
              </div>
            </li>
            <li
              className="px-4 py-3 lg:py-0">
              <div
                className="capitalize text-2xl font-bold lg:text-sm lg:font-light text-gray-700 underline lg:no-underline">
                <Notifications/>
              </div>
            </li>
            <li
              className="px-4 py-3 lg:py-0">
              <NavLink
                to={`/profile/${currentUser.username}`}
                className="font-bold lg:text-sm lg:font-light capitalize text-sm text-gray-700 underline lg:no-underline">
                <UserIcon/>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AuthNav;
