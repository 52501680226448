/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import IconButton from '../../UI/IconButton';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import { useAuth } from '../../../context/auth';
import CommentIcon from '../../UI/Icons/CommentIcon';

const CreateComment = ({commentId, postId, edit, fetchPost, showComment}) => {
  const token = getToken();
  const {currentUser} = useAuth();
  const [content, setContent] = useState('');

  const handleChange = (e) => {
    setContent(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (edit) {
      axios.put(`/api/comments/${commentId}`, {content, user_id: currentUser.id, post_id: postId}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(setTimeout(() => fetchPost(), 500));
    } else {
      axios.post(`/api/comments`, {content, user_id: currentUser.id, post_id: postId}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(() => {
        showComment();
        setContent('');
        setTimeout(() => (fetchPost()), 500);
      });
      showComment();
      setContent('');
      setTimeout(() => (fetchPost()), 500);
    }
    ;
  };
  return (
    <form onSubmit={handleSubmit} className='flex flex-row justify-between items-center p-2 border border-gray-200 rounded-md h-auto mt-3 hover:border-secondary'>
      <input className='bg-transparent p-1 flex-grow h-6 focus:outline-none cursor-pointer' value={content}
        type='text'
        onChange={handleChange}
        placeholder="Write a comment"
        autoFocus={true}/>
      <IconButton type='submit'>
        <CommentIcon width='15' height='15' />
      </IconButton>
    </form>
  );
};

export default CreateComment;
