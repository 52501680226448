import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import ArrowNext from '../components/UI/Icons/ArrowNext';

function Welcome () {
  return (
    <div className="grid md:grid-cols-2 sm:grid-cols-1 h-screen">
      <div className="flex flex-1 flex-col items-start justify-between h-full pt-20 pl-12 md:pl-32 pr-10 md:pr-20 pb-20">
        <div >
          <img src="/images/logo.png" alt="peconto" className='w-20 mb-16' />
          <h1 className="text-3xl md:text-5xl text-primary font-body font-semibold" >Welcome to Peconto...</h1>
          <p className=' text-lg md:text-xl text-primary font-normal w-4/5'>Peconto connects buisness, trainee and instructors for exponential success..</p>
        </div>

        <div className='flex flex-col items-start justify-start w-full mt-6 md:mt-0'>
          <Link to='/register/instructor' className='flex justify-between items-center bg-primary text-white p-3 text-sm w-full md:w-2/3 rounded-md'> <ArrowNext fill="#fff"/> OFFER YOUR SERVICES</Link>
          <Link to='/register/user' className='flex justify-between items-center text-primary bg-white p-3 text-sm w-full md:w-2/3 rounded-md border border-primary hover:border-4 my-5'><ArrowNext fill='#0F4975'/> LOOK FOR INSTRUCTORS</Link>
        </div>
        <div className='w-full' >
          <Footer/>
        </div>
      </div>
      <div className="hidden md:flex flex-col items-center justify-center h-full bg-primary_bg p-4 ">
        <img src="/images/illustration_full.png" />
      </div>
    </div>
  );
}

export default Welcome;
