import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '../components/UI/Button';
import ArrowNext from '../components/UI/Icons/ArrowNext';
import { getToken } from '../utils/auth';
import Check from '../components/UI/Check';
import { useAuth } from '../context/auth';

const FieldsOfInterest = () => {
  const history = useHistory();
  const {currentUser} = useAuth();
  const [fieldOfInterests, setFieldOfInterests] = useState(null);
  const [selected, setSelected] = useState([]);

  const token = getToken();

  useEffect(() => {
    axios.get(`/api/categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setFieldOfInterests(res.data.data);
    });
  }, []);

  const isSelected = (field) => {
    return selected.find(selectedItem => selectedItem === field);
  };

  const toggleSelect = (field) => {
    if (isSelected(field)) {
      if (selected.indexOf(field) > -1) {
        setSelected(selected => {
          selected.splice(selected.indexOf(field), 1);
          return selected;
        });
      }
    } else {
      setSelected(selected => [...selected, field]);
    }
  };

  const handleClick = () => {
    axios.put(`/api/profile/${currentUser.id}`, { fields_of_interest: selected }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      history.push('/follow');
    }).catch(err => console.log(err));
  };

  return (
    <div className="flex justify-center w-full h-screen bg-primary_bg">
      <div className="relative bg-white w-800px  px-8 h-full flex flex-col justify-start items-center">
        <div className='absolute top-0 right-0 m-6'>
          <Link to='/home' className='text-primary text-sm flex justify-start items-center p-3 hover:text-link_primary'>
                      Skip This Step
          </Link>
        </div>

        <div className='flex flex-col justify-center items-center mt-16 mb-8 md:mb-16 w-5/7'>
          <h1 className='text-2xl md:text-4xl text-primary font-body font-semibold capitalize' >Fields of Interest</h1>
          <p className='text-sm md:text-lg text-primary font-normal opacity-75 text-center' >Select what you&apos;d like to improve and have a training in.</p>
        </div>

        <div className='flex justify-center items-start gap-y-5 gap-x-4 flex-wrap'>
          {fieldOfInterests && fieldOfInterests.map(field => (
            <Check field={field} key={field.id} toggleSelect={toggleSelect} />
          ))}
        </div>

        <Button onClick={handleClick} className='text-primary border-2  mx-auto my-16 hover:border-primary hover:font-bold'> <ArrowNext/> Next</Button>

      </div>
    </div>
  );
};

export default FieldsOfInterest;
