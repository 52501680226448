import React from 'react';
import InstructorJobs from '../components/Jobs/instructor';

const Jobs = () => {
  return (
    <div className='w-full bg-primary_bg'>
      <div className='container p-2 mx-auto flex flex-row items-start justify-start w-full'>
        <div className="w-full mx-3">
          <InstructorJobs/>
        </div>
      </div>
    </div>
  );
};

export default Jobs;
