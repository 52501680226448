import { createSlice } from '@reduxjs/toolkit';

const category = createSlice({
  name: 'category',
  initialState: {
    categories: {data: [], count: 0},
    fetched: undefined,
    updated: {},
    created: {}
  },
  reducers: {
    createCategory (state, action) {},
    createCategorySuccess (state, action) {
      state.created = action.payload;
    },
    editCategory (state, action) {},
    editCategorySuccess (state, action) {
      state.updated = action.payload;
    },
    getCategory (state, action) {},
    getCategorySuccess (state, action) {
      state.fetched = action.payload.data;
    },
    listCategory (state, action) {},
    listCategorySuccess (state, action) {
      state.categories.data = action.payload.data;
      state.categories.count = action.payload.count;
    },
    deleteCategory (state, action) {},
    deleteCategorySuccess (state, action) {}
  }
});

// Action creators are generated for each case reducer function
export const {
  createCategory,
  createCategorySuccess,
  editCategory,
  editCategorySuccess,
  getCategory,
  getCategorySuccess,
  listCategory,
  listCategorySuccess,
  deleteCategory,
  deleteCategorySuccess
} = category.actions;

export default category.reducer;
