/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import { Formik } from 'formik';
import CertificationForm from '../Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import { useAuth } from '../../../context/auth';
import { getToken } from '../../../utils/auth';
import axios from 'axios';
import TextError from '../../UI/TextError';

const CertificationCreate = ({fetchCert, onClose}) => {
  const token = getToken();
  const {currentUser} = useAuth();
  const [error, setError] = useState('');

  const INITIALVALUES = {
    name: '',
    issuing_organization: '',
    credential_not_expire: false,
    issue_date_month: '',
    issue_date_year: '',
    expiration_date_month: '',
    expiration_date_year: '',
    credential_id: '',
    credential_url: '',
    user_id: currentUser.id
  };

  const onSubmit = values => {
    axios.post(`/api/certificates`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(() => {
      onClose();
      fetchCert && fetchCert(values.name);
    }).catch(error => setError(error.messaage));
  };

  return (
    <Fragment>
      {error && <TextError>{error}</TextError>}
      <Formik
        initialValues = {INITIALVALUES}
        validationSchema = {VALIDATIONSHEMA}
        onSubmit={onSubmit}>
        {formik => (
          <CertificationForm formik={formik} />
        )}
      </Formik>
    </Fragment>
  );
};

export default CertificationCreate;
