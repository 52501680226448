/* eslint-disable react/prop-types */
import React from 'react';
import Certifications from '../components/Certifications';
import Education from '../components/Education';
import Experience from '../components/Experience';

const InfoTab = ({user}) => {
  return (
    <div className='px-32' >
      <Experience experiences={user.experiences.data} userId={user.id} />
      <Education educations={user.educations.data} userId={user.id}/>
      <Certifications certificates={user.certificates.data} userId={user.id}/>
    </div>
  );
};

export default InfoTab;
