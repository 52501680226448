/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { setIntendedUrl } from '../utils/auth';
import PropTypes from 'prop-types';
import { useAuth } from '../context/auth';
import AuthNav from '../components/auth-nav';
import useDocumentTitle from '../components/document-title';

function AuthRoute ({ component: Component, title, noNavbar, ...rest }) {
  useDocumentTitle(title);
  let { authenticated, currentUser, isVerified, setIsVerified } = useAuth();

  useEffect(() => {
    currentUser && currentUser.email_verified_at ? setIsVerified(true) : setIsVerified(false);
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        if (!authenticated) {
          setIntendedUrl(props.location.pathname);
        }

        return authenticated
          ? isVerified && !isVerified
            ? < Redirect to = {{ pathname: '/verify' }} />
            : (
              <div className="flex flex-col min-h-screen">
                {!noNavbar ? <AuthNav /> : null}
                <div className="flex flex-1">
                  <Component {...props} />
                </div>
              </div>
            )
          : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
      }
      }
    />
  );
};

AuthRoute.displayName = 'Auth Route';

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
  rest: PropTypes.object,
  location: PropTypes.object,
  title: PropTypes.string
};

export default AuthRoute;
