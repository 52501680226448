/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import EducationForm from '../Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import TextError from '../../UI/TextError';

const EducationEdit = ({eduId, onClose, fetchEdu, setEduId}) => {
  const [education, setEducation] = useState(false);
  const [error, setError] = useState('');
  const token = getToken();

  const INITIALVALUES = {
    school: education.school,
    degree: education.degree,
    field_study: education.field_study,
    start_month: education.start_month,
    start_year: education.start_year,
    end_month: education.end_month,
    end_year: education.end_year,
    grade: education.grade,
    activities_societies: education.activities_societies,
    description: education.description,
    user_id: education.user_id
  };

  useEffect(() => {
    axios.get(`/api/educations/${eduId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setEducation(res.data.data);
    }).catch(error => setError(error.messaage));
    return () => {
      setEduId(null);
      fetchEdu(null);
    };
  }, []);

  const onDelete = () => {
    axios.delete(`/api/educations/${eduId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
      onClose();
      fetchEdu(eduId);
    }).catch(error => setError(error.message));
  };

  const onSubmit = values => {
    axios.put(`/api/educations/${eduId}`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
      onClose();
      fetchEdu(eduId);
    }).catch(error => setError(error.message));
  };

  return (
    <Fragment>
      {error && <TextError>{error}</TextError>}
      {education && <Formik
        initialValues = {INITIALVALUES}
        validationSchema = {VALIDATIONSHEMA}
        onSubmit={onSubmit}
        onReset={onDelete}>
        {formik => (
          <EducationForm edit />
        )}
      </Formik>}
    </Fragment>
  );
};

export default EducationEdit;
