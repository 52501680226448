import React from 'react';

const CompanyIcon = () => {
  return (
    <svg id="company" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="container" width="24" height="24" fill="#0f4975" opacity="0"/>
      <path id="business" d="M20.438,22.5H19.5V1.125A1.125,1.125,0,0,0,18.375,0H2.625A1.125,1.125,0,0,0,1.5,1.125V22.5H.563A.563.563,0,0,0,0,23.063V24H21v-.937A.563.563,0,0,0,20.438,22.5ZM6,3.563A.563.563,0,0,1,6.563,3H8.438A.563.563,0,0,1,9,3.563V5.438A.563.563,0,0,1,8.438,6H6.563A.563.563,0,0,1,6,5.438Zm0,4.5A.563.563,0,0,1,6.563,7.5H8.438A.563.563,0,0,1,9,8.063V9.938a.563.563,0,0,1-.562.563H6.563A.563.563,0,0,1,6,9.938ZM8.438,15H6.563A.563.563,0,0,1,6,14.438V12.563A.563.563,0,0,1,6.563,12H8.438A.563.563,0,0,1,9,12.563v1.875A.563.563,0,0,1,8.438,15ZM12,22.5H9V18.563A.563.563,0,0,1,9.563,18h1.875a.563.563,0,0,1,.563.563Zm3-8.062a.563.563,0,0,1-.562.563H12.563A.563.563,0,0,1,12,14.438V12.563A.563.563,0,0,1,12.563,12h1.875a.563.563,0,0,1,.563.563Zm0-4.5a.563.563,0,0,1-.562.563H12.563A.563.563,0,0,1,12,9.938V8.063a.563.563,0,0,1,.563-.562h1.875A.563.563,0,0,1,15,8.063Zm0-4.5A.563.563,0,0,1,14.438,6H12.563A.563.563,0,0,1,12,5.438V3.563A.563.563,0,0,1,12.563,3h1.875A.563.563,0,0,1,15,3.563Z" transform="translate(2)" fill="#0f4975"/>
    </svg>
  );
};

export default CompanyIcon;
