import { combineReducers } from 'redux';
import user from './userSlice';
import category from './categorySlice';
import subCategory from './subCategorySlice';
import privacy from './privacySlice';
import terms from './termsSlice';

const rootReducer = combineReducers({
  user,
  category,
  subCategory,
  privacy,
  terms
});

export default rootReducer;
