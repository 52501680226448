/* eslint-disable react/prop-types */
import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  IconButton
} from '@mui/material';
import {MdOutlineModeEdit, MdDeleteOutline} from 'react-icons/md';

const BasicTable = ({title, rows, cells, editAction, deleteAction}) => {
  return (
    <TableContainer component={Paper}>
      <Toolbar>
        <h6>{title}</h6>
      </Toolbar>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className='bg-gray-300'>
          <TableRow>
            {cells.map(cell =>
              <TableCell key={cell.Header} align={cell.align} >{cell.Header}</TableCell>
            )}
            <TableCell align='left' >Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {cells.map(cell => (
                <TableCell key={cell.accessor} align={cell.align}>{cell.accessor ? row[cell.accessor] : cell.elem}</TableCell>
              ))}
              <TableCell align='left'>
                <IconButton variant='oulined' color='info' onClick={() => editAction(row.id)}>
                  <MdOutlineModeEdit/>
                </IconButton>
                <IconButton variant='oulined' color='error' onClick={() => deleteAction(row.id)}>
                  <MdDeleteOutline/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
