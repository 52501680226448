import {call, put, takeEvery} from 'redux-saga/effects';
import Client from '../../api/client';
import {
  createSubCategory,
  createSubCategorySuccess,
  deleteSubCategory,
  deleteSubCategorySuccess,
  editSubCategory,
  editSubCategorySuccess,
  getSubCategory,
  getSubCategorySuccess,
  listSubCategory,
  listSubCategorySuccess
} from '../reducer/subCategorySlice';

function * list (action) {
  let subCategoryData;
  try {
    subCategoryData = yield call(() => Client('/api/sub-categories'));
  } catch (error) {}
  yield put(listSubCategorySuccess(subCategoryData));
}

function * create (action) {
  const data = action.payload.values;
  let subCategoryData;
  try {
    subCategoryData = yield call(() => Client('/api/sub-categories', {method: 'POST', body: data}));
  } catch (error) {
    if (action.payload.failedCallback) {
      action.payload.failedCallback();
    }
    return null;
  }
  if (action.payload.successCallback) {
    yield put(createSubCategorySuccess(subCategoryData.data));
    action.payload.successCallback();
  }
}

function * edit (action) {
  const data = action.payload.values;
  const {id} = action.payload;
  let subCategoryData;
  try {
    subCategoryData = yield call(() => Client(`/api/sub-categories/${id}`, {method: 'PUT', body: data}));
  } catch (error) {
    if (action.payload.failedCallback) {
      action.payload.failedCallback();
    }
    return null;
  }
  yield put(editSubCategorySuccess(subCategoryData.data));
  if (action.payload.successCallback) {
    action.payload.successCallback();
  }
}

function * remove (action) {
  const {id} = action.payload;
  let subCategoryData;
  try {
    subCategoryData = yield call(() => Client(`/api/sub-categories/${id}`, {method: 'DELETE'}));
  } catch (error) {
    if (action.payload.failedCallback) {
      action.payload.failedCallback();
    }
    return null;
  }
  yield put(deleteSubCategorySuccess(subCategoryData.data));
  if (action.payload.successCallback) {
    action.payload.successCallback();
  }
}

function * get (action) {
  const {id} = action.payload;
  let subCategoryData;
  try {
    subCategoryData = yield call(() => Client(`/api/sub-categories/${id}`));
  } catch (error) {}
  yield put(getSubCategorySuccess(subCategoryData));
}

function * SubCategorySaga () {
  yield takeEvery(createSubCategory.type, create);
  yield takeEvery(listSubCategory.type, list);
  yield takeEvery(editSubCategory.type, edit);
  yield takeEvery(deleteSubCategory.type, remove);
  yield takeEvery(getSubCategory.type, get);
};

export default SubCategorySaga;
