import {call, put, takeEvery} from 'redux-saga/effects';
import Client from '../../api/client';
import {
  createCategory,
  createCategorySuccess,
  deleteCategory,
  deleteCategorySuccess,
  editCategory,
  editCategorySuccess,
  getCategory,
  getCategorySuccess,
  listCategory,
  listCategorySuccess
} from '../reducer/categorySlice';

function * list (action) {
  let categoryData;
  try {
    categoryData = yield call(() => Client('/api/categories'));
  } catch (error) {}
  yield put(listCategorySuccess(categoryData));
}

function * create (action) {
  const data = action.payload.values;
  let categoryData;
  try {
    categoryData = yield call(() => Client('/api/categories', {method: 'POST', body: data}));
  } catch (error) {
    if (action.payload.failedCallback) {
      action.payload.failedCallback();
    }
    return null;
  }
  if (action.payload.successCallback) {
    yield put(createCategorySuccess(categoryData.data));
    action.payload.successCallback();
  }
}

function * edit (action) {
  const data = action.payload.values;
  const {id} = action.payload;
  let categoryData;
  try {
    categoryData = yield call(() => Client(`/api/categories/${id}`, {method: 'PUT', body: data}));
  } catch (error) {
    if (action.payload.failedCallback) {
      action.payload.failedCallback();
    }
    return null;
  }
  yield put(editCategorySuccess(categoryData.data));
  if (action.payload.successCallback) {
    action.payload.successCallback();
  }
}

function * remove (action) {
  const {id} = action.payload;
  let categoryData;
  try {
    categoryData = yield call(() => Client(`/api/categories/${id}`, {method: 'DELETE'}));
  } catch (error) {
    if (action.payload.failedCallback) {
      action.payload.failedCallback();
    }
    return null;
  }
  yield put(deleteCategorySuccess(categoryData.data));
  if (action.payload.successCallback) {
    action.payload.successCallback();
  }
}

function * get (action) {
  const {id} = action.payload;
  let categoryData;
  try {
    categoryData = yield call(() => Client(`/api/categories/${id}`));
  } catch (error) {}
  yield put(getCategorySuccess(categoryData));
}

function * CategorySaga () {
  yield takeEvery(createCategory.type, create);
  yield takeEvery(listCategory.type, list);
  yield takeEvery(editCategory.type, edit);
  yield takeEvery(deleteCategory.type, remove);
  yield takeEvery(getCategory.type, get);
};

export default CategorySaga;
