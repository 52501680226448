/* eslint-disable react/prop-types */
import React from 'react';

const IconButton = ({className, children, ...restProps}) => {
  return (
    <button
      className={'font-bold uppercase text-sm outline-none focus:outline-none ease-linear transition-all duration-150 rounded-full ' + className} {...restProps}>
      {children}
    </button>
  );
};

export default IconButton;
