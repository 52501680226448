/* eslint-disable react/prop-types */
import React from 'react';
import {Form} from 'formik';
import FormControl from '../../UI/FormControl';
import Button from '../../UI/Button';
import { months, years } from '../../utils/constants';
import CompanyIcon from '../../UI/Icons/CompanyIcon';
import CalendarIcon from '../../UI/Icons/CalendarIcon';
import CertificationIcon from '../../UI/Icons/CertificateIcon';
import WebsiteIcon from '../../UI/Icons/WebsiteIcon';
import ArrowNext from '../../UI/Icons/ArrowNext';

const CertificationForm = ({edit, formik}) => {
  return (
    <Form className='w-full'>
      <FormControl control='input' type='text' label='Certificate title...' name='name' Icon={<CertificationIcon/>} />
      <FormControl control='input' type='text' label='Provider..' name='issuing_organization' Icon={<CompanyIcon/>} />
      <div className='grid gap-4 grid-cols-2'>
        <FormControl control='select' label='Start Month' name='issue_date_month' options={months} Icon={<CalendarIcon/>} />
        <FormControl control='select' label='Start Year' name='issue_date_year' options={years} Icon={<CalendarIcon/>} />
      </div>
      <div className='grid gap-4 grid-cols-2'>
        <FormControl control='select' label='Expiration Month' name='expiration_date_month' options={months} Icon={<CalendarIcon/>} />
        <FormControl control='select' label='Expiration Year' name='expiration_date_year' options={years} Icon={<CalendarIcon/>}/>
      </div>
      <FormControl control='input' type='text' label='Website...' name='credential_url' Icon={<WebsiteIcon/>} />
      {edit ? <div className='border-t sticky w-full flex flex-row justify-between items-center bottom-0 bg-white py-3'>
        <Button className='text-red-600 bg-transparent rounded-full hover:bg-gray-300' type='reset' >Delete</Button>
        <Button className='text-primary border-2  mx-auto hover:border-primary hover:font-bold' type='submit'>Update</Button>
      </div> : <div className='border-t sticky w-full flex flex-row-reverse justify-between items-center bottom-0 bg-white py-3 '>
        <Button className='text-primary border-2  mx-auto hover:border-primary hover:font-bold' type='submit'> <ArrowNext/> Next</Button>
      </div>}
    </Form>
  );
};

export default CertificationForm;
