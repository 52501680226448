import * as Yup from 'yup';

export const VALIDATIONSHEMA = Yup.object({
  course_id: Yup.string().required(),
  type: Yup.string().required(),
  hours: Yup.string().required(),
  price: Yup.number().required(),
  capacity: Yup.number().required(),
  currency_id: Yup.number().required()
});
