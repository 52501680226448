/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import CertificationForm from '../Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import TextError from '../../UI/TextError';

const CertificationEdit = ({certId, onClose, fetchCert, setCertId}) => {
  const [certificate, setCertificate] = useState(false);
  const [error, setError] = useState('');
  const token = getToken();

  let credentialNotExpire = '';
  if (certificate.credential_not_expire === 1) {
    credentialNotExpire = true;
  } else {
    credentialNotExpire = false;
  }

  const INITIALVALUES = {
    name: certificate.name,
    issuing_organization: certificate.issuing_organization,
    credential_not_expire: credentialNotExpire,
    issue_date_month: certificate.issue_date_month,
    issue_date_year: certificate.issue_date_year,
    expiration_date_month: certificate.expiration_date_month,
    expiration_date_year: certificate.expiration_date_year,
    credential_id: certificate.credential_id,
    credential_url: certificate.credential_url,
    user_id: certificate.user_id
  };

  useEffect(() => {
    axios.get(`/api/certificates/${certId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCertificate(res.data.data);
    }).catch(error => setError(error.messaage));
    return () => setCertId(null);
  }, []);

  const onDelete = () => {
    axios.delete(`/api/certificates/${certId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
      onClose();
      fetchCert(certId);
    }).catch(error => setError(error.message));
  };

  const onSubmit = values => {
    axios.put(`/api/certificates/${certId}`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
      onClose();
      fetchCert(certId);
    }).catch(error => setError(error.message));
  };

  return (
    <Fragment>
      {error && <TextError>{error}</TextError>}
      {certificate && <Formik
        initialValues = {INITIALVALUES}
        validationSchema = {VALIDATIONSHEMA}
        onSubmit={onSubmit}
        onReset={onDelete}>
        {formik => (
          <CertificationForm edit formik={formik} />
        )}
      </Formik>}
    </Fragment>
  );
};

export default CertificationEdit;
