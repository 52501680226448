/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import ExperienceForm from '../Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import TextError from '../../UI/TextError';

const ExperienceEdit = ({expId, onClose, fetchExp, setExpId}) => {
  const [experience, setExperience] = useState(false);
  const [error, setError] = useState('');
  const token = getToken();
  const [categories, setCategories] = useState(false);
  const [countries, setCountries] = useState(false);

  useEffect(() => {
    axios.get(`/api/experiences/${expId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setExperience(res.data.data);
    }).catch(error => setError(error.messaage));
    axios.get(`/api/categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCategories(res.data.data);
    });
    axios.get(`/api/countries`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCountries(res.data.data);
    }).catch(error => setError(error.messaage));
    return () => {
      setExpId(null);
      fetchExp(true);
    };
  }, []);

  const onDelete = () => {
    axios.delete(`/api/experiences/${expId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
      onClose();
      fetchExp(expId);
    }).catch(error => setError(error.message));
  };

  const onSubmit = values => {
    if (values.currently_working) {
      delete values.end_month;
      delete values.end_year;
    }
    axios.put(`/api/experiences/${expId}`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
      onClose();
      fetchExp(expId);
    }).catch(error => setError(error.message));
  };

  let currentlyWorking = '';
  if (experience.currently_working === 1) {
    currentlyWorking = true;
  } else {
    currentlyWorking = false;
  }

  const INITIALVALUES = {
    title: experience.title,
    employment_type: experience.employment_type,
    company_name: experience.company_name,
    start_month: experience.start_month,
    start_year: experience.start_year,
    end_month: experience.end_month,
    end_year: experience.end_year,
    headline: experience.headline,
    category_id: experience.category_id,
    country_id: experience.country_id,
    city_id: experience.city_id,
    description: experience.description,
    currently_working: currentlyWorking,
    end_current_postion: experience.end_current_postion,
    user_id: experience.user_id
  };

  return (
    <Fragment>
      {error && <TextError>{error}</TextError>}
      {experience && categories && countries &&
      <Formik
        initialValues = {INITIALVALUES}
        validationSchema = {VALIDATIONSHEMA}
        onSubmit={onSubmit}
        onReset={onDelete}>
        {formik => (
          <ExperienceForm categories={categories} countries={countries} edit formik={formik} />
        )}
      </Formik>}
    </Fragment>
  );
};

export default ExperienceEdit;
