/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import CompanyDetailsForm from '../Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import TextError from '../../UI/TextError';
import { useAuth } from '../../../context/auth';

const CompanyDetailsCreate = ({userId, success, setFetch}) => {
  const [error, setError] = useState('');
  const [categories, setCategories] = useState(false);
  const [employmentTypes, setEmploymentTypes] = useState(false);
  const token = getToken();
  const { currentUser } = useAuth();

  const INITIALVALUES = {
    name: currentUser.company_details ? currentUser.company_details.name ? currentUser.company_details.name : '' : '',
    category_id: currentUser.company_details ? currentUser.company_details.category_id || '' : '',
    sub_category_id: currentUser.company_details ? currentUser.company_details.sub_category_id || '' : '',
    user_role: currentUser.company_details ? currentUser.company_details.user_role || '' : '',
    about: currentUser.company_details ? currentUser.company_details.about || '' : '',
    _method: 'PUT'
  };

  useEffect(() => {
    axios.get(`/api/categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCategories(res.data.data);
    }).catch(error => setError(error.messaage));
    axios.get(`/api/employment_types`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setEmploymentTypes(res.data.data);
    }).catch(error => setError(error.messaage));
  }, []);

  const onSubmit = values => {
    axios.post(`/api/profile/${userId}`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
      success();
      setFetch(state => !state);
    }).catch(error => setError(error.message));
  };
  return (
    <Fragment>
      {error && <TextError>{error}</TextError>}
      {categories && employmentTypes && <Formik
        initialValues = {INITIALVALUES}
        validationSchema = {VALIDATIONSHEMA}
        onSubmit={onSubmit}>
        {formik => (
          <CompanyDetailsForm values={formik.values} categories={categories} employmentTypes={employmentTypes} />
        )}
      </Formik>}
    </Fragment>
  );
};

export default CompanyDetailsCreate;
