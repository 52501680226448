import * as Yup from 'yup';

export const VALIDATIONSHEMA = Yup.object({
  school: Yup.string().required(),
  start_month: Yup.string(),
  start_year: Yup.string(),
  end_month: Yup.string(),
  end_year: Yup.string()
//   grade: Yup.string()
});
