/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import ContactInfo from '../ContactInfo';
import Modal from '../UI/Modal';
import PersonalInfoEdit from './Edit';
import ProfileImageEdit from './Edit/EditProfileImage';
import CoverImageEdit from './Edit/EditCoverImage';
import { useAuth } from '../../context/auth';
import axios from 'axios';
import { getToken, setIntendedUrl } from '../../utils/auth';
import { useHistory, useParams } from 'react-router-dom';
import EditIcon from '../UI/Icons/EditIcon';
import OptionIcon from '../UI/Icons/OptionIcon';
import Button from '../UI/Button';
import FollowIcon from '../UI/Icons/FollowIcon';
import UnFollowIcon from '../UI/Icons/UnFollowIcon';

const PersonalInfo = ({userInfo}) => {
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditProfileImage, setShowEditProfileImage] = useState(false);
  const [showEditCoverImage, setShowEditCoverImage] = useState(false);
  const [user, setUser] = useState(userInfo);
  const [fetch, setFetch] = useState(false);
  const [userId, setUserId] = useState(null);
  const {currentUser, setCurrentUser, setToken} = useAuth();
  const token = getToken();
  const isCurrentUser = user.id === currentUser.id;
  const { username } = useParams();
  const history = useHistory();

  const handleProfileImage = () => setShowEditProfileImage(true);
  const handleCoverImage = () => setShowEditCoverImage(true);
  const handleFetch = () => setFetch(prevState => !prevState);
  const handleCloseEdit = () => setShowEditModal(false);

  const handleLogout = () => {
    setCurrentUser(null);
    setToken(null);
    history.push('/');
    setIntendedUrl(null);
  };
  const handleFollow = () => {
    axios.post(`/api/user/follow`, {user_id: userInfo.id}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      setFetch(state => !state);
    });
  };

  useEffect(() => {
    axios.get(`/api/profile/${username}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setUser(res.data.data);
    });
  }, [fetch, username]);

  useEffect(() => {
    if (userId !== null) {
      setShowEditModal(true);
    }
  }, [userId]);

  return (
    <Fragment>
      {user && <div className='w-full mx-auto max-w-screen-lg h-auto flex flex-col justify-start items-start rounded-lg bg-white shadow-lg border-1 relative pb-3 mt-10'>
        <img
          className='w-full h-64 cursor-pointer mb-4 object-cover rounded-t-lg'
          src={user.cover_photo} alt="user cover image" />
        {isCurrentUser && <button
          onClick={handleCoverImage}
          style={{position: 'absolute', top: '38%', left: '0px'}}
          className="p-3 bg-white rounded-tr-lg text-primary">
          <EditIcon/>
        </button>}
        <div className='flex justify-start items-start w-full h-full my-8 gap-10' >
          <div className="relative flex flex-row justify-start items-end">
            <img className='w-40 h-40 rounded-lg border-3 border-white cursor-pointer ml-10' src={user.photo} />
            {isCurrentUser && <button onClick={handleProfileImage} className="p-2 bg-white rounded-tl-lg text-primary opacity-50 hover:opacity-100" style={{position: 'absolute', top: '0px', left: '25%'}}><EditIcon /></button>}
          </div>
          <div className='w-full px-8'>
            <div className="flex flex-row justify-between items-center w-full">
              <div className='flex flex-col justify-start items-start'>
                {user.role === 'user' ? <h4 className='text-2xl text-primary'>{user.company_details && user.company_details.name}</h4> : <h4 className='text-2xl text-primary'>{user.first_name} {user.last_name}</h4>}
                <p className="text-md text-primary">
                  <span>{user.role === 'user' && user.company_details && user.company_details.category && user.company_details.category.label} -- </span>
                  <span>{user.country && `${user.country.label}`}</span>
                </p>
              </div>
              <div className="flex flex-row justify-end items-center gap-4" >
                {!isCurrentUser && <Fragment>
                  { user.follow ? <Button onClick={handleFollow} className='border border-gray-400 text-white bg-secondary p-2 '>
                    <UnFollowIcon/>
                                  Following
                  </Button> : <Button onClick={handleFollow} className='border border-gray-400 text-primary p-2 '>
                    <FollowIcon />
                                  Follow
                  </Button>}
                </Fragment>}
                <button className="py-2 px-4 text-primary border border-primary uppercase rounded-lg hover:font-semibold" onClick={() => setShowContactInfo(true)} >Contact info</button>
                {isCurrentUser && <button className="p-2 text-red-500 font-semibold uppercase" onClick={handleLogout} >log out</button>}
                {isCurrentUser && <button onClick={() => setShowEditModal(true)} className="ml-2 p-3 hover:bg-gray-200 rounded-full">
                  <OptionIcon/>
                </button>}

              </div>
            </div>

            {user.about && <p className="text-primary opacity-75 my-4">{user.about}</p>}

            <div className='flex justify-start items-start gap-4' >
              {user.fields_of_interest && user.fields_of_interest.data.map(field => (<div className='text-secondary p-2 border border-gray-400 cursor-pointer rounded-md' key={field.id} >{field.label}</div>))}
            </div>

          </div>
        </div>
        {showEditCoverImage && <CoverImageEdit fetchInfo={handleFetch} onClose={() => setShowEditCoverImage(false)} coverImage={user.cover_photo} />}
        {showEditProfileImage && <ProfileImageEdit userImage={user.photo} fetchInfo={handleFetch} onClose={() => setShowEditProfileImage(false)} />}
        {showContactInfo && <ContactInfo isCurrentUser={isCurrentUser} setUserId={setUserId} fetch={fetch} fetchInfo={handleFetch} userInfo={user} onClose={() => setShowContactInfo(false)}/>}
        {showEditModal && <Modal title="Edit Personal info" onClose={handleCloseEdit}><PersonalInfoEdit setUserId={setUserId} fetchInfo={handleFetch} onClose={handleCloseEdit} userId={user.id} /></Modal>}
      </div>}
    </Fragment>
  );
};

export default PersonalInfo;
