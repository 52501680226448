/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Post from '../components/Post';
import CreatePost from '../components/Post/Create';
import axios from 'axios';
import { getToken } from '../utils/auth';
import TextError from '../components/UI/TextError';
import { useAuth } from '../context/auth';

const Posts = ({ category, postTab, userId }) => {
  const [posts, setPosts] = useState('');
  const [error, setError] = useState();
  const token = getToken();
  const [fetch, setFetch] = useState(false);
  const { currentUser } = useAuth();

  const toggleFetchPosts = () => setFetch((prevState) => !prevState);

  useEffect(() => {
    if (token !== null) {
      axios
        .get(
          `/api/posts${category && '/category/'}${
            category && category
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then((res) => {
          postTab
            ? setPosts(
              res.data.data.filter(
                (post) => post.user?.id === userId
              )
            )
            : setPosts(res.data.data);
        })
        .catch((error) => setError(error.message));
    }
  }, [fetch, token, category]);

  return (
    <div
      className={`flex flex-col px-3 md:px-16  w-full ${
        postTab ? 'md:w-4/5 mx-auto' : 'md:w-1/2'
      }`}
    >
      {error && <TextError>{error}</TextError>}
      {currentUser.id === userId && (
        <CreatePost fetchPosts={toggleFetchPosts} />
      )}
      <p className="text-primary my-5 font-body">
                Posts from people and companies you follow...
      </p>
      {posts ? (
        posts.map(
          (post) =>
            post.user && (
              <Post
                fetchPosts={toggleFetchPosts}
                key={post.id}
                id={post.id}
                industry={post.industry}
                country={
                  post.user.country
                    ? post.user.country.label
                    : ''
                }
                userId={post.user.id}
              />
            )
        )
      ) : (
        <img src="/images/loading-dots-blue.gif" alt="loading" />
      )}
    </div>
  );
};

export default Posts;
