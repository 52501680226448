import React from 'react';

const CalendarIcon = () => {
  return (
    <svg id="calendar" xmlns="http://www.w3.org/2000/svg" width="24" height="24.128" viewBox="0 0 24 24.128">
      <rect id="container" width="24" height="24" fill="none"/>
      <g id="Group_138" data-name="Group 138" transform="translate(-3.04 -2.25)">
        <path id="Path_91" data-name="Path 91" d="M9.278,4.254a1,1,0,1,0-2.009,0V6.687a4.52,4.52,0,0,0-3.847,3.882l-.116.874q-.029.222-.056.445a.66.66,0,0,0,.659.736H26.03a.66.66,0,0,0,.659-.736q-.027-.223-.056-.445l-.116-.874a4.52,4.52,0,0,0-3.847-3.882V4.254a1,1,0,1,0-2.009,0V6.488a64.165,64.165,0,0,0-11.384,0Z" transform="translate(0.07)" fill="#0f4975"/>
        <path id="Path_92" data-name="Path 92" d="M27.019,12.387a.661.661,0,0,0-.664-.637H3.724a.661.661,0,0,0-.664.637A38.833,38.833,0,0,0,3.5,19.633a4.03,4.03,0,0,0,3.587,3.391l1.6.158a64.746,64.746,0,0,0,12.707,0l1.6-.158a4.03,4.03,0,0,0,3.587-3.391A38.824,38.824,0,0,0,27.019,12.387Z" transform="translate(0 2.884)" fill="#0f4975"/>
      </g>
    </svg>

  );
};

export default CalendarIcon;
