/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {Form} from 'formik';
import FormControl from '../../UI/FormControl';
import Button from '../../UI/Button';
import { months, years } from '../../utils/constants';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import ArrowNext from '../../UI/Icons/ArrowNext';
import CompanyIcon from '../../UI/Icons/CompanyIcon';
import CalendarIcon from '../../UI/Icons/CalendarIcon';

const ExperienceForm = ({formik, edit, countries, categories}) => {
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const token = getToken();
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (formik.values.country_id !== '') {
      axios.get(`/api/countries/${parseInt(formik.values.country_id)}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(res => {
        setCities(res.data.data.cities.data);
      });
    }
  }, [formik.values.country_id]);

  useEffect(() => {
    axios.get(`/api/employment_types`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((res) => {
      setEmploymentTypes(res.data.data);
    });
  }, []);
  return (
    <Form className="w-full">
      <FormControl control='input' type='text' label='Job title...' name='title' />
      <FormControl control='textarea' label='Position description' name='description'/>
      <FormControl control='select' label='Employment Type' name='employment_type' options={employmentTypes}/>
      <FormControl control='input' type='text' label='Company Name' name='company_name' Icon={<CompanyIcon/>} />
      <FormControl control='select' label='Industry' name='category_id' options={categories} />
      <FormControl control='select' label='Country' name='country_id' options={countries}/>
      <FormControl control='select' label='City' name='city_id' options={cities}/>
      <div className='grid gap-4 grid-cols-2'>
        <FormControl control='select' label='Start Month' name='start_month' options={months} Icon={<CalendarIcon/>}/>
        <FormControl control='select' label='Start Year' name='start_year' options={years} Icon={<CalendarIcon />}/>
      </div>
      {!formik.values.currently_working && <div className='grid gap-4 grid-cols-2'>
        <FormControl control='select' label='End Month' name='end_month' options={months} Icon={<CalendarIcon />}/>
        <FormControl control='select' label='End Year' name='end_year' options={years} Icon={<CalendarIcon />}/>
      </div>}
      <FormControl control='checkbox' label="I am currently working in this role" name="currently_working" />
      {edit ? <div className="border-t sticky w-full  flex flex-row-reverse justify-between items-center bottom-0 bg-white py-3">
        <Button className='text-primary border-2  mx-auto hover:border-primary hover:font-bold' type='submit'>Update</Button>
        <Button className='text-red-600 bg-transparent rounded-full hover:bg-gray-300' type='reset' >Delete</Button>
      </div> : <div className='border-t sticky w-full flex flex-row-reverse justify-between items-center bottom-0 bg-white py-3 '>
        <Button className='text-primary border-2  mx-auto hover:border-primary hover:font-bold' type='submit'> <ArrowNext/> Next</Button>
      </div>}
    </Form>
  );
};

export default ExperienceForm;
