/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormControl from '../../UI/FormControl';
import Button from '../../UI/Button';
import ArrowNext from '../../UI/Icons/ArrowNext';
import { getToken } from '../../../utils/auth';
import axios from 'axios';
import { courseLang, courseTypes } from '../../utils/constants';
import LocationIcon from '../../UI/Icons/LocationIcon';
import IndustryIcon from '../../UI/Icons/IndustryIcon';

const SpecificsForm = ({ setSelectedIndex, setSubmitValues, countries, categories }) => {
  const token = getToken();
  const [cities, setCities] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const VALIDATIONSHEMA = Yup.object({
    country_id: Yup.string().required().label('Country'),
    city_id: Yup.string().required().label('City'),
    category_id: Yup.string().required().label('Category'),
    sub_category_id: Yup.string().required().label('Sub category'),
    course_lang: Yup.string().required().label('Course language'),
    course_type: Yup.string().oneOf(['online', 'onsite']).required().label('Course Type')
  });

  const onSubmit = (values) => {
    setSubmitValues(state => ({
      ...state,
      ...values
    }));
    setSelectedIndex(index => ++index);
  };
  const INITIALVALUES = {
    country_id: '',
    city_id: '',
    category_id: '',
    sub_category_id: '',
    course_lang: '',
    course_type: ''
  };

  return (
    <Formik
      initialValues = {INITIALVALUES}
      validationSchema = {VALIDATIONSHEMA}
      onSubmit={onSubmit}>
      {formik => (
        <Form className='w-full' >
          <label className='text-sm text-gray-600 font-semibold mb-1 flex gap-2' htmlFor='country_id'> <LocationIcon/> Location</label>
          <div className="grid grid-cols-2 gap-4">
            <FormControl control='select' label='Country' name='country_id' options={countries} onChange={(e) => {
              formik.setFieldValue('country_id', e.target.value);
              axios.get(`/api/countries/${parseInt(e.target.value)}`, {
                headers: {
                  'Authorization': `Bearer ${token}`
                }}).then(res => {
                setCities(res.data.data.cities.data);
              });
            }}/>
            <FormControl control='select' label='City' name='city_id' options={cities}/>
          </div>
          <label className='text-sm text-gray-600 font-semibold mb-1 flex gap-2' >Course Details</label>
          <div className="grid grid-cols-2 gap-4">
            <FormControl control='select' label='Language...' name='course_lang' options={courseLang}/>
            <FormControl control='select' label='Type...' name='course_type' options={courseTypes}/>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <FormControl control='select' label='Industry...' name='category_id' options={categories} Icon={<IndustryIcon/> } onChange={(e) => {
              formik.setFieldValue('category_id', e.target.value);
              axios.get(`/api/categories/${parseInt(e.target.value)}`, {
                headers: {
                  'Authorization': `Bearer ${token}`
                }}).then(res => {
                setSubCategories(res.data.data.sub_categories.data);
              });
            }}/>
            <FormControl control='select' label='Category...' name='sub_category_id' options={subCategories} />
          </div>
          <Button type='submit' className='text-primary border-2  mx-auto my-16 hover:border-primary hover:font-bold'> <ArrowNext/> Next</Button>
        </Form>)}
    </Formik>
  );
};

export default SpecificsForm;
