/* eslint-disable standard/array-bracket-even-spacing */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import CompanyDetailsCreate from '../components/CompanyDetails/Create';
import ArrowBack from '../components/UI/Icons/ArrowBack';

function CreateCompanyProfile () {
  const history = useHistory();
  return (
    <div className="flex justify-center w-full bg-primary_bg">
      <div className="relative bg-white w-800px  px-8 flex flex-col justify-center items-center">

        <img src="/images/logo.png" alt="Peconto" className='my-10 w-20 md:w-24' />
        <div className='flex flex-col justify-center items-center w-5/7 mb-10'>
          <h1 className='text-2xl md:text-4xl text-primary font-body font-semibold capitalize' >Complete your profile</h1>
          <p className='text-sm md:text-lg text-primary font-normal opacity-75 text-center w-2/3 md:w-4/5' >Complete your company profile.</p>
        </div>

        <div className='absolute top-0 left-0 m-6'>
          <Link to='/' className='text-primary flex justify-start items-center p-3'>
            <ArrowBack fill="#0F4975" />
                      Back
          </Link>
        </div>

        <div className='absolute top-0 right-0 m-6'>
          <Link to='/create-company-contact' className='text-primary opacity-75 flex justify-start items-center p-3 hover:text-secondary'>
                      Skip this step
          </Link>
        </div>

        <div className='w-full md:w-2/3 mt-10'>
          <CompanyDetailsCreate success={() => history.push('/create-company-contact')}/>
        </div>

      </div>
    </div>
  );
}

export default CreateCompanyProfile;
