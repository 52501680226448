import React from 'react';

function Dashboard () {
  return (
    <div className='container mx-auto h-full'>
      <div className='ml-5 mt-12'>
        <h1 className='text-2xl'>Welocme to your dashboard</h1>
      </div>
    </div>
  );
}

export default Dashboard;
