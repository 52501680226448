/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import { Formik, Form, ErrorMessage } from 'formik';
import FormControl from '../../UI/FormControl';
import * as Yup from 'yup';
import { HiOutlineDocumentText } from 'react-icons/hi';
import TextError from '../../UI/TextError';

const ApplyForm = ({ userId, jobId, onClose }) => {
  const token = getToken();

  const onSubmit = values => {
    const formData = new FormData();
    formData.append('user_id', userId);
    formData.append('job_id', jobId);
    formData.append('cover_letter', values.coverLetter);
    formData.append('resume', values.file);

    axios.post('/api/applies', formData, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(() => {
      onClose();
    }).catch();
  };

  return (
    <Formik
      initialValues={{ coverLetter: '', file: '' }}
      validationSchema={Yup.object({ coverLetter: Yup.string(), file: Yup.mixed().required('CV is required') })}
      onSubmit={onSubmit}
    >
      {({setFieldValue, values}) => (
        <Form>
          <FormControl control='textarea' name='coverLetter' label='Cover letter...' />
          <div className='flex justify-start items-center p-3 border border-gray-400 rounded-lg gap-4'>
            <label className='text-primary flex gap-2 px-2 py-1 rounded-md hover:bg-gray-400 cursor-pointer items-center' htmlFor="file"> <HiOutlineDocumentText className='text-primary' /> upload cv...</label>
            <span className='text-xs text-primary opacity-75'>{values.file.name}</span>
            <input type="file"
              accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
              name="file"
              id="file"
              onChange={(event) => {
                setFieldValue('file', event.currentTarget.files[0]);
              }} style={{display: 'none'}} />
            <ErrorMessage name='file' component={TextError} />
          </div>
          <button className='w-full rounded-lg my-4 p-4  flex justify-center items-center bg-primary text-white font-semibold text-base hover:text-primary hover:bg-white uppercase' type='submit' >send application</button>
        </Form>
      )}
    </Formik>

  );
};

export default ApplyForm;
