import { createSlice } from '@reduxjs/toolkit';

const privacy = createSlice({
  name: 'privacy',
  initialState: {
    privacy: {data: [], count: 0},
    fetched: undefined,
    updated: {}
  },
  reducers: {
    editPrivacy (state, action) {},
    editPrivacySuccess (state, action) {
      state.updated = action.payload;
    },
    getPrivacy (state, action) {},
    getPrivacySuccess (state, action) {
      state.fetched = action.payload.data;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  editPrivacy,
  editPrivacySuccess,
  getPrivacy,
  getPrivacySuccess
} = privacy.actions;

export default privacy.reducer;
