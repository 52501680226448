/* eslint-disable indent */
import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { getToken } from '../../utils/auth';
import TextError from '../UI/TextError';
import Button from '../UI/Button';
import { useHistory } from 'react-router-dom';
import SearchIcon from '../UI/Icons/SearchIcon';
import { Popover, RadioGroup } from '@headlessui/react';
import Card from '../UI/Card';
import { useAuth } from '../../context/auth';

const Search = () => {
  const token = getToken();
  const [result, setResult] = useState(null);
  const [name, setName] = useState('');
  const [searchFor, setSearchFor] = useState('companies');
  const {currentUser} = useAuth();

  const history = useHistory();

  const search = (e) => {
    const name = e.target.value;
    setName(name);
      name !== ''
          ? searchFor === 'jobs'
              ? axios.get(`/api/job/filter/?title=${name}`, {
                  headers: {
                      'Authorization': `Bearer ${token}`
                  }
              }).then(res => setResult(res.data.data))
        : axios.get(`/api/search/${searchFor}/?name=${name}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(res => setResult(res.data.data))
      : setResult(false);
  };

  const handleClickResult = (username) => {
    history.push(`/profile/${username}`);
    setResult(false);
    setName('');
  };

    const handleClick = () => {
        searchFor === 'jobs' ? history.push(`/jobs/${name}`)
        : history.push(`/search/${searchFor}/${name}`);
    setResult(false);
    setName('');
  };

const handleJobClick = (id) => {
    history.push(`/job/show/${id}`);
    setResult(false);
    setName('');
};

  return (
    <Popover className="relative" >
      <Popover.Button className='focus:outline-none' ><SearchIcon /></Popover.Button>
      <Popover.Panel className="absolute z-10 right-0">
        <Card className="bg-white border border-primary flex flex-col p-1">

          <input className='w-64 h-10 p-4 text-base border border-gray-400 rounded-md' type='text' value={name} placeholder="search here" onChange={search} />
          <RadioGroup value={searchFor} onChange={setSearchFor} className='mx-auto w-full flex justify-center items-center my-3 text-sm border border-gray-400 text-gray-400 rounded-md'>
            {currentUser.role === 'user'
              ? <RadioGroup.Option value='instructors' className='w-full'>
                {({ checked }) => (
                  <span className={`flex justify-center items-center w-full py-1 rounded-md cursor-pointer ${checked ? 'bg-secondary text-white' : ''}`}>Instructors</span>
                )}
              </RadioGroup.Option>
              : <RadioGroup.Option value='jobs' className='w-full'>
                {({ checked }) => (
                  <span className={`flex justify-center items-center w-full py-1 rounded-md cursor-pointer ${checked ? 'bg-secondary text-white' : ''}`}>Requests</span>
                )}
              </RadioGroup.Option>}
            <RadioGroup.Option value='companies' className='w-full'>
              {({ checked }) => (
                <span className={`flex justify-center items-center w-full py-1 rounded-md cursor-pointer ${checked ? 'bg-secondary text-white' : ''}`}>Companies</span>
              )}
            </RadioGroup.Option>
          </RadioGroup>
          {result && <Fragment>
            {result.length === 0
              ? <TextError>No Result Found</TextError>
              : <Fragment>
                {result.map(user => (searchFor === 'jobs' ? <div onClick={() => handleJobClick(user.id)} key={user.id} className='flex flex-col justify-start items-start p-2 mb-2 rounded-md cursor-pointer  border border-gray-300 hover:border-primary'>
                    <h3 className='text-primary text-lg font-semibold' >{user.title}</h3>
                    <p className='text-sm text-gray-500'>{user.category.label} <span className='text-xs' >{user.country.label}</span></p>
                </div> : <div onClick={() => handleClickResult(user.username)} key={user.id} className='flex justify-start items-center p-2 mb-2 rounded-md cursor-pointer  border border-gray-300 hover:border-primary'>
                  <img className="w-12 h-12 rounded-md" src={user.photo} alt='user photo' />
                    <div className='ml-4'>
                    <h3 className='text-primary text-lg' >{user.full_name}</h3>
                  <p className='text-sm text-gray-500'>{user.username}</p>
                 </div>
                </div>))}
                <Button onClick={handleClick} className='text-secondary border-2 border-white rounded-md hover:border-secondary mt-5 mx-auto'>View All Result</Button>
              </Fragment>
            }
          </Fragment>}
        </Card>
      </Popover.Panel>
    </Popover>
  );
};

export default Search;
