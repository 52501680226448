/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import FollowIcon from '../Icons/FollowIcon';

const Check = ({ field, toggleSelect }) => {
  const [checked, setChecked] = useState(false);
  const handleClick = (id) => {
    toggleSelect(id);
    setChecked(state => !state);
  };
  return (
    <div onClick={() => handleClick(field.id)} className={`flex justify-center items-center gap-x-4 border p-2 cursor-pointer rounded-lg ${checked ? 'bg-secondary text-white border-primary' : 'border-gray-300 text-primary'}`} >
      <FollowIcon/> <p>{field.label}</p>
    </div>
  );
};

export default Check;
