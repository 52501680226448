/* eslint-disable react/prop-types */
import React from 'react';

const LikeIcon = ({width, height, fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '20.531'} height={height || '20.547'} viewBox="0 0 20.531 20.547"><g transform="translate(-0.008 0)"><path d="M.616,9.027a.608.608,0,0,0-.608.608v8.927a.608.608,0,0,0,.608.608H4.278V9.027Z" fill={ fill || '#0f4975'} /><path d="M20.539,12.384a2.12,2.12,0,0,0-1.033-1.819,2.118,2.118,0,0,0-1.743-3.322H13.29c.137-.621.325-1.553.455-2.529.34-2.54.108-3.948-.731-4.432A1.832,1.832,0,0,0,11.428.109a2.086,2.086,0,0,0-1.206,1.4L9.046,4.585C8.45,6.057,6.625,7.6,5.493,8.456V19.439a19.649,19.649,0,0,0,6.52,1.108h4.874a2.118,2.118,0,0,0,1.8-3.235,2.117,2.117,0,0,0,.819-3.11A2.12,2.12,0,0,0,20.539,12.384Z" fill={ fill || '#0f4975'}/></g></svg>
  );
};

export default LikeIcon;
