/* eslint-disable react/prop-types */
import * as React from 'react';
import {
  Box,
  List,
  CssBaseline,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { Drawer, DrawerHeader } from './sidebar.style';
import {FiUsers} from 'react-icons/fi';
import { HiOutlineMenu } from 'react-icons/hi';
import { BiCategory } from 'react-icons/bi';
import { MdOutlineCategory } from 'react-icons/md';
import { Link } from 'react-router-dom';

export default function MiniDrawer ({open, handleDrawer}) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawer}>
            <HiOutlineMenu />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <Link to='/admin/users'>
            <ListItem button key={'Users'}>
              <ListItemIcon>
                <FiUsers />
              </ListItemIcon>
              <ListItemText primary={'Users'} />
            </ListItem>
          </Link>

          <Link to='/admin/categories'>
            <ListItem button key={'Categories'}>
              <ListItemIcon>
                <BiCategory />
              </ListItemIcon>
              <ListItemText primary={'Categories'} />
            </ListItem>
          </Link>

          <Link to='/admin/sub-categories'>
            <ListItem button key={'Sub Categories'}>
              <ListItemIcon>
                <MdOutlineCategory />
              </ListItemIcon>
              <ListItemText primary={'Sub Categories'} />
            </ListItem>
          </Link>

          <Link to='/admin/privacy'>
            <ListItem button key={'Privacy Policy'}>
              <ListItemIcon>
                <MdOutlineCategory />
              </ListItemIcon>
              <ListItemText primary={'Privacy Policy'} />
            </ListItem>
          </Link>

          <Link to='/admin/terms'>
            <ListItem button key={'Terms of Use'}>
              <ListItemIcon>
                <MdOutlineCategory />
              </ListItemIcon>
              <ListItemText primary={'Terms of Use'} />
            </ListItem>
          </Link>

          <Link to='/admin/ad-email'>
            <ListItem button key={'Ad Email'}>
              <ListItemIcon>
                <MdOutlineCategory />
              </ListItemIcon>
              <ListItemText primary={'Ad Email'} />
            </ListItem>
          </Link>
        </List>
      </Drawer>
    </Box>
  );
}
