import React, {useState} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Line from '../../components/UI/Icons/Line';
import Modal from '../../components/UI/Modal';
import ArrowBack from '../../components/UI/Icons/ArrowBack';
import EnvelopIcon from '../../components/UI/Icons/EnvelopIcon';
import RegisterWithEmail from '../../components/register-with-email';
import TermsOfUse from '../../components/TermsOfUse';
import { setToken } from '../../utils/auth';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
// import MicrosoftLogin from 'react-microsoft-login';
import { register } from '../../api/auth';
import { useAuth } from '../../context/auth';
import TextError from '../../components/UI/TextError';

var jwt = require('jsonwebtoken');

function Register () {
  const { role: userRole } = useParams();
  const [withEmail, setWithEmail] = useState(false);
  const [error, setError] = useState([]);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const history = useHistory();
  const { setCurrentUser } = useAuth();

  const back = () => {
    withEmail ? setWithEmail(false) : history.push('/');
  };

  const handleGoogle = (data) => {
    const userData = jwt.decode(data.credential);

    register({
      email: userData.email,
      first_name: userData.given_name,
      last_name: userData.family_name,
      role: userRole || 'user',
      google_id: userData.sub,
      // eslint-disable-next-line no-useless-escape
      password: 'r@83Xj~(h=XkQY\d',
      // eslint-disable-next-line no-useless-escape
      password_confirmation: 'r@83Xj~(h=XkQY\d'
    })
      .then(({ user, token }) => {
        if (token && user) {
          setToken(token);
          setCurrentUser(user);
          axios.post(`/api/email/verify/resend`, {}, {
            headers: {
              'Authorization': `Bearer ${token}`
            }});
          history.push('/verify');
        }
      })
      .catch((error) => {
        error.json().then(({ errors }) => setError(Object.values(errors)));
      });
  };

  return (
    <div className="flex justify-center w-full h-1080px bg-primary_bg">
      <div className="relative bg-white w-800px px:3  md:px-8 h-full flex flex-col justify-start items-center">
        <div className='absolute top-0 left-0 m-6'>
          <button onClick={back} className='text-primary flex justify-start items-center p-3 hover:text-link_primary'>
            <ArrowBack fill="#0F4975" />
                      Back
          </button>
        </div>
        <img src="/images/logo.png" alt="Peconto" className='mb-16 mt-20 w-24' />

        <div className='flex flex-col justify-center items-center mb-16'>
          <h1 className='text-4xl text-primary font-body font-semibold' >Start with Peconto</h1>
          <p className='text-lg text-primary font-normal opacity-75' >Create an {userRole} account with Peconto.</p>
        </div>

        {withEmail ? <RegisterWithEmail/> : <div className='flex flex-col justify-start items-center w-4/7'>
          <div className='flex flex-col justify-center align-center mb-6 w-full' >
            <GoogleLogin
              onSuccess={credentialResponse => {
                handleGoogle(credentialResponse);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
              width='380'
              theme='filled_blue'
              size='large'
            />
            {/* <GoogleLogin
              clientId={process.env.MIX_GOOGLE_CLIENT_ID}
              buttonText="Google"
              onSuccess={(res) => handleGoogle(res.profileObj)}
              onFailure={(res) => console.log(res)}
              cookiePolicy={'single_host_origin'}
            /> */}
            {/* <MicrosoftLogin clientId='dce1735a-97b2-4c27-b515-d2aeafd10fb8' authCallback={(data) => console.log(data)} /> */}
            {/* <button className='text-primary border-2 border-primary py-3 px-5 rounded-md hover:border-4 flex justify-start items-center gap-x-24 my-6' >
              <img src="/images/auth_microsoft.png" alt="microsoft" className='w-6 mr-3' />
              Microsoft
            </button> */}

            <div className='flex flex-col justify-center items-center mb-3'>
              {error.map((error, index) => <TextError key={index} > {error} </TextError>)}
            </div>

            <div className='flex justify-between items-center mt-5'>
              <Line />
              <span className='text-primary mx-6'>or</span>
              <Line/>
            </div>

            <button onClick={() => setWithEmail(true)} className='text-primary border-2 border-primary py-3 px-5 rounded-md hover:border-4 flex justify-start items-center gap-x-16 my-6' >
              <EnvelopIcon/>
              Continue with Email
            </button>
          </div>

          <div className="mb-3 2xl:mt-32 flex justify-center items-center text-primary">
          Already have an account? <Link to="/login" className="text-sm  text-link_primary font-bold ml-2"> LOG IN</Link>
          </div>

        </div>}

        <div className='absolute bottom-0 text-xs text-center mx-8 md:mx-24 mb-3 text-gray-500'>
            By creating an account with Peconto, you acknowledge that you have read and understood, and agree to the
          <span onClick={() => setShowTermsModal(true)} className='text-link_primary mx-1 font-semibold'>
            TERMS OF USE
          </span>
          and
          <span onClick={() => setShowPrivacyModal(true)} className='text-link_primary mx-1 font-semibold'>
            PRIVACY POLICY
          </span>
        </div>
        {showTermsModal && <Modal title='Terms of use' onClose={() => setShowTermsModal(false)} ><TermsOfUse/></Modal>}
        {showPrivacyModal && <Modal title='Privacy Policy' onClose={() => setShowPrivacyModal(false)} ><TermsOfUse/></Modal>}
      </div>
    </div>
  );
}

export default Register;
