import {call, put, takeEvery} from 'redux-saga/effects';
import Client from '../../api/client';
import {
  editPrivacy,
  editPrivacySuccess,
  getPrivacy,
  getPrivacySuccess
} from '../reducer/privacySlice';

function * edit (action) {
  const data = action.payload.values;
  const {id} = action.payload;
  let cmsData;
  try {
    cmsData = yield call(() => Client(`/api/cms/${id}`, {method: 'PUT', body: data}));
  } catch (error) {
    if (action.payload.failedCallback) {
      action.payload.failedCallback();
    }
    return null;
  }
  yield put(editPrivacySuccess(cmsData.data));
  if (action.payload.successCallback) {
    action.payload.successCallback();
  }
}

function * get (action) {
  const {id} = action.payload;
  let cmsData;
  try {
    cmsData = yield call(() => Client(`/api/cms/${id}`));
  } catch (error) {}
  yield put(getPrivacySuccess(cmsData));
}

function * privacySaga () {
  yield takeEvery(editPrivacy.type, edit);
  yield takeEvery(getPrivacy.type, get);
};

export default privacySaga;
