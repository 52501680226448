/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, listUser } from '../../../../store/reducer/userSlice';
import BasicTable from '../../../../components/UI/BasicTable';
import BasicBreadcrumbs from '../../../../components/UI/BasicBreadcrubs';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';

const ListUsers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { users } = useSelector(state => state.user);

  const editAction = (id) => history.push(`/admin/users/${id}`);
  const deleteAction = (id) => {
    const successCallback = () => {
      history.push('/admin/users');
      dispatch(listUser());
    };
    dispatch(deleteUser({id, successCallback}));
  };

  useEffect(() => {
    dispatch(listUser());
  }, [dispatch]);

  const tableCells = [
    {
      Header: 'Username',
      accessor: 'username',
      align: 'left'
    },
    {
      Header: 'First name',
      accessor: 'first_name',
      align: 'left'
    },
    {
      Header: 'Last name',
      accessor: 'username',
      align: 'left'
    },
    {
      Header: 'Email',
      accessor: 'email',
      align: 'left'
    },
    {
      Header: 'Role',
      accessor: 'role',
      align: 'left'
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      align: 'left'
    }
  ];

  return (
    <div className='container mx-auto h-full'>
      <div className='ml-5 my-12'>
        <div className='flex justify-between items-center mb-4'>
          <BasicBreadcrumbs active='Users' />
          <Button variant="contained" color="primary" onClick={() => history.push('/admin/user')}>
            Create User
          </Button>
        </div>
        {users && <BasicTable title='Users' rows={users.data} cells={tableCells} editAction={editAction} deleteAction={deleteAction} />}
      </div>
    </div>
  );
};

export default ListUsers;
