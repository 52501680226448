import React from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import BioCreate from '../components/Bio/Create';

const CreateInstructorBio = () => {
  const history = useHistory();
  return (
    <div className="flex justify-center w-full h-auto bg-primary_bg">
      <div className="relative bg-white w-800px  px-8 h-full flex flex-col justify-start items-center">
        <div className='absolute top-0 right-0 m-6'>
          <Link to='/' className='text-primary text-sm flex justify-start items-center p-3 hover:text-link_primary'>
                    Skip This Step
          </Link>
        </div>
        <div className='flex flex-col justify-center items-center mt-16 mb-8 md:mb-16 w-5/7'>
          <h1 className='text-2xl md:text-4xl text-primary font-body font-semibold capitalize' >complete your Bio</h1>
          <p className='text-sm md:text-lg text-primary font-normal opacity-75 text-center' >Add your info for a better and more customized experience.</p>
        </div>

        <ul className='flex justify-center items-center lg:gap-x-6 gap-2  border-b border-t py-2 md:py-5 w-full flex-wrap' >
          <li>
            <NavLink to='/create-instructor-contact'
              className="capitalize text-xs font-normal md:text-base text-primary py-1 md:py-5 px-2 "
              activeClassName='border-b-4 border-secondary text-secondary font-bold' >Contact</NavLink>
          </li>
          <li>
            <NavLink to='/create-instructor-bio'
              className="capitalize text-xs font-normal md:text-base text-primary py-1 md:py-5 px-2 "
              activeClassName='border-b-4 border-secondary text-secondary font-bold' >Bio</NavLink>
          </li>
          <li>
            <NavLink to='/create-instructor-experience'
              className="capitalize text-xs font-normal md:text-base text-primary py-1 md:py-5 px-2 "
              activeClassName='border-b-4 border-secondary text-secondary font-bold' >Experience</NavLink>
          </li>
          <li>
            <NavLink to='/create-instructor-education'
              className="capitalize text-xs font-normal md:text-base text-primary py-1 md:py-5 px-2 "
              activeClassName='border-b-4 border-secondary text-secondary font-bold' >Education</NavLink>
          </li>
          <li>
            <NavLink to='/create-instructor-certificates'
              className="capitalize text-xs font-normal md:text-base text-primary py-1 md:py-5 px-2 "
              activeClassName='border-b-4 border-secondary text-secondary font-bold' >Certificates</NavLink>
          </li>
          <li>
            <NavLink to='/create-instructor-courses'
              className="capitalize text-xs font-normal md:text-base text-primary py-5 px-2 "
              activeClassName='border-b-4 border-secondary text-secondary font-bold' >Courses</NavLink>
          </li>
        </ul>

        <h6 className='mt-10 text-primary' >Write about yourself.</h6>

        <div className='w-full md:w-2/3 mt-10'>
          <BioCreate success={() => history.push('/create-instructor-experience')} />
        </div>
      </div>
    </div>
  );
};

export default CreateInstructorBio;
