import React from 'react';
import Card from '../../UI/Card';
import JobSearch from './Search';

const InstructorJobs = () => {
  return (
    <Card className='w-full flex flex-col justify-start items-center shadow-none'>
      <JobSearch/>
    </Card>
  );
};

export default InstructorJobs;
