import * as Yup from 'yup';

export const VALIDATIONSHEMA = Yup.object({
  first_name: Yup.string(),
  last_name: Yup.string(),
  email: Yup.string().email(),
  phone_number: Yup.string(),
  address: Yup.string(),
  country_id: Yup.string(),
  city_id: Yup.string(),
  zip_code: Yup.string()
});
