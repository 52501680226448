/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import { Formik } from 'formik';
import SubCategoryForm from '../components/Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSubCategory } from '../../../../store/reducer/subCategorySlice';
import Card from '../../../../components/UI/Card';
import BasicBreadcrumbs from '../../../../components/UI/BasicBreadcrubs';
import axios from 'axios';
import { getToken } from '../../../../utils/auth';
import TextError from '../../../../components/UI/TextError';

const CreateSubCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState();
  const [error, setError] = useState();
  const token = getToken();

  useEffect(() => {
    axios.get(`/api/categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCategories(res.data.data);
    }).catch(error => setError(error.messaage));
  }, []);

  const INITIALVALUES = {
    name: '',
    label: '',
    category_id: ''
  };

  return (
    <div className='w-full bg-gray-200'>
      <div className='container p-2 mx-auto flex flex-col items-start justify-start md:w-3/5 sm:w-full'>
        <BasicBreadcrumbs active='Create' url='/admin/sub-categories' back='Sub Categories' />
        {error && <TextError>{error}</TextError>}
        <h3 className='text-2xl font-semibold my-6'>Create Sub Category</h3>
        <Card className='bg-white w-full'>
          {categories && <Formik
            initialValues = {INITIALVALUES}
            validationSchema = {VALIDATIONSHEMA}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              const successCallback = () => {
                setSubmitting(false);
                history.push('/admin/sub-categories');
              };
              const failedCallback = () => {
                setSubmitting(false);
              };
              dispatch(createSubCategory({ values, successCallback, failedCallback }));
            }}>
            {formik => (
              <SubCategoryForm values={formik.values} categories={categories} />
            )}
          </Formik>}
        </Card>
      </div>
    </div>
  );
};

export default CreateSubCategory;
