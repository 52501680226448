/* eslint-disable react/prop-types */
import { ErrorMessage, Field } from 'formik';
import React from 'react';
import TextError from '../TextError';

const Select = ({label, name, options, Icon, ...restProps}) => {
  return (
    <div className=' mb-4'>
      <div className="flex justify-start items-center bg-white border border-gray-300 rounded-md py-2 px-4">
        {Icon}
        <Field className='w-full py-1 px-3 text-primary font-base focus:outline-none' as='select' id={name} name={name} {...restProps}>
          <option value='' defaultValue >{label} </option>
          {options.map(option => (<option key={option.id} value={option.id}>{option.label}</option>))}
        </Field>
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default Select;
