/* eslint-disable react/prop-types */
import React from 'react';
import {Form} from 'formik';
import FormControl from '../../../../../components/UI/FormControl';
import Button from '../../../../../components/UI/Button';

const SubCategoryForm = ({categories, values, edit}) => {
  return (
    <Form className="w-full">
      <FormControl control='input' type='text' label='Name' name='name' />
      <FormControl control='input' type='text' label='Label' name='label' />
      <FormControl control='select' label='Category' name='category_id' options={categories} />
      <div className="border-t sticky w-full flex flex-row justify-between items-center bottom-0 bg-white py-3">
        <Button className='text-white bg-blue-700 rounded-full ml-auto hover:bg-blue-900' type='submit'>Submit</Button>
      </div>
    </Form>
  );
};

export default SubCategoryForm;
