/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import JobForm from '../Form';
import { getToken } from '../../../utils/auth';
import axios from 'axios';
import TextError from '../../UI/TextError';
import Card from '../../UI/Card';
import { Link } from 'react-router-dom';
import ArrowBack from '../../UI/Icons/ArrowBack';

const JobCreate = () => {
  const token = getToken();
  const [error, setError] = useState('');
  const [countries, setCountries] = useState();
  const [currencies, setCurrencies] = useState();
  const [categories, setCategories] = useState();

  useEffect(() => {
    axios.get(`/api/countries`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCountries(res.data.data);
    }).catch(error => setError(error.messaage));
    axios.get(`/api/categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCategories(res.data.data);
    });
    axios.get(`/api/currencies`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCurrencies(res.data.data);
    });
  }, []);

  return (
    <div className="flex justify-center w-full h-auto min-h-screen bg-primary_bg">
      <div className="relative bg-white w-800px  px-4 h-full flex flex-col justify-start items-center">

        <div className='flex flex-col justify-center items-center mt-16 mb-8 md:mb-16 w-5/7'>
          <h1 className='text-2xl md:text-4xl text-primary font-body font-semibold capitalize' >Post a Course Request</h1>
          <p className='text-sm md:text-lg text-primary font-normal opacity-75 text-center' >Hire a professional trainer for your business growth.</p>
        </div>

        <div className='absolute top-0 left-0 m-6'>
          <Link to='/home' className='text-primary text-sm flex justify-start items-center p-3 hover:text-link_primary'>
            <ArrowBack/>   back
          </Link>
        </div>

        <div className=' mx-auto flex flex-col items-start justify-start w-full '>
          {error && <TextError>{error}</TextError>}
          <Card className='bg-white w-full shadow-none'>
            {countries && categories && currencies && <JobForm setError={setError} countries={countries} currencies={currencies} categories={categories} />}
          </Card>
        </div>

      </div>
    </div>
  );
};

export default JobCreate;
