import React, { Fragment, useState, useEffect } from 'react';
import Card from '../../UI/Card';
import { useAuth } from '../../../context/auth';
import PostForm from '../Form';
import axios from 'axios';
import { getToken } from '../../../utils/auth';

// eslint-disable-next-line react/prop-types
const CreatePost = ({userId, fetchPosts}) => {
  const {currentUser} = useAuth();
  const [showPostForm, setShowPostForm] = useState(false);
  const [user, setUser] = useState(false);
  const handleShowPostForm = () => setShowPostForm(true);
  const handleClosePostForm = () => setShowPostForm(false);
  const token = getToken();

  useEffect(() => {
    axios.get(`/api/profile/${currentUser.username}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setUser(res.data.data);
    });
  }, []);
  return (
    <Fragment>
      {showPostForm ? <PostForm fetchPosts={fetchPosts} onClose={handleClosePostForm}/> : <Card className="flex-col bg-white">
        <div className='flex flex-row items-center my-2'>
          {user && <img className='w-12 h-12 p-1 border border-gray-300 rounded-lg mr-4 cursor-pointer' src={user.photo}/>}
          <div onClick={handleShowPostForm} className="flex-grow rounded-lg border cursor-pointer p-3 h-12 text-sm hover:border-primary" >Write a new post...</div>
        </div>
      </Card>}
    </Fragment>
  );
};

export default CreatePost;
