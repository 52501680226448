/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {Form} from 'formik';
import FormControl from '../../UI/FormControl';
import Button from '../../UI/Button';
import { getToken } from '../../../utils/auth';
import axios from 'axios';
import ArrowNext from '../../UI/Icons/ArrowNext';
import PhotoIcon from '../../UI/Icons/PhotoIcon';
import CompanyIcon from '../../UI/Icons/CompanyIcon';
import IndustryIcon from '../../UI/Icons/IndustryIcon';
import RoleIcon from '../../UI/Icons/RoleIcon';
import { userHiringRole } from '../../utils/constants';

const CompanyDetailsForm = ({ categories, values, employmentTypes }) => {
  const token = getToken();
  const [image, setImage] = useState(null);
  const [subCategories, setSubCategories] = useState([]);

  const handleChangeImage = (e) => {
    const image = e.target.files[0];

    if (image === '' || image === undefined) {
      // eslint-disable-next-line no-undef
      alert(`not an image, the file is a ${typeof image}`);
      return;
    } else {
      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData.append('photo', image);
      formData.append('_method', 'PUT');

      axios.post('/api/profile/image', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(() => {
      }).catch();
    }
    setImage(image);
  };

  useEffect(() => {
    if (values.category_id !== '') {
      axios.get(`/api/categories/${parseInt(values.category_id)}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(res => {
        setSubCategories(res.data.data.sub_categories.data);
      });
    }
  }, [values.category_id]);

  return (
    <Form className="w-full">
      <div className='h-32 w-32 mx-auto border-2 border-gray-300 rounded-md cursor-pointer p-1'>
        {image
          ? <img className='object-cover w-full h-full border-3 cursor-pointer' src={URL.createObjectURL(image)} />
          : <label className='p-4 w-full h-full flex flex-col justify-center items-center' htmlFor="post">
            <PhotoIcon/> <p className='text-sm text-gray-500 text-center my-3'>Personal photo</p> </label>}
      </div>
      <div className="w-full p-3">
        <input
          type="file"
          accept="image/gif, image/jpeg, image/png"
          name="image"
          id="post"
          onChange={handleChangeImage}
          style={{ display: 'none' }}
        />
      </div>
      <FormControl control='input' type='text' label='Company name...' name='name' Icon={<CompanyIcon />} />
      <FormControl control='select' label='Role in Company...' name='user_role' options={userHiringRole} Icon={<RoleIcon />}/>
      <FormControl control='select' label='Industry...' name='category_id' options={categories} Icon={<IndustryIcon/>}/>
      <FormControl control='select' label='Category...' name='sub_category_id' options={subCategories} />
      <FormControl control='textarea' name="about" label='About company...' />

      <div className="w-full flex flex-row justify-between items-center py-3">
        <Button className='text-primary border-2  mx-auto hover:border-primary hover:font-bold' type='submit'> <ArrowNext/> Next</Button>
      </div>
    </Form>
  );
};

export default CompanyDetailsForm;
