import React from 'react';

const PecontoCard = () => {
  return (
    <div className='w-full flex flex-col justify-start items-start'>
      <div className='flex flex-col justify-start items-start mb-16'>
        <h1 className='text-primary text-4xl font-body mb-6' >Peconto</h1>
        <p className='text-sm text-primary opacity-75 w-3/5' >A company, abbreviated as co., is a legal entity representing an association of people, whether natural, legal or a mixture of both, with a specific objective. Company members share a common purpose and unite to achieve specific, declared goals. Companies take various forms, such as: voluntary associations, which may include nonprofit organizations. Business entities, whose aim is generating profit. Financial entities and banks. Programs or educational institutions.</p>
      </div>
      <div className='w-full flex justify-end items-center mt-16 mb-8'>
        <img src="/images/logo.svg" alt="" />
      </div>
    </div>
  );
};

export default PecontoCard;
