/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import Categoryform from '../components/Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { editCategory, getCategory } from '../../../../store/reducer/categorySlice';
import Card from '../../../../components/UI/Card';
import BasicBreadcrumbs from '../../../../components/UI/BasicBreadcrubs';

const EditCategory = () => {
  const dispatch = useDispatch();
  const {id: categoryId} = useParams();
  const {fetched} = useSelector(state => state.category);
  const history = useHistory();

  useEffect(() => {
    dispatch(getCategory({id: categoryId}));
  }, []);

  const INITIALVALUES = fetched ? {
    name: fetched.name,
    label: fetched.label,
    _method: 'PUT'
  } : {};

  return (
    <div className='w-full bg-gray-200'>
      <div className='container p-2 mx-auto flex flex-col items-start justify-start md:w-3/5 sm:w-full'>
        <BasicBreadcrumbs active='Edit' url='/admin/categories' back='Categories' />
        <h3 className='text-2xl font-semibold my-6'>Edit Category</h3>
        <Card className='bg-white w-full'>
          {fetched && <Formik
            initialValues = {INITIALVALUES}
            validationSchema = {VALIDATIONSHEMA}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              const successCallback = () => {
                setSubmitting(false);
                history.push('/admin/categories');
              };
              const failedCallback = () => {
                setSubmitting(false);
              };
              dispatch(editCategory({ id: categoryId, values, successCallback, failedCallback }));
            }}>
            {formik => (
              <Categoryform values={formik.values}/>
            )}
          </Formik>}
        </Card>
      </div>
    </div>
  );
};

export default EditCategory;
