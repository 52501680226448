/* eslint-disable react/prop-types */
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../TextError';

const Input = ({label, name, Icon, ...restProps}) => {
  return (
    <div className='mb-4'>
      <div className='flex justify-start items-center border border-gray-300 rounded-md py-2 px-4'>
        {Icon}
        <Field id={name} name={name} {...restProps} placeholder={label} className="w-full py-1 px-3 text-primary font-base focus:outline-none"/>
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default Input;
