/* eslint-disable react/prop-types */
import React from 'react';
import { Formik } from 'formik';
import CategoryForm from '../components/Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createCategory } from '../../../../store/reducer/categorySlice';
import Card from '../../../../components/UI/Card';
import BasicBreadcrumbs from '../../../../components/UI/BasicBreadcrubs';

const CreateCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const INITIALVALUES = {
    name: '',
    label: ''
  };

  return (
    <div className='w-full bg-gray-200'>
      <div className='container p-2 mx-auto flex flex-col items-start justify-start md:w-3/5 sm:w-full'>
        <BasicBreadcrumbs active='Create' url='/admin/categories' back='Categories' />
        <h3 className='text-2xl font-semibold my-6'>Create Job</h3>
        <Card className='bg-white w-full'>
          {<Formik
            initialValues = {INITIALVALUES}
            validationSchema = {VALIDATIONSHEMA}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              const successCallback = () => {
                setSubmitting(false);
                history.push('/admin/categories');
              };
              const failedCallback = () => {
                setSubmitting(false);
              };
              dispatch(createCategory({ values, successCallback, failedCallback }));
            }}>
            {formik => (
              <CategoryForm values={formik.values} />
            )}
          </Formik>}
        </Card>
      </div>
    </div>
  );
};

export default CreateCategory;
