import React, { useState, useEffect } from 'react';
import TimelineView from '../components/TimelineView';
import TimelineFollow from '../components/TimelineFollow';
import Posts from '../containers/posts';
import { useAuth } from '../context/auth';
import { getToken } from '../utils/auth';
import axios from 'axios';

function Home () {
  const { currentUser } = useAuth();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [user, setUser] = useState();
  const token = getToken();
  useEffect(() => {
    axios.get(`/api/profile/${currentUser.username}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setUser(res.data.data);
    });
  }, [currentUser.username]);
  return (
    user ? <div className="w-full bg-primary_bg">
      <div className='container mx-auto flex justify-center items-start gap-x-6 pt-12' >
        <TimelineView user={user} fieldsOfInterest={user.fields_of_interest.data} selected={selectedCategory} setSelected={setSelectedCategory}/>
        <Posts userId={currentUser.id} category={selectedCategory} />
        <TimelineFollow/>
      </div>
    </div> : null
  );
}

export default Home;
