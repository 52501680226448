/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect } from 'react';
import { Formik } from 'formik';
import BioForm from '../Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import TextError from '../../UI/TextError';
import { useAuth } from '../../../context/auth';

const BioCreate = ({userId, success}) => {
  const [error, setError] = useState('');
  const [subCategories, setSubCategories] = useState('');
  const token = getToken();
  const { currentUser } = useAuth();

  const INITIALVALUES = {
    about: currentUser.about || '',
    _method: 'PUT'
  };

  useEffect(() => {
    axios.get(`/api/sub-categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setSubCategories(res.data.data);
    });
  }, []);

  const onSubmit = values => {
    axios.post(`/api/profile/${userId}`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
      success();
    }).catch(error => setError(error.message));
  };
  return (
    <Fragment>
      {error && <TextError>{error}</TextError>}
      {subCategories && <Formik
        initialValues = {INITIALVALUES}
        validationSchema = {VALIDATIONSHEMA}
        onSubmit={onSubmit}>
        {formik => (
          <BioForm subCategories={subCategories} />
        )}
      </Formik>}
    </Fragment>
  );
};

export default BioCreate;
