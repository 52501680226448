/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import Userform from '../components/Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import axios from 'axios';
import { getToken } from '../../../../utils/auth';
import TextError from '../../../../components/UI/TextError';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createUser } from '../../../../store/reducer/userSlice';
import Card from '../../../../components/UI/Card';
import BasicBreadcrumbs from '../../../../components/UI/BasicBreadcrubs';

const CreateUser = () => {
  const [countries, setCountries] = useState(false);
  const [error, setError] = useState('');
  const token = getToken();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    axios.get(`/api/countries`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCountries(res.data.data);
    }).catch(error => setError(error.messaage));
  }, []);

  const INITIALVALUES = {
    username: '',
    first_name: '',
    last_name: '',
    password: '',
    confirm_password: '',
    email: '',
    phone_number: '',
    country_id: '',
    city_id: '',
    address: '',
    zip_code: '',
    website: '',
    about: ''
  };

  return (
    <div className='w-full bg-gray-200'>
      <div className='container p-2 mx-auto flex flex-col items-start justify-start md:w-3/5 sm:w-full'>
        {error && <TextError>{error}</TextError>}
        <BasicBreadcrumbs active='Create' url='/admin/users' back='Users' />
        <h3 className='text-2xl font-semibold my-6'>Create Job</h3>
        <Card className='bg-white w-full'>
          {countries && <Formik
            initialValues = {INITIALVALUES}
            validationSchema = {VALIDATIONSHEMA}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              const successCallback = () => {
                setSubmitting(false);
                history.push('/admin/users');
              };
              const failedCallback = () => {
                setSubmitting(false);
              };
              dispatch(createUser({ values, successCallback, failedCallback }));
            }}>
            {formik => (
              <Userform values={formik.values} countries={countries} />
            )}
          </Formik>}
        </Card>
      </div>
    </div>
  );
};

export default CreateUser;
