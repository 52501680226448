/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormControl from '../../UI/FormControl';
import Button from '../../UI/Button';
import ArrowNext from '../../UI/Icons/ArrowNext';
import { useAuth } from '../../../context/auth';
import { getToken } from '../../../utils/auth';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const PreferencesForm = ({ job, submitValues, setSubmitValues, edit, setError }) => {
  const { currentUser } = useAuth();
  const token = getToken();
  const history = useHistory();
  const [notify, setNotify] = useState(currentUser.settings.notify_on_apply_job);

  const VALIDATIONSHEMA = Yup.object({
    cv_submit: Yup.string().oneOf(['yes', 'no', 'optional']).required()
  });

  let INITIALVALUES = {
    email_updates: currentUser.email,
    user_id: currentUser.id,
    cv_submit: 'optional'
  };

  if (job) {
    INITIALVALUES = {
      cv_submit: job.cv_submit || '',
      email_updates: job.email_updates || ''
    };
  }

  useEffect(() => {
    axios.post(`/api/profile/${currentUser.id}`, {
      notify_on_apply_job: notify === 'yes' && true,
      _method: 'put'
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(() => {
    }).catch();
  }, [notify]);

  const onDelete = () => {
    axios.delete(`/api/jobs/${job.id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(() => {
      history.push('/jobs');
    }).catch(error => setError(error.message));
  };

  const onSubmit = values => {
    setSubmitValues(state => ({
      ...state,
      ...values
    }));
    if (job) {
      axios.put(`/api/jobs/${job.id}`, submitValues, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(() => {
        history.push(`/jobs`);
      }).catch(error => setError(error.message));
    } else {
      axios.post(`/api/jobs`, submitValues, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(() => {
        history.push('/job-success');
      }).catch(error => setError(error.messaage));
    }
  };

  return (
    <Formik
      initialValues={INITIALVALUES}
      validationSchema={VALIDATIONSHEMA}
      onSubmit={onSubmit}
      onReset={onDelete}>
      {formik => (
        <Form className='w-full' >
          <div>
            <label className='block text-xl text-primary font-body mb-3' htmlFor='duration'>Applicants must submit through Peconto?</label>
            <FormControl
              control='checkbox'
              label='Yes, applicants must upload their C.V.'
              name='cv_submit'
              value='yes'
              checked={formik.values.cv_submit === 'yes' && true}
              onChange={(e) => {
                formik.setFieldValue('cv_submit', 'yes');
              }} />
            <FormControl
              control='checkbox'
              label='No, applicants will apply externally.'
              name='cv_submit'
              value='no'
              checked={formik.values.cv_submit === 'no' && true}
              onChange={(e) => {
                formik.setFieldValue('cv_submit', 'no');
              }} />
          </div>
          <div>
            <label className='block text-xl text-primary font-body mb-3' htmlFor='duration'>Receive email updates for new application?</label>
            <FormControl
              control='checkbox'
              label='Yes, send updates.'
              name='notify'
              value='1'
              checked={notify === 1 && '1'}
              onChange={(e) => {
                setNotify(1);
              }} />
            <FormControl
              control='checkbox'
              label='No, Ill login myself to review.'
              name='notify'
              value='0'
              checked={notify === 0 && '0'}
              onChange={(e) => {
                setNotify(0);
              }} />
          </div>
          {edit ? <div className="border-t w-full flex flex-row justify-between items-center bg-white py-3">
            <Button className='text-red-600 bg-transparent rounded-full hover:bg-gray-300' type='reset' >Delete</Button>
            <Button className='text-white bg-blue-7s00 rounded-full ml-auto hover:bg-blue-900' type='submit'>Update</Button>
          </div> : <div className='border-t w-full flex flex-row-reverse justify-between items-centerbg-white py-3 '>
            <Button type='submit' className='text-primary border-2  mx-auto my-16 hover:border-primary hover:font-bold'> <ArrowNext /> create request</Button>
          </div>}
        </Form>)}
    </Formik>
  );
};

export default PreferencesForm;
