import React from 'react';

const IndustryIcon = () => {
  return (
    <svg id="industry" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="container" width="24" height="24" fill="#0f4975" opacity="0"/>
      <path id="industry-2" data-name="industry" d="M22.271,8.427l-6.521,4.15v-3.2a1.126,1.126,0,0,0-1.729-.949L7.5,12.577v-9.2A1.125,1.125,0,0,0,6.375,2.25H1.125A1.125,1.125,0,0,0,0,3.375v18.75A1.125,1.125,0,0,0,1.125,23.25h21.75A1.125,1.125,0,0,0,24,22.125V9.376A1.126,1.126,0,0,0,22.271,8.427Z" transform="translate(0 -0.25)" fill="#0f4975"/>
    </svg>

  );
};

export default IndustryIcon;
