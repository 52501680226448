/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import CourseForm from '../Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import { useAuth } from '../../../context/auth';
import { getToken } from '../../../utils/auth';
import axios from 'axios';
import TextError from '../../UI/TextError';

const CourseCreate = ({fetchCourse, onClose}) => {
  const token = getToken();
  const {currentUser} = useAuth();
  const [error, setError] = useState('');
  const [currencies, setCurrencies] = useState(false);
  const [categories, setCategories] = useState(false);

  useEffect(() => {
    axios.get(`/api/categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCategories(res.data.data);
    });
    axios.get(`/api/currencies`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCurrencies(res.data.data);
    });
  }, []);

  const INITIALVALUES = {
    course_id: '',
    type: '',
    hours: '',
    price: '',
    details: '',
    capacity: '',
    fixed_amount: false,
    hourly_rate: false,
    on_site: false,
    online: false,
    category_id: '',
    sub_category_id: '',
    user_id: currentUser.id,
    currency_id: ''
  };

  const onSubmit = values => {
    delete values.category_id;
    delete values.sub_category_id;
    axios.post(`/api/instructor-courses`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(() => {
      onClose();
      fetchCourse && fetchCourse(values.price);
    }).catch(error => setError(error.messaage));
  };

  return (
    <Fragment>
      {error && <TextError>{error}</TextError>}
      {categories && currencies && <Formik
        initialValues = {INITIALVALUES}
        validationSchema = {VALIDATIONSHEMA}
        onSubmit={onSubmit}>
        {formik => (
          <CourseForm values={formik.values} currencies={currencies} categories={categories} />
        )}
      </Formik>}
    </Fragment>
  );
};

export default CourseCreate;
