/* eslint-disable indent */
import React, {useState, useEffect} from 'react';
import { Popover } from '@headlessui/react';
import Card from '../UI/Card';
import NotificationsIcon from '../UI/Icons/NotificationsIcon';
import { getToken } from '../../utils/auth';
import axios from 'axios';
import Badge from '@mui/material/Badge';

const Notifications = () => {
    const [notifications, setNotifications] = useState(false);
    const [notiCount, setNotiCount] = useState(false);
    const token = getToken();

    useEffect(() => {
      const notificationInterval = setInterval(() => {
        axios.get(`api/my-notifications`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }}).then(res => {
                setNotifications(res.data.data);
                setNotiCount(res.data.data.filter(notify => notify.seen === 0).length);
          }).catch();
      }, 5000);
        return () => clearInterval(notificationInterval);
    }, []);

    const handleClickNotifications = () => {
        let ids = [];
        notifications.map(notify => notify.seen === 0 && ids.push(notify.id));
        if (ids.length > 0) {
            axios.put(`api/seen-notifications`, {ids}, {
                headers: {
                  'Authorization': `Bearer ${token}`
                }}).then().catch();
        }
    };

    return (
        <Popover className="relative" >
            <Popover.Button onClick={handleClickNotifications} className='focus:outline-none' >
                {notiCount > 0 ? <Badge color="error" badgeContent={notiCount} max={99}>
                    <NotificationsIcon />
                </Badge> : <NotificationsIcon />}
            </Popover.Button>
            <Popover.Panel className="absolute z-10 right-0 w-300px">
                <Card className="bg-white border border-primary flex flex-col p-1">
                <h3 className='text-primary text-base my-3' >Notifications</h3>
                    {notifications && notifications.map(notification => <li key={notification.id} >
                        <div className={`flex justify-start items-center p-2 mb-2 cursor-pointer ${notification.seen === 0 && 'border-l-2 border-blue-500 hover:border-primary'}`}>
                        <img className="w-12 h-12 rounded-md" src='/images/profile.png' alt='user photo' />
                        <div className='ml-4'>
                            <h5 className='text-primary text-xs font-semibold' >{notification.message}</h5>
                            <p className='text-xs text-gray-500'>{notification.created_at}</p>
                        </div>
                    </div>
                    </li>)}
                </Card>
            </Popover.Panel>
        </Popover>
    );
};

export default Notifications;
