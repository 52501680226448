/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import Userform from '../components/Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import axios from 'axios';
import { getToken } from '../../../../utils/auth';
import TextError from '../../../../components/UI/TextError';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { editUser, getUser } from '../../../../store/reducer/userSlice';
import Card from '../../../../components/UI/Card';
import BasicBreadcrumbs from '../../../../components/UI/BasicBreadcrubs';

const EditUser = () => {
  const [countries, setCountries] = useState(false);
  const [error, setError] = useState('');
  const token = getToken();
  const dispatch = useDispatch();
  const {id: userId} = useParams();
  const {fetched} = useSelector(state => state.user);
  const history = useHistory();

  useEffect(() => {
    dispatch(getUser({id: userId}));
    axios.get(`/api/countries`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCountries(res.data.data);
    }).catch(error => setError(error.messaage));
  }, []);

  const INITIALVALUES = fetched ? {
    username: fetched.username,
    first_name: fetched.first_name,
    last_name: fetched.last_name,
    email: fetched.email,
    phone_number: fetched.phone_number,
    country_id: fetched.country ? fetched.country.id : '',
    city_id: fetched.city ? fetched.city.id : '',
    address: fetched.address,
    zip_code: fetched.zip_code,
    website: fetched.website,
    about: fetched.about ? fetched.about : '',
    _method: 'PUT'
  } : {};

  return (
    <div className='w-full bg-gray-200'>
      <div className='container p-2 mx-auto flex flex-col items-start justify-start md:w-3/5 sm:w-full'>
        {error && <TextError>{error}</TextError>}
        <BasicBreadcrumbs active='Edit' url='/admin/users' back='Users' />
        <h3 className='text-2xl font-semibold my-6'>Edit User</h3>
        <Card className='bg-white w-full'>
          {fetched && countries && <Formik
            initialValues = {INITIALVALUES}
            validationSchema = {VALIDATIONSHEMA}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              const successCallback = () => {
                setSubmitting(false);
                history.push('/admin/users');
              };
              const failedCallback = () => {
                setSubmitting(false);
              };
              dispatch(editUser({ id: userId, values, successCallback, failedCallback }));
            }}>
            {formik => (
              <Userform values={formik.values} countries={countries} />
            )}
          </Formik>}
        </Card>
      </div>
    </div>
  );
};

export default EditUser;
