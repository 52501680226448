/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import SubCategoryform from '../components/Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { editSubCategory, getSubCategory } from '../../../../store/reducer/subCategorySlice';
import Card from '../../../../components/UI/Card';
import BasicBreadcrumbs from '../../../../components/UI/BasicBreadcrubs';
import axios from 'axios';
import { getToken } from '../../../../utils/auth';
import TextError from '../../../../components/UI/TextError';

const EditSubCategory = () => {
  const dispatch = useDispatch();
  const {id: subCategoryId} = useParams();
  const {fetched} = useSelector(state => state.subCategory);
  const [categories, setCategories] = useState();
  const [error, setError] = useState();
  const history = useHistory();
  const token = getToken();

  useEffect(() => {
    dispatch(getSubCategory({id: subCategoryId}));
    axios.get(`/api/categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCategories(res.data.data);
    }).catch(error => setError(error.messaage));
  }, []);

  const INITIALVALUES = fetched ? {
    name: fetched.name,
    label: fetched.label,
    category_id: fetched.category_id,
    _method: 'PUT'
  } : {};

  return (
    <div className='w-full bg-gray-200'>
      <div className='container p-2 mx-auto flex flex-col items-start justify-start md:w-3/5 sm:w-full'>
        <BasicBreadcrumbs active='Edit' url='/admin/sub-categories' back='Sub Categories' />
        {error && <TextError>{error}</TextError>}
        <h3 className='text-2xl font-semibold my-6'>Edit Sub Category</h3>
        <Card className='bg-white w-full'>
          {fetched && categories && <Formik
            initialValues = {INITIALVALUES}
            validationSchema = {VALIDATIONSHEMA}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              const successCallback = () => {
                setSubmitting(false);
                history.push('/admin/sub-categories');
              };
              const failedCallback = () => {
                setSubmitting(false);
              };
              dispatch(editSubCategory({ id: subCategoryId, values, successCallback, failedCallback }));
            }}>
            {formik => (
              <SubCategoryform values={formik.values} categories={categories}/>
            )}
          </Formik>}
        </Card>
      </div>
    </div>
  );
};

export default EditSubCategory;
