import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Welcome from '../pages/welcome';
import Login from '../pages/auth/login';
import Register from '../pages/auth/register';
import ForgotPassword from '../pages/auth/forgot-password';
import ResetPassword from '../pages/auth/reset-password';
import NotFound from '../pages/404';
import Home from '../pages/home';
import Profile from '../pages/profile';
import AuthRoute from './auth-route';
import GuestRoute from './guest-route';
import { useAuth } from '../context/auth';
import FullPageSpinner from '../components/full-page-spinner';
import Verify from '../pages/verifiy';
import Jobs from '../pages/jobs';
import SearchResults from '../pages/searchResults';
import JobCreate from '../components/Jobs/Create';
import JobEdit from '../components/Jobs/Edit';
import JobShow from '../components/Jobs/Show';
import Dashboard from '../pages/admin';
import AdminRoute from './admin-route';
import ListUsers from '../pages/admin/User/List';
import EditUser from '../pages/admin/User/Edit';
import CreateUser from '../pages/admin/User/Create';
import CreateCompanyProfile from '../pages/createCompanyProfile';
import CreateInstructorContact from '../pages/CreateInstructorContact';
import CreateInstructorBio from '../pages/CreateInstructorBio';
import CreateInstructorExperience from '../pages/CreateInstructorExperience';
import CreateInstructorEducation from '../pages/CreateInstructorEducation';
import CreateInstructorCertificates from '../pages/CreateInstructorCertificates';
import CreateInstructorCourses from '../pages/CreateInstructorCourses';
import FieldsOfInterest from '../pages/FieldsOfInterest';
import Follow from '../pages/Follow';
import CreateInstructorProfile from '../pages/CreateInstructorProfile';
import CreatedSuccessfully from '../pages/CreatedSuccessfully';
import CreateCompanyContact from '../pages/CreateCompanyContact';
import HelpCenter from '../pages/helpCenter';
import ContactUs from '../pages/ContactUs';
import JobSuccessfully from '../pages/JobSuccessfully';
import EditCategory from '../pages/admin/Category/Edit';
import CreateCategory from '../pages/admin/Category/Create';
import ListCategories from '../pages/admin/Category/List';
import EditSubCategory from '../pages/admin/SubCategory/Edit';
import CreateSubCategory from '../pages/admin/SubCategory/Create';
import ListSubCategories from '../pages/admin/SubCategory/List';
import EditPrivacy from '../pages/admin/Cms/EditPrivacy';
import EditTerms from '../pages/admin/Cms/EditTerms';
import AdEmail from "../pages/admin/Cms/AdEmail";

function App () {
  let { initializing } = useAuth();
  return (
    initializing
      ? <FullPageSpinner />
      : <Router>
        <div className="flex flex-col">
          <Switch >
            <GuestRoute exact path="/" component={Welcome} title="welcome" />
            <GuestRoute path="/register/:role" component={Register} title="register" />
            <GuestRoute path="/login" component={Login} title="login"/>
            <GuestRoute path="/verify" component={Verify} title="verify"/>
            <GuestRoute path="/forgot-password" component={ForgotPassword} title="forgot password"/>
            <GuestRoute path="/password/reset/:token" component={ResetPassword} title="reset password"/>
            <AuthRoute path='/job/show/:id' component={JobShow} title="job"/>
            <AuthRoute path="/job/:id" component={JobEdit} title="Edit Job"/>
            <AuthRoute path="/job" component={JobCreate} title="Create Job" noNavbar={true}/>
            <AuthRoute path="/job-success" component={JobSuccessfully} title="Job Created Successfully" noNavbar={true}/>
            <AuthRoute path="/home" component={Home} title="home"/>
            <AuthRoute path="/profile/:username" component={Profile} title="profile"/>
            <AuthRoute path="/jobs/:name" component={Jobs} title="jobs"/>

            <AuthRoute path="/jobs" component={Jobs} title="jobs" />
            <AuthRoute path="/fields-of-interest" component={FieldsOfInterest} title="Create Company Profile" noNavbar={true} />
            <AuthRoute path="/follow" component={Follow} title="Follow" noNavbar={true} />
            <AuthRoute path="/help-center" component={HelpCenter} title="Help Center"/>
            <AuthRoute path="/contact-us" component={ContactUs} title="Contact Support" noNavbar={true}/>
            <AuthRoute path="/created-successfully" component={CreatedSuccessfully} title="Created Successfully" noNavbar={true} />
            <AuthRoute path="/create-company-profile" component={CreateCompanyProfile} title="Create Company Profile" noNavbar={true} />
            <AuthRoute path="/create-company-contact" component={CreateCompanyContact} title="Create Company Contact" noNavbar={true} />
            <AuthRoute path="/create-instructor-profile" component={CreateInstructorProfile} title="Create Instructor Profile" noNavbar={true} />
            <AuthRoute path="/create-instructor-bio" component={CreateInstructorBio} title="Create Instructor Bio" noNavbar={true}/>
            <AuthRoute path="/create-instructor-contact" component={CreateInstructorContact} title="Create Instructor Profile" noNavbar={true}/>
            <AuthRoute path="/create-instructor-experience" component={CreateInstructorExperience} title="Create Instructor Experience" noNavbar={true}/>
            <AuthRoute path="/create-instructor-education" component={CreateInstructorEducation} title="Create Instructor Education" noNavbar={true}/>
            <AuthRoute path="/create-instructor-certificates" component={CreateInstructorCertificates} title="Create Instructor Certificates" noNavbar={true}/>
            <AuthRoute path="/create-instructor-courses" component={CreateInstructorCourses} title="Create Instructor Courses" noNavbar={true}/>
            <AuthRoute path="/search/:searchFor/:name" component={SearchResults} title="Search"/>

            {/* Dashboard */}
            <AdminRoute path='/admin/users/:id' component={EditUser} title='User Edit'/>
            <AdminRoute path='/admin/users' component={ListUsers} title='Users'/>
            <AdminRoute path='/admin/user' component={CreateUser} title='User Create'/>

            <AdminRoute path='/admin/categories/:id' component={EditCategory} title='Category Edit'/>
            <AdminRoute path='/admin/categories' component={ListCategories} title='Categories'/>
            <AdminRoute path='/admin/category' component={CreateCategory} title='Category Create'/>

            <AdminRoute path='/admin/sub-categories/:id' component={EditSubCategory} title='Sub Category Edit'/>
            <AdminRoute path='/admin/sub-categories' component={ListSubCategories} title='Sub Categories'/>
            <AdminRoute path='/admin/sub-category' component={CreateSubCategory} title='Sub Category Create'/>

            <AdminRoute path='/admin/privacy' component={EditPrivacy} title='Edit Privacy'/>
            <AdminRoute path='/admin/terms' component={EditTerms} title='Edit Terms'/>
            <AdminRoute path='/admin/ad-email' component={AdEmail} title='Ad Email'/>
            <AdminRoute path='/admin' component={Dashboard} title='Dashboard'/>
            <Route component={NotFound}/>
          </Switch >
        </div>
      </Router>
  );
};

export default App;
