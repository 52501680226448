/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { VALIDATIONSHEMA } from '../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { editPrivacy, getPrivacy } from '../../../../store/reducer/privacySlice';
import Card from '../../../../components/UI/Card';
import BasicBreadcrumbs from '../../../../components/UI/BasicBreadcrubs';
import PrivacyForm from '../components/Form/PrivacyForm';
import SimpleSnackbar from '../../../../components/UI/Snackbar';

const EditPrivacy = () => {
  const dispatch = useDispatch();
  const {fetched} = useSelector(state => state.privacy);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  useEffect(() => {
    dispatch(getPrivacy({id: '1'}));
  }, []);

  const INITIALVALUES = fetched ? {
    title: fetched.title,
    description: fetched.description,
    _method: 'PUT'
  } : {};

  return (
    <div className='w-full bg-gray-200'>
      <div className='container p-2 mx-auto flex flex-col items-start justify-start md:w-3/5 sm:w-full'>
        <BasicBreadcrumbs active='Edit' url='/admin/cms/privacy/1' back='CMS' />
        <h3 className='text-2xl font-semibold my-6'>Edit Privacy Policy</h3>
        <Card className='bg-white w-full'>
          {fetched && <Formik
            initialValues = {INITIALVALUES}
            validationSchema = {VALIDATIONSHEMA}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              const successCallback = () => {
                setSubmitting(false);
                dispatch(getPrivacy({id: '1'}));
                setOpenSnackBar(true);
              };
              const failedCallback = () => {
                setSubmitting(false);
              };
              dispatch(editPrivacy({ id: '1', values, successCallback, failedCallback }));
            }}>
            {formik => (
              <PrivacyForm fetched={fetched} values={formik.values} />
            )}
          </Formik>}
        </Card>
      </div>
      {openSnackBar && <SimpleSnackbar message='Updated successfully' open={openSnackBar} handleClose={() => setOpenSnackBar(false)} />}
    </div>
  );
};

export default EditPrivacy;
