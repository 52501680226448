/* eslint-disable react/prop-types */
import React from 'react';
import {Form} from 'formik';
import FormControl from '../../UI/FormControl';
import Button from '../../UI/Button';
import ArrowNext from '../../UI/Icons/ArrowNext';

const BioForm = ({subCategories}) => {
  return (
    <Form className="w-full">
      <FormControl control='textarea' label='About yourself...' name='about' />
      <FormControl control='select' label='Specialties...' name='specialty' options={subCategories} />

      <div className="w-full flex flex-row justify-between items-center py-3">
        <Button className='text-primary border-2  mx-auto hover:border-primary hover:font-bold' type='submit'> <ArrowNext/> Next</Button>
      </div>
    </Form>
  );
};

export default BioForm;
