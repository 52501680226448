import React, {useEffect, useState} from 'react';
import { getToken } from '../utils/auth';
import axios from 'axios';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import LocationIcon from '../components/UI/Icons/LocationIcon';
import IndustryIcon from '../components/UI/Icons/IndustryIcon';
import FollowIcon from '../components/UI/Icons/FollowIcon';
import UnFollowIcon from '../components/UI/Icons/UnFollowIcon';
import Button from '../components/UI/Button';

const SearchResults = () => {
  const [results, setResults] = useState([]);
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [fetch, setFetch] = useState([]);

  const [location, setLocation] = useState('');
  const [industry, setIndustry] = useState('');

  const token = getToken();
  const history = useHistory();
  const { name, searchFor } = useParams();

  useEffect(() => {
    axios.get(`/api/search/${searchFor}/?name=${name}&location=${location}&industry=${industry}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setResults(res.data.data);
    });
  }, [fetch, searchFor, name, industry, location]);

  useEffect(() => {
    axios.get(`/api/categories`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCategories(res.data.data);
    });
    axios.get(`/api/countries`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCountries(res.data.data);
    });
  }, []);

  const handleClickResult = (username) => {
    history.push(`/profile/${username}`);
  };

  const handleFollow = (id) => {
    axios.post(`/api/user/follow`, {user_id: id}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      setFetch(state => !state);
    });
  };

  return (
    <div className='w-full bg-primary_bg'>
      <div className='container p-2 mx-auto flex flex-row items-start justify-center w-full'>
        <div className='w-1/5 mx-auto flex flex-col justify-start items-start gap-3' >
                  Search filters...

          <div className="w-full flex justify-start items-center border border-gray-300 rounded-md py-2 px-4 bg-white">
            <IndustryIcon/>
            <select onChange={(e) => setIndustry(e.target.value)} className='w-full py-1 px-3 text-primary font-base focus:outline-none' id='industry' name='industry' placeholder='Industry' >
              <option value='' defaultValue >Industry... </option>
              {categories.map(option => (<option key={option.id} value={option.name}>{option.label}</option>))}
            </select>
          </div>
          <div className="w-full flex justify-start items-center border border-gray-300 rounded-md py-2 px-4 bg-white">
            <LocationIcon/>
            <select onChange={(e) => setLocation(e.target.value)} className='w-full py-1 px-3 text-primary font-base focus:outline-none' as='select' id='location' name='location' >
              <option value='' defaultValue >Location... </option>
              {countries.map(option => (<option key={option.id} value={option.name}>{option.label}</option>))}
            </select>
          </div>
        </div>
        <div className="w-3/5 mx-auto">
          <h2 className='pb-4 border-b-2 border-gray-200'> ({results && results.length}) Search results for &quot;{name}&quot;</h2>

          <div className='grid grid-cols-1 md:grid-cols-3  gap-4' >
            {results && results.map(user => (
              <div key={user.id} className='flex flex-col justify-center items-center gap-2 p-8 bg-white border border-gray-400 rounded-md'>
                <img onClick={() => handleClickResult(user.username)} className="h-16 w-16 rounded-lg border border-gray-400  cursor-pointer" src={user.photo} alt='user photo' />
                <h3 onClick={() => handleClickResult(user.username)} className='text-primary text-lg hover:underline cursor-pointer'>{user.role === 'user' ? user.company_details ? user.company_details.name : '' : user.full_name}</h3>
                <h3 className='text-sm text-gray-500'>{user.role === 'user' ? user.company_details ? user.company_details.category.label : '' : 'Help Desk Analyst' }</h3>
                <p className="text-xs text-gray-500">{user.country && user.country.label}</p>
                { user.follow ? <Button onClick={() => handleFollow(user.id)} className='border border-gray-400 text-white bg-secondary p-2 '>
                  <UnFollowIcon/>
                                  Following
                </Button> : <Button onClick={() => handleFollow(user.id)} className='border border-gray-400 text-primary p-2 '>
                  <FollowIcon />
                                  Follow
                </Button>}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
