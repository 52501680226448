/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import DetailsFrom from './DetailsFrom';
import SpecificsForm from './SpecificsForm';
import DurationForm from './DurationForm';
import PaymentForm from './PaymentForm';
import PreferencesForm from './PreferencesForm';
import { useSelector, useDispatch } from 'react-redux';
import { getTerms } from '../../../store/reducer/termsSlice';

const JobForm = ({job, edit, countries, categories, currencies, setError}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [submitValues, setSubmitValues] = useState({});
  const { fetched } = useSelector(state => state.terms);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTerms({ id: '2' }));
  }, []);

  return (
    countries && categories && currencies &&
        <div className="w-full mx-auto">

          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex} >
            <Tab.List className='flex justify-center items-center flex-wrap gap-6 w-full border-t border-b border-gray-400 mb-4'>
              <Tab
                className={({ selected }) => selected
                  ? 'text-secondary font-semibold border-b-4 border-secondary text-sm uppercase py-4 px-2 focus:outline-none'
                  : 'text-primary cursor-pointer text-sm uppercase py-4 px-2 focus:outline-none'
                }>
      terms of use
              </Tab>
              <Tab
                className={({ selected }) => selected
                  ? 'text-secondary font-semibold border-b-4 border-secondary text-sm uppercase py-4 px-2 focus:outline-none'
                  : 'text-primary cursor-pointer text-sm uppercase py-4 px-2 focus:outline-none'
                }>
      details
              </Tab>
              <Tab
                className={({ selected }) => selected
                  ? 'text-secondary font-semibold border-b-4 border-secondary text-sm uppercase py-4 px-2 focus:outline-none'
                  : 'text-primary cursor-pointer text-sm uppercase py-4 px-2 focus:outline-none'
                }>
      Specifics
              </Tab>
              <Tab
                className={({ selected }) => selected
                  ? 'text-secondary font-semibold border-b-4 border-secondary text-sm uppercase py-4 px-2 focus:outline-none'
                  : 'text-primary cursor-pointer text-sm uppercase py-4 px-2 focus:outline-none'
                }>
      Duration
              </Tab>
              <Tab
                className={({ selected }) => selected
                  ? 'text-secondary font-semibold border-b-4 border-secondary text-sm uppercase py-4 px-2 focus:outline-none'
                  : 'text-primary cursor-pointer text-sm uppercase py-4 px-2 focus:outline-none'
                }>
      Payment
              </Tab>
              <Tab
                className={({ selected }) => selected
                  ? 'text-secondary font-semibold border-b-4 border-secondary text-sm uppercase py-4 px-2 focus:outline-none'
                  : 'text-primary cursor-pointer text-sm uppercase py-4 px-2 focus:outline-none'
                }>
      Preferences
              </Tab>
            </Tab.List>
            <Tab.Panels className='w-full md:w-4/5 mx-auto' >
              <Tab.Panel>
                <div>
                  {fetched ? <p className='text-primary opacity-75' dangerouslySetInnerHTML={{__html: fetched.description}} >
                  </p> : <p className='text-primary opacity-75 text-center'>Loading....</p>}
                </div>
                <div className='border-t sticky w-full flex flex-row-reverse justify-between items-center bottom-0 bg-white'>
                  <button type='button' onClick={() => setSelectedIndex(index => ++index)} className='flex justify-center items-center sticky bottom-1 bg-primary text-white font-semibold rounded-lg border-2  mx-auto my-4 py-4 px-8 hover:bg-secondary hover:font-bold'>I Accept the terms of use</button>
                </div>
              </Tab.Panel>
              <Tab.Panel className='w-full' >
                <DetailsFrom job={job && job} setSelectedIndex={setSelectedIndex} setSubmitValues={setSubmitValues} />
              </Tab.Panel>
              <Tab.Panel>
                <SpecificsForm job={job && job} countries={countries} categories={categories} setSelectedIndex={setSelectedIndex} setSubmitValues={setSubmitValues} />
              </Tab.Panel>
              <Tab.Panel>
                <DurationForm job={job && job} setSelectedIndex={setSelectedIndex} setSubmitValues={setSubmitValues}/>
              </Tab.Panel>
              <Tab.Panel>
                <PaymentForm job={job && job} currencies={currencies} setSelectedIndex={setSelectedIndex} setSubmitValues={setSubmitValues}/>
              </Tab.Panel>
              <Tab.Panel>
                <PreferencesForm edit={edit} job={job && job} submitValues={submitValues} setError={setError} setSubmitValues={setSubmitValues}/>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>

        </div>
  );
};

export default JobForm;
