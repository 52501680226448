/* eslint-disable react/prop-types */
import React from 'react';

const ArrowNext = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><g transform="translate(2 2)"><g fill="#24a0dc" stroke="#fff" strokeWidth="2" opacity="0"><rect width="24" height="24" stroke="none" /><rect x="-1" y="-1" width="26" height="26" fill="none" /></g><path d="M10.251,1.867a.965.965,0,0,1,0-1.34.906.906,0,0,1,1.305,0L16.48,5.58a.965.965,0,0,1,0,1.34l-4.923,5.053a.906.906,0,0,1-1.305,0,.965.965,0,0,1,0-1.34L13.6,7.2H1.673a.948.948,0,0,1,0-1.895H13.6Z" transform="translate(3.25 5.75)" fill={fill }/></g></svg>
  );
};

export default ArrowNext;
