/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {AiOutlinePlus} from 'react-icons/ai';
import Modal from '../UI/Modal';
import Card from '../UI/Card';
import EducationCreate from './Create';
import EducationEdit from './Edit';
import {getToken} from '../../utils/auth.js';
import axios from 'axios';
import { useAuth } from '../../context/auth';
import EditIcon from '../UI/Icons/EditIcon';

const Education = ({educations, userId}) => {
  const token = getToken();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userEdu, setUserEdu] = useState(educations);
  const [fetch, setFetch] = useState(false);
  const [eduId, setEduId] = useState(null);
  const {currentUser} = useAuth();

  const isCurrentUser = userId === currentUser.id;

  const handleFetch = (id) => setFetch(id);
  const handleCloseEdit = () => setShowEditModal(false);
  const handleCloseCreate = () => setShowCreateModal(false);

  useEffect(() => {
    if (eduId !== null) {
      setShowEditModal(true);
    }
  }, [eduId]);

  useEffect(() => {
    if (fetch) {
      axios.get(`/api/educations`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(res => {
        setUserEdu(res.data.data);
      });
    }
  }, [fetch]);
  return (
    <Card className='w-full h-auto flex flex-col justify-start items-start shadow-none rounded-lg bg-white border border-gray-400 relative p-5 my-5'>
      <div className="flex w-full h-auto flex-row justify-between items-center">
        <h4 className='text-xl text-primary font-body'>Education</h4>
        {isCurrentUser && <button onClick={() => setShowCreateModal(true)} className="ml-2 p-3 hover:bg-gray-200 rounded-full">
          <AiOutlinePlus className="text-primary w-5 h-5"/>
        </button>}
      </div>
      {userEdu.map(edu => (
        <div key={edu.id} className="flex flex-row justify-between items-start my-2 w-full">
          <div className="flex flex-col justify-start items-start">
            <h6 className="text-md text-primary">{edu.degree}</h6>
            <div className="flex flex-row justify-start items-center text-sm text-gray-500 gap-x-1">
              <p>{edu.school} -- </p> <p> From {edu.start_year}</p> {edu.end_year ? <p> To {edu.end_year}</p> : <p>Current</p>}
            </div>
          </div>
          {isCurrentUser && <button onClick={() => setEduId(edu.id)} className="ml-2 p-3 hover:bg-gray-200 rounded-full">
            <EditIcon className="w-5 h-5"/>
          </button>}
        </div>
      ))}
      {showCreateModal && <Modal title="Create Education" onClose={handleCloseCreate}><EducationCreate fetchEdu={handleFetch} onClose={handleCloseCreate} /></Modal>}
      {showEditModal && <Modal title="Edit Education" onClose={handleCloseEdit}><EducationEdit setEduId={setEduId} fetchEdu={handleFetch} onClose={handleCloseEdit} eduId={eduId} /></Modal>}
    </Card>
  );
};

export default Education;
