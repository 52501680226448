/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import {AiOutlineClose} from 'react-icons/ai';

const Modal = ({title, onClose, children}) => {
  return (
    <Fragment>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative  md:w-1/3 sm:w-2/3 my-6 mx-auto max-w-3xl h-auto">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" style={{maxHeight: '90vh', overflow: 'scroll'}}>
            <div className="flex items-start justify-between p-5 rounded-t sticky top-0 bg-white z-30">
              {title && <h3 className="text-3xl text-primary font-body">
                {title}
              </h3>}
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={onClose}
              >
                <span className="bg-transparent text-primary opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  <AiOutlineClose/>
                </span>
              </button>
            </div>
            {/* body */}
            <div className="relative px-16 pt-4 pb-0 flex-auto">
              {children}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-primary"></div>
    </Fragment>
  );
};

export default Modal;
