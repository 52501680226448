import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '../components/UI/Button';
import ArrowNext from '../components/UI/Icons/ArrowNext';

const CreateInstructorProfile = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push('/create-instructor-contact');
  };

  return (
    <div className="flex justify-center w-full h-screen bg-primary_bg">
      <div className="relative bg-white w-800px  px-8 h-screen flex flex-col justify-center items-center">

        <img src="/images/logo.png" alt="Peconto" className='mb-10 w-20 md:w-24' />
        <div className='flex flex-col justify-center items-center w-5/7 mb-10'>
          <h1 className='text-2xl md:text-4xl text-primary font-body font-semibold capitalize' >Complete your profile</h1>
          <p className='text-sm md:text-lg text-primary font-normal opacity-75 text-center w-2/3 md:w-4/5' >We recommend adding your info and experience to your profile so companies can find you and approach you easily.</p>
        </div>

        <div className='flex flex-col justify-start items-center my-16 w-full'>
          <Button className='text-primary border-2 w-5/7 md:w-1/2 mb-4 hover:border-primary hover:font-bold' onClick={handleClick}> <ArrowNext /> Add info</Button>
          <Link to='/home' className='text-secondary text-sm p-3 hover:text-primary'>
                      Skip For Now
          </Link>
        </div>

      </div>
    </div>
  );
};

export default CreateInstructorProfile;
