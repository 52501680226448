import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { VALIDATIONSHEMA } from '../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { editTerms, getTerms } from '../../../../store/reducer/termsSlice';
import Card from '../../../../components/UI/Card';
import BasicBreadcrumbs from '../../../../components/UI/BasicBreadcrubs';
import TermsForm from '../components/Form/TermsForm';
import SimpleSnackbar from '../../../../components/UI/Snackbar';
import {Autocomplete, createFilterOptions, TextField} from "@mui/material";
import Button from "@mui/material/Button";

const filter = createFilterOptions();
const AdEmail = () => {
  const dispatch = useDispatch();
  const {fetched} = useSelector(state => state.terms);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [value, setValue] = React.useState([]);
  const top100Films = []

  const submitEmails = () => {
      const authToken = localStorage.getItem('auth_token')
      console.log(value);
      fetch('/api/send-ad_emails', {
          method: 'POST',
          body: JSON.stringify({
              emails: value
          }),
          headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${authToken}`
          },
      })
      .then(res => {
          setOpenSnackBar(true)
      })
      .catch(err => {
          console.log(err)
      })
  }
  return (
    <div className='w-full bg-gray-200'>
      <div className='container p-2 mx-auto flex flex-col items-start justify-start md:w-3/5 sm:w-full'>
        <BasicBreadcrumbs active='Ad Email' />
        <h3 className='text-2xl font-semibold my-6'>Ad Emails</h3>
        <Card className='bg-white w-full'>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setValue({
                            title: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue({
                            title: newValue.inputValue,
                        });
                    } else {
                        setValue(newValue);
                    }
                }}
                // filterOptions={(options, params) => {
                //     const filtered = filter(options, params);
                //
                //     const { inputValue } = params;
                //     // Suggest the creation of a new value
                //     const isExisting = options.some((option) => inputValue === option);
                //     if (inputValue !== '' && !isExisting) {
                //         filtered.push({
                //             inputValue,
                //             title: `Add "${inputValue}"`,
                //         });
                //     }
                //
                //     return filtered;
                // }}
                multiple
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="ad-emails-input"
                options={top100Films}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option;
                }}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                sx={{ width: 500 }}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} label="Enter Emails" />
                )}
            />
            <Button variant="contained" style={{margiLeft: "10px"}} onClick={() => submitEmails()}>Send Emails</Button>
        </Card>
      </div>
      {openSnackBar && <SimpleSnackbar message='Updated successfully' open={openSnackBar} handleClose={() => setOpenSnackBar(false)} />}
    </div>
  );
};

export default AdEmail;
