/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {Form} from 'formik';
import FormControl from '../../../../../components/UI/FormControl';
import Button from '../../../../../components/UI/Button';
import { getToken } from '../../../../../utils/auth';
import axios from 'axios';

const UserForm = ({countries, values, edit}) => {
  const token = getToken();
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (values.country_id !== '') {
      axios.get(`/api/countries/${parseInt(values.country_id)}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(res => {
        setCities(res.data.data.cities.data);
      });
    }
  }, [values.country_id]);

  return (
    <Form className="w-full">
      <FormControl control='input' type='text' label='First name' name='first_name' />
      <FormControl control='input' type='text' label='Last name' name='last_name' />
      <FormControl control='input' type='email' label='Email' name='email' />
      <FormControl control='input' type='text' label='Username' name='username' />
      <FormControl control='input' type='password' label='Password' name='password' />
      <FormControl control='input' type='password' label='Confirm Password' name='confirm_password' />
      <FormControl control='input' type='text' label='Phone number' name='phone_number' />
      <FormControl control='select' label='Country' name='country_id' options={countries}/>
      <FormControl control='select' label='City' name='city_id' options={cities}/>
      <FormControl control='input' type='text' label='Address' name='address' />
      <FormControl control='input' type='text' label='Zip code' name='zip_code' />
      <FormControl control='textarea' type='text' label='About' name='about'/>
      <div className="border-t sticky w-full flex flex-row justify-between items-center bottom-0 bg-white py-3">
        <Button className='text-white bg-blue-700 rounded-full ml-auto hover:bg-blue-900' type='submit'>Submit</Button>
      </div>
    </Form>
  );
};

export default UserForm;
