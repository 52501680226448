import React from 'react';

const DegreeIcon = () => {
  return (
    <svg id="degree" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="container" width="24" height="24" fill="none"/>
      <path id="Path_93" data-name="Path 93" d="M13.647,3.308a1.516,1.516,0,0,0-.83,0c-.579.165-1.163.317-1.749.47-2.616.683-5.5,1.435-7.961,3.446l-1.162.948a1.957,1.957,0,0,0,0,2.992l1.143.931A13.407,13.407,0,0,0,5.715,13.76v5.167a3.07,3.07,0,0,0,1.955,2.86l4.465,1.741a3.07,3.07,0,0,0,2.23,0l4.465-1.741a3.07,3.07,0,0,0,1.955-2.86V13.769a13.3,13.3,0,0,0,2.609-1.656l.182-.148v5.518a.837.837,0,1,0,1.674,0V9.669a1.919,1.919,0,0,0-.7-1.5l-1.143-.931c-2.482-2.022-5.484-2.8-8.019-3.466C14.807,3.624,14.224,3.472,13.647,3.308ZM9.119,13.924a.837.837,0,0,0-.668,1.536,29.4,29.4,0,0,0,4.416,1.516,1.614,1.614,0,0,0,.8,0,29.219,29.219,0,0,0,4.4-1.516.837.837,0,1,0-.67-1.535,27.543,27.543,0,0,1-4.136,1.426A27.715,27.715,0,0,1,9.119,13.924Z" transform="translate(-1.25 -1.25)" fill="#0f4975" fillRule="evenodd"/>
    </svg>

  );
};

export default DegreeIcon;
