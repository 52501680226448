/* eslint-disable react/prop-types */
import React from 'react';
import {Form, Field} from 'formik';
import FormControl from '../../../../../components/UI/FormControl';
import Button from '../../../../../components/UI/Button';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const PrivacyForm = ({ values, edit }) => {
  return (
    <Form className="w-full">
      <FormControl control='input' type='text' label='Title' name='title' />
      <Field name='description'>
        {({field}) => <ReactQuill theme='snow' value={field.value} onChange={field.onChange(field.name)} />}
      </Field>
      <div className="border-t sticky w-full flex flex-row justify-between items-center bottom-0 bg-white py-3">
        <Button className='text-white bg-blue-700 rounded-full ml-auto hover:bg-blue-900' type='submit'>Submit</Button>
      </div>
    </Form>
  );
};

export default PrivacyForm;
