import React from 'react';
import { Link } from 'react-router-dom';

const JobSuccessfully = () => {
  return (
    <div className="flex justify-center w-full h-screen bg-primary_bg">
      <div className="relative bg-white w-800px  px-8 h-screen flex flex-col justify-center items-center">

        <img src="/images/checkmark.gif" alt="success" className='mb-10 w-20 md:w-40 mt-32' />
        <div className='flex flex-col justify-center items-center w-5/7'>
          <h1 className='text-2xl md:text-4xl text-primary font-body font-semibold capitalize' >Job Posted Successfully!</h1>
          <p className='text-sm md:text-lg text-primary font-normal opacity-75 text-center w-2/3 md:w-4/5' >Your job was posted and is now viewable by instructors.
You can mange or edit this job from your profile.</p>
        </div>

        <div className='flex flex-col justify-start items-center w-full gap-4 mt-8'>
          <Link to='/jobs' className='font-semibold flex justify-center items-center w-1/2 py-4 bg-primary text-white focus:outline-none rounded-lg hover:bg-secondary uppercase cursor-pointer' >Manage my jobs</Link>
          <Link to='/home' className='font-semibold flex justify-center items-center w-1/2 py-4  text-primary border border-primary focus:outline-none rounded-lg hover:font-bold cursor-pointer uppercase' >home page</Link>
        </div>

      </div>
    </div>
  );
};

export default JobSuccessfully;
