/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import ContactInfoForm from '../Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import TextError from '../../UI/TextError';

const ContactInfoEdit = ({onClose, userId, fetch, fetchInfo, setUserId}) => {
  const [user, setUser] = useState(false);
  const [error, setError] = useState('');
  const [countries, setCountries] = useState(false);
  const token = getToken();

  const INITIALVALUES = {
    phone_number: user.phone_number || '',
    website: user.website || '',
    email: user.email || '',
    address: user.address || '',
    country_id: user.country ? user.country.id : '',
    city_id: user.city ? user.city.id : '',
    _method: 'PUT'
  };

  useEffect(() => {
    axios.get(`/api/profile/${userId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setUser(res.data.data);
    }).catch(error => setError(error.messaage));
    axios.get(`/api/countries`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCountries(res.data.data);
    }).catch(error => setError(error.messaage));
    return () => {
      setUserId(null);
      fetchInfo(null);
    };
  }, [fetch]);

  const onSubmit = values => {
    axios.post(`/api/profile/${userId}`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
      onClose();
      fetchInfo(userId);
    }).catch(error => setError(error.message));
  };
  return (
    <Fragment>
      {error && <TextError>{error}</TextError>}
      {user && <Formik
        initialValues = {INITIALVALUES}
        validationSchema = {VALIDATIONSHEMA}
        onSubmit={onSubmit}>
        {formik => (
          <ContactInfoForm values={formik.values} countries={countries} />
        )}
      </Formik>}
    </Fragment>
  );
};

export default ContactInfoEdit;
