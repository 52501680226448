import React from 'react';

const WebsiteIcon = () => {
  return (
    <svg id="website" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="container" width="24" height="24" fill="#0f4975" opacity="0"/>
      <path id="Path_63" data-name="Path 63" d="M18.778,3.287a29.777,29.777,0,0,0-10.254,0A3.1,3.1,0,0,0,6.053,5.782,49.847,49.847,0,0,0,5.87,23.652l.414,2.409a.9.9,0,0,0,1.519.52L12.8,21.629a1.2,1.2,0,0,1,1.71,0L19.5,26.581a.9.9,0,0,0,1.519-.52l.414-2.409A49.847,49.847,0,0,0,21.25,5.782,3.1,3.1,0,0,0,18.778,3.287Z" transform="translate(-1.151 -2.842)" fill="#0f4975"/>
    </svg>

  );
};

export default WebsiteIcon;
