/* eslint-disable react/prop-types */
import { ErrorMessage, Field } from 'formik';
import React from 'react';
import TextError from '../TextError';

const Textarea = ({label, name, ...restProps}) => {
  return (
    <div className="w-full mb-5">
      <Field className='w-full h-32 border border-gray-300 rounded-md p-2' as='textarea' cols="100" id={name} name={name} placeholder={label} {...restProps}/>
      <ErrorMessage name={name} component={TextError}/>
    </div>
  );
};

export default Textarea;
