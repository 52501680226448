import React from 'react';

const RoleIcon = () => {
  return (
    <svg id="role" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="container" width="24" height="24" fill="#0f4975" opacity="0"/>
      <g id="Group_108" data-name="Group 108" transform="translate(-2.25 -2.25)">
        <path id="Path_58" data-name="Path 58" d="M16.558,2.558a.308.308,0,0,0-.308-.308H7.635A3.385,3.385,0,0,0,4.25,5.635V22.865A3.385,3.385,0,0,0,7.635,26.25H19.942a3.385,3.385,0,0,0,3.385-3.385V10.739a.308.308,0,0,0-.308-.308H17.481a.923.923,0,0,1-.923-.923Zm-2.769,9.231A2.462,2.462,0,1,0,16.25,14.25,2.461,2.461,0,0,0,13.788,11.788ZM8.865,22.25a3.692,3.692,0,0,1,3.692-3.692h2.462a3.692,3.692,0,0,1,3.692,3.692,1.231,1.231,0,0,1-1.231,1.231H10.1A1.231,1.231,0,0,1,8.865,22.25Z" transform="translate(0)" fill="#0f4975" fillRule="evenodd"/>
        <path id="Path_59" data-name="Path 59" d="M15.75,2.878a.259.259,0,0,1,.414-.229,2.152,2.152,0,0,1,.4.42l3.709,5.167a.173.173,0,0,1-.152.27H16.058A.308.308,0,0,1,15.75,8.2Z" transform="translate(2.654 0.079)" fill="#0f4975"/>
      </g>
    </svg>

  );
};

export default RoleIcon;
