import React, {useState} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ArrowBack from '../components/UI/Icons/ArrowBack';

function Verify () {
  const { role: userRole } = useParams();
  const [withEmail, setWithEmail] = useState(false);
  const history = useHistory();

  const back = () => {
    withEmail ? setWithEmail(false) : history.push('/');
  };

  return (
    <div className="flex justify-center w-full h-screen bg-primary_bg">
      <div className="relative bg-white w-800px  px-8 h-full flex flex-col justify-start items-center">
        <div className='absolute top-0 left-0 m-6'>
          <button onClick={back} className='text-primary flex justify-start items-center p-3 hover:text-link_primary'>
            <ArrowBack fill="#0F4975" />
                      Back
          </button>
        </div>
        <img src="/images/logo.png" alt="Peconto" className='mt-20 w-24' />

        <div className='flex flex-col justify-center items-center  my-8 md:my-16 w-5/7'>
          <h1 className='text-2xl md:text-4xl text-primary font-body font-semibold capitalize' >Start with Peconto</h1>
          <p className='text-sm md:text-lg text-primary font-normal opacity-75 text-center' >Create an {userRole} account with Peconto.</p>
        </div>

        <div className='flex flex-col justify-center items-center mb:10 md:mb-16 w-56 h-56'>
          <img src="/images/checkmark.gif" alt="done" />
        </div>
        <div className='flex flex-col justify-center items-center mb-16'>
          <p className='text-sm md:text-base text-gray-500 font-normal text-center' >Registration successful!</p>
          <p className='text-sm md:text-base text-gray-500 font-normal text-center' >Check your email for a verification message.</p>
        </div>

        <div className='absolute bottom-0 text-xs text-center mx-24 mb-3 text-gray-500'>
        Didn&apos;t receive any mail from Peconto?
          <Link to='/' className='text-link_primary mx-1 font-semibold'>
            RESEND
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Verify;
