/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import {MdOutlineModeEdit} from 'react-icons/md';
import {AiOutlineClose} from 'react-icons/ai';
import Modal from '../UI/Modal';
import ContactInfoEdit from './Edit';
import LocationIcon from '../UI/Icons/LocationIcon';
import EnvelopIcon from '../UI/Icons/EnvelopIcon';
import PhoneIcon from '../UI/Icons/PhoneIcon';
import WebsiteIcon from '../UI/Icons/WebsiteIcon';
import FollowIcon from '../UI/Icons/FollowIcon';

const ContactInfo = ({userInfo, onClose, fetch, fetchInfo, setUserId, isCurrentUser}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const handleShowEditModal = () => {
    setShowEditModal(true);
  };
  return (
    <Fragment>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative md:w-1/3 sm:w-2/3 my-6 mx-auto max-w-3xl h-auto">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 rounded-t sticky top-0 bg-white z-30">
              <div className="flex flex-row justify-between items-center">
                <div className='w-full border border-gray-300 rounded-md p-3 flex justify-between items-center'>
                  <div className='flex justify-start items-center mr-5 md:mr-10 w-24 h-12 md:h-16' >
                    <img src={userInfo.photo} alt="company" className=' h-full object-contain p-1 border border-gray-300 rounded-lg' />
                  </div>
                  <div className='flex flex-col justify-center items-start w-full' >
                    <h5 className='text-primary text-sm md:text-base mb-1 md:mb-2'>{userInfo.role !== 'user' ? userInfo.full_name : userInfo.company_details ? userInfo.company_details.name : userInfo.full_name}</h5>
                    <p className='text-gray-500 text-xs'>{userInfo.role === 'user' ? userInfo.company_details && userInfo.company_details.category && userInfo.company_details.category.label : ''} -- {userInfo.country && userInfo.country.label}</p>
                  </div>
                  {!isCurrentUser && <div className='mx-2 md:mx-4 cursor-pointer' >
                    <FollowIcon/>
                  </div>}
                </div>
                {isCurrentUser && <button onClick={handleShowEditModal} className="ml-2 p-3 hover:bg-gray-200 rounded-full">
                  <MdOutlineModeEdit className="w-5 h-5"/>
                </button>}
              </div>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-primary opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={onClose}
              >
                <span className="bg-transparent text-primary opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  <AiOutlineClose/>
                </span>
              </button>
            </div>
            {/* body */}
            <div className="relative px-16 pt-4 pb-0 flex flex-col justify-start items-start">

              <div className="flex flex-col justify-start items-start">
                {userInfo.profile_url && <div className="flex flex-row justify-start items-start my-2 gap-6">
                  <WebsiteIcon/>
                  <div className="flex flex-col justify-start items-start">
                    <h6 className="text-sm text-gray-500">Your Profile</h6>
                    <p className='text-primary'>{userInfo.profile_url}</p>
                  </div>
                </div>}

                {userInfo.website && <div className="flex flex-row justify-start items-start my-2 gap-6">
                  <WebsiteIcon/>
                  <div className="flex flex-col justify-start items-start">
                    <h6 className="text-sm text-gray-500">Website</h6>
                    <p className='text-primary'>{userInfo.website}</p>
                  </div>
                </div>}

                {userInfo.phone_number && <div className="flex flex-row justify-start items-start my-2 gap-6">
                  <PhoneIcon/>
                  <div className="flex flex-col justify-start items-start">
                    <h6 className="text-sm text-gray-500">Phone number</h6>
                    <p className='text-primary'>{userInfo.phone_number}</p>
                  </div>
                </div>}

                {userInfo.email && <div className="flex flex-row justify-start items-start my-2 gap-6">
                  <EnvelopIcon/>
                  <div className="flex flex-col justify-start items-start">
                    <h6 className="text-sm text-gray-500">Email</h6>
                    <p className='text-primary'>{userInfo.email}</p>
                  </div>
                </div>}

                {userInfo.country && <div className="flex flex-row justify-start items-start my-2 gap-6">
                  <LocationIcon/>
                  <div className="flex flex-col justify-start items-start">
                    <h6 className="text-sm text-gray-500">Location</h6>
                    <p className='text-primary'><span>{userInfo.country && `${userInfo.country.label},`}</span> <span>{userInfo.city && `${userInfo.city.label},`}</span></p>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
      {showEditModal && <Modal title="Edit Contact info" onClose={() => setShowEditModal(false)}><ContactInfoEdit fetch={fetch} fetchInfo={fetchInfo} setUserId={setUserId} userId={userInfo.id} onClose={() => setShowEditModal(false)} userInfo={userInfo}/></Modal>}
    </Fragment>
  );
};

export default ContactInfo;
