/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormControl from '../../UI/FormControl';
import Button from '../../UI/Button';
import ArrowNext from '../../UI/Icons/ArrowNext';
import { durationTypes, timeTypes, courseWeeks } from '../../utils/constants';

const DurationForm = ({ job, setSelectedIndex, setSubmitValues }) => {
  const [specificDate, setSpecificDate] = useState(false);

  const VALIDATIONSHEMA = Yup.object({
    duration_type: Yup.string().oneOf(['days', 'hours']).required().label('Duration Type'),
    duration: Yup.number().required().label('Duration'),
    course_time_type: Yup.string().oneOf(['morning', 'evening', 'fullday']).required().label('Course Time'),
    course_time_from: Yup.string(),
    course_time_to: Yup.string()
  });

  let INITIALVALUES = {
    duration_type: '',
    duration: '',
    course_time_type: '',
    course_time_from: '',
    course_time_to: '',
    specific_date: 'no',
    course_date: '',
    course_start: ''
  };

  if (job) {
    INITIALVALUES = {
      duration_type: job.duration_type || '',
      duration: job.duration || '',
      course_time_type: job.course_time_type || '',
      course_time_from: job.course_time_from || '',
      course_time_to: job.course_time_to || '',
      specific_date: job.specific_date || '',
      course_date: job.course_date || '',
      course_start: job.course_start || ''
    };
  }

  const onSubmit = (values) => {
    setSubmitValues(state => ({
      ...state,
      ...values
    }));
    setSelectedIndex(index => ++index);
  };
  return (
    <Formik
      initialValues = {INITIALVALUES}
      validationSchema = {VALIDATIONSHEMA}
      onSubmit={onSubmit}>
      {formik => (
        <Form className='w-full' >
          <label className='block text-sm text-gray-600 font-semibold mb-1' htmlFor='duration'>Duration</label>
          <div className="grid grid-cols-2 gap-4">
            <FormControl control='select' label='Duration type...' name='duration_type' options={durationTypes}/>
            <FormControl control='input' type='number' label='Duration...' name='duration' placeholder='Please Enter Course Duration' />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <FormControl control='select' label='Course Time' name='course_time_type' options={timeTypes}/>
            <FormControl control='input' type='time' label='From' name='course_time_from' />
            <FormControl control='input' type='time' label='To' name='course_time_to' />
          </div>
          <div className="grid grid-cols-1">
            <label className='block text-lg text-primary font-body' htmlFor='duration'>Is there a planned date for the start of the course?</label>
            <div className='grid grid-cols-1 gap-4' >
              <div className='flex flex-row justify-start items-center w-full mb-5' >
                <input className='w-5 h-5 mr-4 cursor-pointer' type='checkbox' defaultChecked={specificDate} value={specificDate}
                  onChange={(e) => {
                    const { value } = e.target;
                    setSpecificDate(!specificDate);
                    value === true && formik.setFieldValue('course_date', '');
                    value === false && formik.setFieldValue('course_start', '');
                  }} />
                <span className='block text-sm text-primary opacity-50 font-semibold'>Yes, select a start date.</span>
              </div>
              <div className='w-2/3' >
                {!specificDate ? <FormControl control='select' label='Start With In' name='course_start' options={courseWeeks}/> : <FormControl control='input' type='date' label='Course Date' name='course_date' />}
              </div>
            </div>
          </div>
          <Button type='submit' className='text-primary border-2  mx-auto my-16 hover:border-primary hover:font-bold'> <ArrowNext/> Next</Button>
        </Form>)}
    </Formik>
  );
};

export default DurationForm;
