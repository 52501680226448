import * as Yup from 'yup';

export const VALIDATIONSHEMA = Yup.object({
  name: Yup.string().required(),
  issuing_organization: Yup.string().required(),
  issue_date_month: Yup.string(),
  issue_date_year: Yup.string(),
  expiration_date_month: Yup.string(),
  expiration_date_year: Yup.string(),
  //   credential_id: Yup.string(),
  credential_url: Yup.string().matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, 'Enter correct url!')
});
