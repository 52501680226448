import React from 'react';
import ReactDOM from 'react-dom';
import App from './router';
import store from './store';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './context/auth';

ReactDOM.render(
  <AuthProvider>
    <Provider store={store}>
      <GoogleOAuthProvider clientId="290332542142-2lemuj3c38mkcj512qe5ki3mui8a4bde.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </Provider>
  </AuthProvider>,
  document.getElementById('app')
);
