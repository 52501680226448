import React from 'react';

const EditIcon = () => {
  return (
    <svg id="edit" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_60" data-name="Rectangle 60" width="24" height="24" fill="none"/>
      <g id="Group_128" data-name="Group 128" transform="translate(-2.808 -2.792)">
        <path id="Path_89" data-name="Path 89" d="M20.652,3.068a.259.259,0,0,1,.158.441L9.948,14.352a.977.977,0,0,0-.255.444l-1.305,4.99a.978.978,0,0,0,1.195,1.193l5-1.3a.98.98,0,0,0,.445-.255l11.015-11a.259.259,0,0,1,.442.155A59.43,59.43,0,0,1,26.4,21.707a5.4,5.4,0,0,1-4.768,4.709,62.072,62.072,0,0,1-13.656,0,5.4,5.4,0,0,1-4.768-4.709,59.423,59.423,0,0,1,0-13.829A5.4,5.4,0,0,1,7.98,3.169,62.129,62.129,0,0,1,20.652,3.068Z" fill="#0f4975"/>
        <path id="Path_90" data-name="Path 90" d="M20.532,4.259a.327.327,0,0,1,.461,0L22.84,6.1a.325.325,0,0,1,0,.461L12.013,17.372a.33.33,0,0,1-.148.085l-2.5.652a.326.326,0,0,1-.4-.4l.653-2.495a.326.326,0,0,1,.085-.148Z" transform="translate(1.878 0.416)" fill="#0f4975"/>
      </g>
    </svg>

  );
};

export default EditIcon;
