/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import PersonalInfoForm from '../Form';
import { VALIDATIONSHEMA } from '../utils/validation';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import TextError from '../../UI/TextError';

const PersonalInfoEdit = ({userId, onClose, fetchInfo, setUserId}) => {
  const [user, setUser] = useState(false);
  const [countries, setCountries] = useState(false);
  const [error, setError] = useState('');
  const token = getToken();

  useEffect(() => {
    axios.get(`/api/profile/${userId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setUser(res.data.data);
    }).catch(error => setError(error.messaage));
    axios.get(`/api/countries`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setCountries(res.data.data);
    }).catch(error => setError(error.messaage));
    return () => {
      setUserId(null);
      fetchInfo(null);
    };
  }, []);

  const onSubmit = values => {
    axios.post(`/api/profile/${userId}`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
      onClose();
      fetchInfo(userId);
    }).catch(error => setError(error.message));
  };

  const INITIALVALUES = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone_number: user.phone_number,
    country_id: user.country ? user.country.id : '',
    city_id: user.city ? user.city.id : '',
    address: user.address,
    zip_code: user.zip_code,
    website: user.website,
    about: user.about ? user.about : '',
    _method: 'PUT'
  };

  return (
    <Fragment>
      {error && <TextError>{error}</TextError>}
      {user && countries && <Formik
        initialValues = {INITIALVALUES}
        validationSchema = {VALIDATIONSHEMA}
        onSubmit={onSubmit}>
        {formik => (
          <PersonalInfoForm values={formik.values} countries={countries} />
        )}
      </Formik>}
    </Fragment>
  );
};

export default PersonalInfoEdit;
