/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {AiOutlinePlus} from 'react-icons/ai';
import Modal from '../UI/Modal/index';
import Card from '../UI/Card';
import CertificationCreate from './Create';
import CertificationEdit from './Edit';
import {getToken} from '../../utils/auth.js';
import axios from 'axios';
import { useAuth } from '../../context/auth';
import EditIcon from '../UI/Icons/EditIcon';

const Certifications = ({certificates, userId}) => {
  const token = getToken();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userCert, setUserCert] = useState(certificates);
  const [fetch, setFetch] = useState(false);
  const [certId, setCertId] = useState(null);
  const {currentUser} = useAuth();

  const isCurrentUser = userId === currentUser.id;

  const handleFetch = (id) => setFetch(id);
  const handleCloseEdit = () => setShowEditModal(false);
  const handleCloseCreate = () => setShowCreateModal(false);

  useEffect(() => {
    if (certId !== null) {
      setShowEditModal(true);
    }
  }, [certId]);

  useEffect(() => {
    if (fetch) {
      axios.get(`/api/certificates`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(res => {
        setUserCert(res.data.data);
      });
    }
  }, [fetch]);

  return (
    <Card className='w-full h-auto flex flex-col justify-start items-start rounded-lg bg-white shadow-none border border-gray-400 relative p-5 my-5'>
      <div className="flex w-full h-auto flex-row justify-between items-center">
        <h4 className='text-xl text-primary font-body'>Certificates</h4>
        {isCurrentUser && <button onClick={() => setShowCreateModal(true)} className="ml-2 p-3 hover:bg-gray-200 rounded-full">
          <AiOutlinePlus className="text-primary w-5 h-5"/>
        </button>}
      </div>
      {userCert.map(certificate => (
        <div key={certificate.id} className="flex flex-row justify-between items-start my-2 w-full">
          <div className="flex flex-col justify-start items-start">
            <h6 className="text-md text-primary">{certificate.name}</h6>
            <div className="flex flex-row justify-start items-center text-sm text-gray-500">
              <h6>{certificate.issuing_organization}</h6>
            </div>
            <div className="flex flex-row justify-start items-center text-sm text-gray-500 gap-x-2">
              <span>Acquired: {certificate.issue_date_year}</span> <span>Expired: {certificate.expiration_date_year }</span>
            </div>
          </div>
          {isCurrentUser && <button onClick={() => setCertId(certificate.id)} className="ml-2 p-3 hover:bg-gray-200 rounded-full">
            <EditIcon className="w-5 h-5"/>
          </button>}
        </div>
      ))}
      {showCreateModal && <Modal title="Create Certification" onClose={handleCloseCreate}><CertificationCreate fetchCert={handleFetch} onClose={handleCloseCreate}/></Modal>}
      {showEditModal && <Modal title="Edit Certification" onClose={handleCloseEdit}><CertificationEdit setCertId={setCertId} fetchCert={handleFetch} onClose={handleCloseEdit} certId={certId}/></Modal>}
    </Card>
  );
};

export default Certifications;
