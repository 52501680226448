/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import {AiOutlinePlus} from 'react-icons/ai';
import Modal from '../UI/Modal/index';
import Card from '../UI/Card';
import ExperienceCreate from './Create';
import ExperienceEdit from './Edit';
import {getToken} from '../../utils/auth.js';
import axios from 'axios';
import { useAuth } from '../../context/auth';
import EditIcon from '../UI/Icons/EditIcon';

const Experience = ({experiences, userId}) => {
  const token = getToken();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userExpe, setUserExpe] = useState(experiences);
  const [fetch, setFetch] = useState(false);
  const [expId, setExpId] = useState(null);

  const {currentUser} = useAuth();

  const handleFetch = (id) => setFetch(id);

  const isCurrentUser = userId === currentUser.id;

  useEffect(() => {
    if (expId !== null) {
      setShowEditModal(true);
    }
  }, [expId]);

  useEffect(() => {
    if (fetch) {
      axios.get(`/api/experiences`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(res => {
        setUserExpe(res.data.data);
      });
    }
  }, [fetch]);

  return (
    <Card className='w-full h-auto flex flex-col justify-start items-start shadow-none rounded-lg bg-white border border-gray-400 relative p-5 my-5'>
      <div className="flex w-full h-auto flex-row justify-between items-center">
        <h4 className='text-xl text-primary font-body'>Experiences</h4>
        {isCurrentUser && <button onClick={() => setShowCreateModal(true)} className="ml-2 p-3 hover:bg-gray-200 rounded-full">
          <AiOutlinePlus className="text-primary w-5 h-5"/>
        </button>}
      </div>
      {userExpe.map(exp => (
        <div key={exp.id} className="flex flex-row justify-between items-start my-2 w-full">
          <div className="flex flex-col justify-start items-start">
            <h6 className="text-md text-primary">{exp.company_name}</h6>
            <div className="flex flex-row justify-start items-center text-sm text-gray-500">
              <h6>{exp.title} -- </h6>
              {exp.end_month ? <Fragment>
                <p>{exp.start_month} {exp.start_year}</p>
                <p className="ml-3">{exp.end_month} {exp.end_year}</p>
              </Fragment> : ' Current'}
            </div>
          </div>
          {isCurrentUser && <button onClick={() => setExpId(exp.id)} className="ml-2 p-3 hover:bg-gray-200 rounded-full">
            <EditIcon className="w-5 h-5"/>
          </button>}
        </div>
      ))}
      {showEditModal && <Modal title="Edit Experience" onClose={() => setShowEditModal(false)}><ExperienceEdit setExpId={setExpId} fetchExp={handleFetch} expId={expId} onClose={() => setShowEditModal(false)}/></Modal>}
      {showCreateModal && <Modal title="Create Experience" onClose={() => setShowCreateModal(false)}><ExperienceCreate fetchExp={handleFetch} onClose={() => setShowCreateModal(false)}/></Modal>}
    </Card>
  );
};

export default Experience;
