/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCategory, listCategory } from '../../../../store/reducer/categorySlice';
import BasicTable from '../../../../components/UI/BasicTable';
import BasicBreadcrumbs from '../../../../components/UI/BasicBreadcrubs';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';

const ListCategories = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { categories } = useSelector(state => state.category);

  const editAction = (id) => history.push(`/admin/categories/${id}`);
  const deleteAction = (id) => {
    const successCallback = () => {
      history.push('/admin/categories');
      dispatch(listCategory());
    };
    dispatch(deleteCategory({id, successCallback}));
  };

  useEffect(() => {
    dispatch(listCategory());
  }, [dispatch]);

  const tableCells = [
    {
      Header: 'Name',
      accessor: 'name',
      align: 'left'
    },
    {
      Header: 'Label',
      accessor: 'label',
      align: 'left'
    }
  ];

  return (
    <div className='container mx-auto h-full'>
      <div className='ml-5 mt-12'>
        <div className='flex justify-between items-center mb-4'>
          <BasicBreadcrumbs active='Categories' />
          <Button variant="contained" color="primary" onClick={() => history.push('/admin/category')}>
            Create Category
          </Button>
        </div>
        {categories && <BasicTable title='Categories' rows={categories.data} cells={tableCells} editAction={editAction} deleteAction={deleteAction} />}
      </div>
    </div>
  );
};

export default ListCategories;
