import React, { useState } from 'react';
import Modal from '../components/UI/Modal';
import PecontoCard from '../components/PecontoCard';
import TermsOfUse from '../components/TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';

function Footer () {
  const [showPecontoModal, setShowPecontoModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  return (
    <div className="w-full h-15 text-center">
      <div className="p-4 border-t border-gray-400 flex flex-col md:flex-row justify-between items-center w-full">
        <ul className='flex flex-col md:flex-row justify-start items-center gap-3 mb-4 md:mb-0'>
          <li onClick={() => setShowPecontoModal(true)} className='text-primary text-xs cursor-pointer' >About Peconto</li>
          <li onClick={() => setShowTermsModal(true)} className='text-primary text-xs cursor-pointer' >Terms of use</li>
          <li onClick={() => setShowPrivacyModal(true)} className='text-primary text-xs cursor-pointer' >privacy policy</li>
        </ul>
        <p className='text-xs text-gray-400' > &copy;{(new Date()).getFullYear()}  All rights reserved</p>
      </div>
      {showPecontoModal && <Modal onClose={() => setShowPecontoModal(false)} ><PecontoCard/></Modal>}
      {showTermsModal && <Modal onClose={() => setShowTermsModal(false)} title='Terms of Use' ><TermsOfUse/></Modal>}
      {showPrivacyModal && <Modal onClose={() => setShowPrivacyModal(false)} title='Privacy Policy' ><PrivacyPolicy/></Modal>}
    </div>
  );
};

export default Footer;
