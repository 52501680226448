import React, { useState } from 'react';
import Card from '../components/UI/Card';
import ArrowNext from '../components/UI/Icons/ArrowNext';
import Button from '../components/UI/Button';
import Modal from '../components/UI/Modal';
import PecontoCard from '../components/PecontoCard';
import { Link } from 'react-router-dom';

const HelpCenter = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className='w-full bg-primary_bg'>
      <div className='w-full md:w-2/3 mx-auto grid grid-cols-1 md:grid-cols-7 gap-8 pt-8'>
        <Card className='md:col-span-5 bg-white flex flex-col justify-start items-start gap-y-4'>
          <h1 className='text-4xl text-primary font-body'>Frequently asked questions</h1>

          <div className='flex flex-col justify-start items-start gap-y-2'>
            <h4 className='text-primary text-base'>What is a company?</h4>
            <p className='text-primary opacity-50' >A company, abbreviated as co., is a legal entity representing an association of people, whether natural, legal or a mixture of both, with a specific objective. Company members share a common purpose and unite to achieve specific, declared goals. Companies take various forms, such as: voluntary associations, which may include nonprofit organizations. Business entities, whose aim is generating profit. Financial entities and banks. Programs or educational institutions.</p>
          </div>

          <div className='flex flex-col justify-start items-start gap-y-2'>
            <h4 className='text-primary text-base'>What is a company?</h4>
            <p className='text-primary opacity-50' >A company, abbreviated as co., is a legal entity representing an association of people, whether natural, legal or a mixture of both, with a specific objective. Company members share a common purpose and unite to achieve specific, declared goals. Companies take various forms, such as: voluntary associations, which may include nonprofit organizations. Business entities, whose aim is generating profit. Financial entities and banks. Programs or educational institutions.</p>
          </div>
        </Card>
        <div className='md:col-span-2 flex flex-col justify-start items-start gap-y-8'>
          <Card className='bg-white w-full flex flex-col justify-start items-start gap-y-4'>
            <h6 className='text-lg text-primary font-body'>Can&apos;t find what you&apos;re looking for?</h6>
            <p className='text-xs text-primary opacity-50'>Our support team will help answer any question or inquire you have about Peconto.</p>
            <Link to='/contact-us' className='border border-primary rounded-md w-full text-primary hover:font-semibold flex flex-row justify-between items-center gap-x-12 rounded-lg px-4 py-2 font-normal uppercase text-sm outline-none focus:outline-none ease-linear transition-all duration-150 '><ArrowNext/> support</Link>
          </Card>
          <Card className='bg-white w-full flex flex-col justify-start items-start gap-y-4'>
            <h6 className='text-lg text-primary font-body'>What is Peconto?</h6>
            <p className='text-xs text-primary opacity-50'>Peconto is a platform connecting entrepreneurs and business owners who need to grow with skilled trainers.</p>
            <Button onClick={() => setShowModal(true)} className='border border-primary rounded-md w-full text-primary hover:font-semibold'><ArrowNext/> more</Button>
          </Card>
        </div>
      </div>
      {showModal && <Modal onClose={() => setShowModal(false)}><PecontoCard/></Modal>}
    </div>
  );
};

export default HelpCenter;
