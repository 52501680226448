import { createSlice } from '@reduxjs/toolkit';

const terms = createSlice({
  name: 'terms',
  initialState: {
    terms: {data: [], count: 0},
    fetched: undefined,
    updated: {}
  },
  reducers: {
    editTerms (state, action) {},
    editTermsSuccess (state, action) {
      state.updated = action.payload;
    },
    getTerms (state, action) {},
    getTermsSuccess (state, action) {
      state.fetched = action.payload.data;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  editTerms,
  editTermsSuccess,
  getTerms,
  getTermsSuccess
} = terms.actions;

export default terms.reducer;
