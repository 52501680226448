import React, {useState} from 'react';
import Recaptcha from 'react-recaptcha';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { login } from '../../api/auth';
import { getIntendedUrl } from '../../utils/auth';
import useInputValue from '../../components/input-value';
import TextError from '../../components/UI/TextError';
import Line from '../../components/UI/Icons/Line';
import axios from 'axios';
import EnvelopIcon from '../../components/UI/Icons/EnvelopIcon';
import LockIcon from '../../components/UI/Icons/LockIcon';
import ArrowBack from '../../components/UI/Icons/ArrowBack';
import { GoogleLogin } from '@react-oauth/google';

var jwt = require('jsonwebtoken');

function Login () {
  let {setCurrentUser, setToken} = useAuth();
  let history = useHistory();
  let email = useInputValue('email');
  let password = useInputValue('password');

  const [isVerified, setIsVerified] = useState(true);
  const [error, setError] = useState('');
  const [googleError, setGoogleError] = useState([]);

  const handleGoogle = (data) => {
    const userData = jwt.decode(data.credential);

    login({
      email: userData.email,
      // eslint-disable-next-line no-useless-escape
      password: 'r@83Xj~(h=XkQY\d'
    })
      .then(({ user, token }) => {
        if (user && token) {
          setToken(token);
          setCurrentUser(user);
        }
        if (user.email_verified_at === null) {
          axios.post(`api/email/verify/resend`, {}, {
            headers: {
              'Authorization': `Bearer ${token}`
            }});
          history.push('/verify');
        } else {
          if (user && token) {
            setToken(token);
            setCurrentUser(user);
            history.push(getIntendedUrl());
          }
        }
      })
      .catch((error) => {
        error.json().then(({ errors }) => setGoogleError(Object.values(errors)));
      });
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (isVerified) {
      login({
        email: email.value,
        password: password.value
      }).then(({ user, token }) => {
        if (user.email_verified_at === null) {
          axios.post(`api/email/verify/resend`, {}, {
            headers: {
              'Authorization': `Bearer ${token}`
            }});
          history.push('/verify');
        } else {
          if (user && token) {
            setToken(token);
            setCurrentUser(user);
            history.push(getIntendedUrl());
          }
        }
      }).catch(error => {
        error.json().then(({ errors }) => email.parseServerError(errors));
      });
    } else {
      setError('Please verified your are not a robot');
    }
    login({
      email: email.value,
      password: password.value
    }).then(({ user, token }) => {
      if (user.email_verified_at === null) {
        axios.post(`api/email/verify/resend`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }});
        history.push('/verify');
      } else {
        if (user && token) {
          setToken(token);
          setCurrentUser(user);
          history.push(getIntendedUrl());
        }
      }
    }).catch(error => {
      error.json().then(({ errors }) => email.parseServerError(errors));
    });
  };

  const verifyCallback = (response) => {
    if (response) {
      setIsVerified(true);
    }
  };

  return (
    <div className="flex justify-center w-full h-screen bg-primary_bg">
      <div className="relative bg-white w-3/4 sm:w-1/2 lg:w-3/5 xl:w-2/5 px-8 h-full flex flex-col justify-between items-center">
        <div className='absolute top-0 left-0 m-6'>
          <Link to='/' className='text-primary flex justify-start items-center p-3'>
            <ArrowBack fill="#0F4975" />
                      Back
          </Link>
        </div>
        <img src="/images/logo.png" alt="Peconto" className='mb-16 mt-20 w-24' />

        <div className='flex flex-col justify-center items-center'>
          <h1 className='text-4xl text-primary font-body font-semibold' >Log in to Peconto</h1>
          <p className='text-lg text-primary font-normal' >Select your Perferred login method.</p>
        </div>

        <div className='flex flex-col justify-center items-center w-full mt-4'>
          <div className='flex justify-center align-center mb-6' >
            <GoogleLogin
              auto_select
              onSuccess={credentialResponse => {
                handleGoogle(credentialResponse);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
              theme='filled_blue'
              size='large'
              width='380'
            />
          </div>

          <div className='flex flex-col justify-center items-center mb-3'>
            {googleError.map((error, index) => <TextError key={index} > {error} </TextError>)}
          </div>

          <div className='flex justify-between items-center'>
            <Line />
            <span className='text-primary mx-6'>or</span>
            <Line/>
          </div>

          <form className='w-4/6' onSubmit={handleSubmit}
            method="POST">
            <div className="my-4">
              <div className='flex justify-start items-center border border-gray-300 rounded-md py-2 px-4'>
                <EnvelopIcon/>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder='Email address...'
                  className={` w-full py-1 px-3 text-primary font-base focus:outline-none ${email.error ? 'border-red-500' : ''}`}
                  required
                  autoFocus
                  {...email.bind}
                />
              </div>

              {email.error && <p className="text-red-500 text-xs pt-2">{email.error}</p>}
            </div>

            <div className="mb-3">
              <div className='flex justify-start items-center border border-gray-300 rounded-md py-2 px-4'>
                <LockIcon/>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder='Password...'
                  className="w-full py-1 px-3 text-primary font-base "
                  required
                  {...password.bind}
                />
              </div>
            </div>

            {error && <TextError>{error}</TextError>}
            <div className="mt-4 mb-10 ml-10 2xl:ml-24">
              <Recaptcha
                sitekey={process.env.MIX_RECAPTCHA_SITE_KEY}
                render="explicit"
                verifyCallback={verifyCallback}
              />
            </div>

            <div className="mb-3">
              <button type="submit" className="rounded-lg p-4 text-white bg-primary w-full font-bold">
                            LOG IN
              </button>
            </div>
          </form>
        </div>

        <div className="mb-3 2xl:mt-32 flex justify-center items-center text-primary">
          Forgot your password? <Link to="/forgot-password" className="text-sm  text-blue-500 font-bold ml-2"> RESET PASSWORD</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
