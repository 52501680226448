/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

const Alert = ({ hide, title, msg }) => {
  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      hide();
    }, 2000);

    return () => {
      clearTimeout(hideTimeout);
    };
  }, []);

  return (
    <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mt-3" role="alert">
      <div className="flex">
        <div>
          {title && <p className="font-bold">{title}</p>}
          {msg && <p className="text-sm">{msg}</p>}
        </div>
      </div>
    </div>
  );
};

export default Alert;
