export const months = [
  {label: 'Month', id: ''},
  {label: 'January', id: 'Jan'},
  {label: 'February', id: 'Feb'},
  {label: 'March', id: 'Mar'},
  {label: 'April', id: 'Apr'},
  {label: 'May', id: 'May'},
  {label: 'June', id: 'June'},
  {label: 'July', id: 'July'},
  {label: 'August', id: 'Aug'},
  {label: 'September', id: 'Sept'},
  {label: 'October', id: 'Oct'},
  {label: 'November', id: 'Nov'},
  {label: 'December', id: 'Dec'}
];

export const generateYears = () => {
  var max = new Date().getFullYear();
  var min = 1900;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push({label: `${i.toString()}`, id: `${i.toString()}`});
  }
  years.unshift({label: 'Year', id: ''});
  return years;
};

export const years = generateYears();

export const courseTypes = [
  {label: 'Online', id: 'online'},
  {label: 'Onsite', id: 'onsite'}
];

export const userHiringRole = [
  {label: 'Hiring Manager', id: 'hiring_manager'},
  {label: 'Recruiter', id: 'recruiter'},
  {label: 'Talent Acquisitions', id: 'talent_asquisitions'},
  {label: 'Assistant', id: 'assistant'},
  {label: 'Office Manager', id: 'office_manager'},
  {label: 'HR', id: 'hr'},
  {label: 'Owner', id: 'owner'},
  {label: 'Other', id: 'other'}
];

export const courseLang = [
  {label: 'Arabic', id: 'arabic'},
  {label: 'English', id: 'english'},
  {label: 'French', id: 'french'},
  {label: 'Spanish', id: 'spanish'},
  {label: 'Russian', id: 'russian'},
  {label: 'German', id: 'german'},
  {label: 'Turkish', id: 'turkish'}
];

export const durationTypes = [
  {label: 'Days', id: 'days'},
  {label: 'Hours', id: 'hours'}
];

export const timeTypes = [
  {label: 'Morning', id: 'morning'},
  {label: 'Evening', id: 'evening'},
  {label: 'Full Day', id: 'fullday'}
];

export const courseWeeks = [
  {label: '1 Week', id: '1week'},
  {label: '2 Weeks', id: '2weeks'},
  {label: '3 Weeks', id: '3weeks'},
  {label: '4 Weeks', id: '4weeks'},
  {label: '5 Weeks', id: '5weeks'},
  {label: '6 Weeks', id: '6weeks'},
  {label: '7 Weeks', id: '7weeks'},
  {label: '8 Weeks', id: '8weeks'}
];
