/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Form} from 'formik';
import FormControl from '../../UI/FormControl';
import { courseTypes } from '../../utils/constants';
import Button from '../../UI/Button';
import { getToken } from '../../../utils/auth';
import axios from 'axios';
import ArrowNext from '../../UI/Icons/ArrowNext';
import UserIcon from '../../UI/Icons/UserIcon';

const CourseForm = ({edit, values, currencies, categories}) => {
  const [courses, setCourses] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const token = getToken();

  useEffect(() => {
    if (values.category_id !== '') {
      axios.get(`/api/categories/${parseInt(values.category_id)}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(res => {
        setSubCategories(res.data.data.sub_categories.data);
      });
    }
  }, [values.category_id]);

  useEffect(() => {
    if (values.sub_category_id !== '') {
      axios.get(`/api/sub-categories/${parseInt(values.sub_category_id)}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(res => {
        setCourses(res.data.data.courses.data);
      });
    }
  }, [values.sub_category_id]);

  return (
    <Form className="w-full">
      <div className='grid gap-4 grid-cols-2'>
        <FormControl control='select' label='Industry...' name='category_id' options={categories}/>
        <FormControl control='select' label='Category...' name='sub_category_id' options={subCategories} />
      </div>
      <FormControl control='select' label='Course title...' name='course_id' options={courses} />
      <div className='grid gap-4 grid-cols-2'>
        <FormControl control='select' label='Course Type...' name='type' options={courseTypes}/>
        <FormControl control='input' type='text' label='Hours' name='hours' />
      </div>
      <FormControl control='textarea' label='Course Details...' name='details' />
      <FormControl control='input' type='number' label='Capacity' name='capacity' Icon={<UserIcon/>} />
      <div className='grid gap-4 grid-cols-2'>
        <FormControl control='input' type='number' label='Price' name='price' />
        <FormControl control='select' label='Currency' name='currency_id' options={currencies}/>
      </div>
      <h6 className='text-primary text-sm mb-3 font-normal' >Preferred Payment</h6>
      <div className='grid gap-4 grid-cols-2'>
        <FormControl control='checkbox' label='Fixed amount' name='fixed_amount' />
        <FormControl control='checkbox' label='Hourly rate' name='hourly_rate' />
      </div>

      <h6 className='text-primary text-sm mb-3 font-normal' >Preferred location</h6>
      <div className='grid gap-4 grid-cols-2'>
        <FormControl control='checkbox' label='Onsite' name='on_site' />
        <FormControl control='checkbox' label='Online' name='online' />
      </div>
      {edit ? <div className="border-t sticky w-full flex flex-row justify-between items-center bottom-0 bg-white py-3">
        <Button className='text-red-600 bg-transparent rounded-full hover:bg-gray-300' type='reset' >Delete</Button>
        <Button className='text-primary border-2  mx-auto hover:border-primary hover:font-bold' type='submit'>Update</Button>
      </div> : <div className='border-t sticky w-full flex flex-row-reverse justify-between items-center bottom-0 bg-white py-3'>
        <Button className='text-primary border-2  mx-auto hover:border-primary hover:font-bold' type='submit'> <ArrowNext/> Next</Button>
      </div>}
    </Form>
  );
};

export default CourseForm;
