/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { getToken } from '../../utils/auth';
import * as Yup from 'yup';
import FormControl from '../UI/FormControl';
import UserIcon from '../UI/Icons/UserIcon';
import EnvelopIcon from '../UI/Icons/EnvelopIcon';
import axios from 'axios';
import TextError from '../UI/TextError';

const ContactUsForm = ({success}) => {
  const [error, setError] = useState(null);
  const token = getToken();
  const INITIALVALUES = {
    name: '',
    email: '',
    subject: '',
    content: ''
  };

  const VALIDATIONSHEMA = Yup.object({
    email: Yup.string().email().required(),
    name: Yup.string().required(),
    subject: Yup.string().required(),
    content: Yup.string().required()
  });

  const onSubmit = values => {
    axios.post(`/api/contact-us`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(() => {
      success();
    }).catch(error => setError(error.message));
  };
  return (
    <Formik
      initialValues={INITIALVALUES}
      validationSchema={VALIDATIONSHEMA}
      onSubmit={onSubmit}
    >
      <Form className="w-full">
        {error && <TextError>{error}</TextError>}
        <FormControl control='input' type='text' label='Full name...' name='name' Icon={<UserIcon/> }/>
        <FormControl control='input' type='text' label='Email address...' name='email' Icon={<EnvelopIcon/> }/>
        <FormControl control='input' type='text' label='Message subject...' name='subject' />
        <FormControl control='textarea' label='Message content...' name='content' />
        <div className="w-full flex flex-row justify-between items-center py-3">
          <button className='w-full text-white bg-primary font-semibold py-4 justify-center rounded-md focus:outline-none uppercase' type='submit'>send message</button>
        </div>
      </Form>
    </Formik>
  );
};

export default ContactUsForm;
