/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import FormControl from '../../UI/FormControl';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from '../../UI/Button';
import { userHiringRole } from '../../utils/constants';
import ArrowNext from '../../UI/Icons/ArrowNext';

const DetailsFrom = ({ job, setSelectedIndex, setSubmitValues }) => {
  const [INITIALVALUES, setINITIALVALUES] = useState({
    title: '',
    user_role: '',
    user_role_other: '',
    description: '',
    brief: ''
  });
  const VALIDATIONSHEMA = Yup.object({
    title: Yup.string().required(),
    user_role: Yup.string().oneOf(['hiring_manager', 'recruiter', 'talent_asquisitions', 'assistant', 'office_manager', 'hr', 'owner', 'other']).required().label('User Role'),
    user_role_other: Yup.string().when(['user_role'], {
      is: (userRole) => userRole === 'other',
      then: Yup.string().required('Please enter a your role')
    }),
    description: Yup.string().required()
  });

  useEffect(() => {
    if (job) {
      setINITIALVALUES({
        title: job.title || '',
        user_role: job.user_role || '',
        user_role_other: job.user_role_other === null ? '' : job.user_role_other,
        description: job.description || '',
        brief: job.brief || ''
      });
    }
  }, [job]);

  const onSubmit = (values) => {
    setSubmitValues(state => ({
      ...state,
      ...values
    }));
    setSelectedIndex(index => ++index);
  };
  return (
    <Formik
      initialValues = {INITIALVALUES}
      validationSchema = {VALIDATIONSHEMA}
      onSubmit={onSubmit}>
      {formik => (
        <Form className='w-full' >
          <FormControl control='input' type='text' label='Title' name='title' placeholder='Please Enter Job Title' />
          <FormControl control='textarea' label='Brief...' name='brief' />
          <FormControl control='select' label='Your Role in your Company' name='user_role' options={userHiringRole}/>
          {formik.values.user_role === 'other' && <FormControl control='input' type='text' label='Other' name='user_role_other' />}
          <label className='block text-sm text-gray-600 font-semibold mb-1' htmlFor='description'>Description</label>
          <Field name='description'>
            {({field}) => <ReactQuill theme='snow' value={field.value} onChange={field.onChange(field.name)} />}
          </Field>
          <Button type='submit' className='text-primary border-2  mx-auto my-16 hover:border-primary hover:font-bold'> <ArrowNext/> Next</Button>
        </Form>)}
    </Formik>
  );
};

export default DetailsFrom;
