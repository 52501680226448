import React, { Fragment, useState, useEffect } from 'react';
import PersonalInfo from '../components/PersonalInfo';
import axios from 'axios';
import { getToken } from '../utils/auth';
import { useParams } from 'react-router';
import ProfileTabs from './profileTabs';

const ProfileContainer = () => {
  const [user, setUser] = useState();
  const [fetch, setFetch] = useState(false);
  const {username} = useParams();
  const token = getToken();

  useEffect(() => {
    axios.get(`/api/profile/${username}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      setUser(res.data.data);
    });
  }, [username, fetch]);

  return (
    <Fragment>
      {user &&
        <Fragment>
          <PersonalInfo userInfo={user} setFetch={setFetch}/>
          <ProfileTabs user={user} setFetch={setFetch} />
        </Fragment>
      }
    </Fragment>
  );
};

export default ProfileContainer;
